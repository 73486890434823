import React, { FC } from 'react';
import {
  CustomVoice,
  CustomVoiceEnv,
  CustomVoicePlatform,
} from '@lovo/lovo-custom-voice';
import { useLocation } from 'react-router-dom';

const CustomVoicePage: FC = ({ children }) => {
  console.log(process.env.REACT_APP_BUILD_ENV);
  const env =
    process.env.REACT_APP_BUILD_ENV === 'production'
      ? CustomVoiceEnv.Production
      : process.env.REACT_APP_BUILD_ENV === 'staging'
      ? CustomVoiceEnv.Staging
      : CustomVoiceEnv.Development;

  const location = useLocation();
  const searchParam = new URLSearchParams(location.search);
  const userId = searchParam.get('userId') || undefined;
  const referralCode = searchParam.get('referralCode') || undefined;

  return (
    <CustomVoice
      env={env}
      platform={CustomVoicePlatform.LovoStudio}
      authObj={{ userId, referralCode }}
    />
  );
};

export default CustomVoicePage;
