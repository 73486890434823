import React from 'react';
import styled from 'styled-components';
import Button from './Button';
import { logoColor } from '../../lib/styles/palette';

const Fullscreen = styled.div`
  position: fixed;
  z-index: 51;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1100px;
`;

const AskModalBlock = styled.div`
  max-width: 600px;
  width: 30%;
  min-width: 300px;
  background: white;
  padding: 1.8rem;
  border-radius: 0px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
  h2 {
    margin-top: 0;
    margin-bottom: 1rem;
    color: ${logoColor};
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0.5px;
  }
  p {
    color: #242424;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.36px;
  }
`;

const ButtonWithMarginTop = styled(Button)`
  height: 54px;
  width: 35%;
  background-color: ${logoColor};
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  border-radius: 0;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  text-align: center;
  color: #242424;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const WarningModal = ({ visible, onCancel, message }) => {
  if (!visible) return null;
  return (
    <Fullscreen>
      <AskModalBlock>
        <Description>{message}</Description>
        <ButtonWithMarginTop
          cyan
          fullWidth
          style={{ marginTop: '2rem', marginBottom: '0rem' }}
          onClick={onCancel}
        >
          OK
        </ButtonWithMarginTop>
      </AskModalBlock>
    </Fullscreen>
  );
};

export default WarningModal;
