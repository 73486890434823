import React from 'react';
import styled, { CSSObject } from 'styled-components';
import Button from '../Button';

interface Props {
  style?: CSSObject;
  logo?: string;
  text?: string;
  onSubmit: Function;
  logoStyle?: React.CSSProperties;
}

const JoinUsBtn: React.FC<Props> = ({
  style,
  logo,
  text,
  onSubmit,
  logoStyle,
}) => {
  return (
    <JoinBtn style={style} onClick={onSubmit}>
      {logo && <img src={logo} alt="logo img" style={logoStyle} />}
      {text && text}
    </JoinBtn>
  );
};

export default JoinUsBtn;

const JoinBtn = styled(Button)`
  padding: 9px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #313131;
  height: 36px;
  color: white;
  font-size: 14px;
  font-weight: bold;

  img {
    margin-right: 6px;
  }

  &:hover {
    background-color: black;
  }
`;
