import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../modules/index';
import {
  accessPrivateSkinRequest,
  accessCustomSkinRequest,
  getSkinListRequest,
  initializeWorkspace,
  changePostsField,
  changeCodeField,
} from '../modules/posts/actions';

export default function usePosts() {
  const dispatch = useDispatch();
  const posts = useSelector((state: StoreState) => state.posts);

  const handleChangeCodeField = useCallback(
    (value: any) => dispatch(changeCodeField(value)),
    [dispatch],
  );
  const handleAccessPrivateSkinRequest = useCallback(
    (code: string) => dispatch(accessPrivateSkinRequest(code)),
    [dispatch],
  );
  const handleAccessCustomSkinRequest = useCallback(
    (speaker_id: string, access_code: string) =>
      dispatch(accessCustomSkinRequest(speaker_id, access_code)),
    [dispatch],
  );
  const handleGetSkinList = useCallback(() => dispatch(getSkinListRequest()), [
    dispatch,
  ]);
  const handleInitializeWorkspace = useCallback(
    (key: string) => dispatch(initializeWorkspace(key)),
    [dispatch],
  );
  const handleChangePostsField = useCallback(
    (key: string, value: any) => dispatch(changePostsField({ key, value })),
    [dispatch],
  );

  return {
    posts,
    handleChangeCodeField,
    handleAccessPrivateSkinRequest,
    handleAccessCustomSkinRequest,
    handleGetSkinList,
    handleInitializeWorkspace,
    handleChangePostsField,
  };
}
