import React, { FC } from 'react';
import styled from 'styled-components';
import {
  Fullscreen,
  ModalBlock,
  ModalTitle,
  Description,
  RightContainer,
  NeverShow,
  DontShowContainer,
  CloseButton,
} from './style';
import { Button, CustomCheckBox, CloseSvg } from '..';
import { logoColor, hilightedLogoColor } from '../../../lib/styles/palette';
import discord_background from '../../../images/discordModal.svg';

interface Props {
  visible: boolean;
  onCancel: VoidFunction;
  onSubmit: Function;
  isCheckedDiscordNeverShow: boolean;
  setDiscordNeverShow: Function;
  modalStyle?: React.CSSProperties;
}

const Modal: FC<Props> = ({
  visible,
  onCancel,
  onSubmit,
  isCheckedDiscordNeverShow,
  setDiscordNeverShow,
  modalStyle,
}) => {
  if (!visible) return null;
  return (
    <Fullscreen>
      <AskModalBlock style={modalStyle}>
        <RightContainer>
          <CloseButton onClick={onCancel}>
            <CloseSvg />
          </CloseButton>
        </RightContainer>
        <Title>
          Join
          <br />
          the <span>LOVO Studio</span> community
          <br /> on <span>Discord</span>!
        </Title>
        <Description>
          You can find updates, events, give us feedback, report bugs, and find
          sneak peaks on new features & voices!
        </Description>
        <ButtonWithMarginTop cyan fullWidth onClick={onSubmit}>
          Join Discord
        </ButtonWithMarginTop>
        {setDiscordNeverShow ? (
          <DontShowContainer>
            <CustomCheckBox
              size="15px"
              viewSize={15}
              isChecked={isCheckedDiscordNeverShow || false}
              onChange={(e) => {
                setDiscordNeverShow(e);
              }}
            />
            <NeverShow>Never show this again</NeverShow>
          </DontShowContainer>
        ) : (
          ''
        )}
      </AskModalBlock>
    </Fullscreen>
  );
};

export default Modal;

const AskModalBlock = styled(ModalBlock)`
  background-image: url(${discord_background});
  background-color: #232323;
  border-radius: 8px;
`;
const Title = styled(ModalTitle)`
  margin: 28px;
  width: 80%;
  text-align: left;
  span {
    color: #755ee5;
  }
`;
const ButtonWithMarginTop = styled(Button)`
  margin-left: 28px;
  width: 210px;
  height: 52px;
  margin-top: 29px;
  margin-bottom: 15px;
  border-radius: 4px;
  color: #fff;
  font-size: 18px;
  font-weight: bolder;
  letter-spacing: 0.36px;
  font-family: MyFont;
  background-color: ${logoColor};

  :hover {
    background-color: ${hilightedLogoColor};
  }
`;
