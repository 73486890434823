import axios from 'axios';
import { store } from '../..';
import { initializeBillStatus } from '../../modules/bill';
import { initializeWorkspace } from '../../modules/posts/actions';
import {
  initializeUserStatus,
  setSessionExpired,
} from '../../modules/user/actions';

export interface Response {
  success: true;
  data: any;
  message: string;
}

export interface ErrorResponse {
  success: false;
  statusCode: number;
  timestamp: string;
  path: string;
  error: string;
  errors: { message: string };
  message: string;
}

const apiServerUrl = `${process.env.REACT_APP_GATEWAY_SERVER_URL}`;

const gatewayClient = axios.create();
gatewayClient.defaults.baseURL = apiServerUrl;
gatewayClient.defaults.withCredentials = true;

gatewayClient.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      if (!(error.response.data.path === '/user/check')) {
        store.dispatch(initializeWorkspace('all'));
        store.dispatch(initializeBillStatus('all'));
        store.dispatch(initializeUserStatus());
        store.dispatch(setSessionExpired(true));
      }
    }
    if (error.response && error.response.data) {
      return error.response.data;
    }
    return Promise.reject(error);
  },
);

export default gatewayClient;
