import React from 'react';
import styled from 'styled-components';
import { logoColor } from '../../lib/styles/palette';

const Wrapper = styled.div`
  max-width: 487px;
  min-width: 300px;

  // margin-left: 8rem;
  // margin-right: 8rem;

  h2 {
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: center;
    color: ${logoColor};
  }

  p {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.36px;
    text-align: center;
    color: #242424;
  }
`;

const BoothInfo = () => {
  return (
    <Wrapper>
      <h2>Thanks for stopping by our booth at TC Disrupt 19!</h2>
      <p>
        We are currently in Beta Test, but we will be launching officially by
        the end of the year.
      </p>
      <p>
        Please join the waitlist to receive TC Disrupt 19 Promo + a chance to be
        in the 2nd batch of our Beta Testers!
      </p>
    </Wrapper>
  );
};

export default BoothInfo;
