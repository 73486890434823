import React from 'react';
import styled from 'styled-components';

import UploadIcon from '../../images/+.png';

const UploadBlock = styled.div`
  width: 285px;
  margin: 0 auto;
  margin-top: 3vh;
  padding-left: 2rem;
  form {
    float: left;
  }

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  label {
    cursor: pointer;
    width: 53px;
    height: 53px;
  }

  img {
    width: 53px;
    height: 53px;
  }

  img:hover {
    filter: opacity(80%); /* IE5+ */
    -webkit-filter: opacity(80%); /* Webkit Nightlies & Chrome Canary */
  }

  p {
    width: 180px;
    text-align: left;
    float: right;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.36px;
    color: #7361d4;
  }

  span {
    position: absolute;
    text-align: left;
    float: right;
    color: #9f9f9f;
    font-size: 11px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.55;
    letter-spacing: normal;
    text-align: left;
    margin-top: 40px;
    margin-left: 10px;
  }
`;

const Divider = styled.div`
  width: 100%;
  margin: 0 auto;
  width: 600px;
  display: inline-block;
  margin-top: 2vh;
  hr {
    margin-top: 24px;
    float: left;
    width: 265px;
    border: 0.5px solid #c1c1c1;
  }
  span {
    float: left;
    background-color: white;
  }
  p {
    width: 70px;
    height: 18px;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    color: #838383;
  }
`;

const RequestVoiceUpload = ({ fileName, onFileUpdate }) => {
  const onFileChange = (file) => {
    if (file !== undefined) {
      const allowedExtensions = ['mp3', 'wav'];
      if (
        !allowedExtensions.includes(
          file.name.split('.')[file.name.split('.').length - 1],
        ) ||
        file.size > 11000000
      ) {
        onFileUpdate('');
      } else {
        onFileUpdate(file);
      }
    }
  };

  return (
    <>
      <UploadBlock>
        <form>
          <input
            type="file"
            name="file"
            id="file"
            onChange={(event) => {
              onFileChange(event.target.files[0]);
              event.target.value = null;
            }}
          />
          <label htmlFor="file">
            <img src={UploadIcon} alt="Upload Audio" />
          </label>
        </form>

        <p>UPLOAD AUDIO</p>
        <span>{fileName}</span>
      </UploadBlock>
      <Divider>
        <hr />
        <span>
          <p>AND / OR</p>
        </span>
        <hr />
      </Divider>
    </>
  );
};

export default RequestVoiceUpload;
