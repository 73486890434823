import React from 'react';
import styled from 'styled-components';
import Button from './Button';
import { logoColor } from '../../lib/styles/palette';
import CancelButton from '../common/CancelButton';

const Fullscreen = styled.div`
  position: absolute;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1100px;
`;

const AskModalBlock = styled.div`
  max-width: 600px;
  min-width: 500px;
  background: white;
  padding: 1.5rem;
  border-radius: 4px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
  h2 {
    margin-top: 0;
    margin-bottom: 1rem;
    color: ${logoColor};
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0.5px;
  }
  p {
    color: #242424;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.36px;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
  }
`;

const ButtonWithMarginTop = styled(Button)`
  width: 40%;
  background-color: ${logoColor};
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.36px;
`;

const Description = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  text-align: center;
  color: #242424;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const ConfirmWaitlistModal = ({ visible, onCancel, message }) => {
  if (!visible) return null;
  return (
    <Fullscreen>
      <AskModalBlock>
        <div className="buttons">
          <CancelButton onCancel={onCancel} />
        </div>
        <h2>Thanks for joining the Waitlist!</h2>
        <Description>{message}</Description>
        <ButtonWithMarginTop
          cyan
          fullWidth
          style={{ marginTop: '2rem', marginBottom: '1rem' }}
          onClick={onCancel}
        >
          OK
        </ButtonWithMarginTop>
      </AskModalBlock>
    </Fullscreen>
  );
};

export default ConfirmWaitlistModal;
