import client from './client';

export const getSkinListsApi = () => client.get('/skin');

export const toggleFavoriteSkinApi = (skinId: string) =>
  client.post(`/skin/favorite`, { id: skinId });

export const uploadFileApi = (data: FormData) =>
  client.post('/workspace/textract', data);

export const getLibraryListsApi = () => client.get('/audio/library');

export const saveLibraryApi = (audio_id: string) =>
  client.post('/audio/library', { audio_id });

export const removeLibraryApi = (audio_id_list: string[]) =>
  client.post('/audio/library/delete', { audio_id_list });

// deprecated
// export const convertVoiceApi = (
//   uid: string,
//   speaker_id: string,
//   text: string,
//   target: string = "singlespeaker"
// ) => mlClient.post("/convert_text", { uid, speaker_id, text, target });

export const accessPrivateSkinApi = (access_code: string) =>
  client.post('/skin/private', { access_code });

export const accessCustomSkinApi = (speaker_id: string, access_code: string) =>
  client.post('/customskins', { speaker_id, access_code });

export const getBgmListApi = () => client.get('/bgm');

export const uploadSynthesisFileApi = (data: FormData) =>
  client.post('/audio/file', data);

export const getAudioStream = async (url: string) => {
  const response: ArrayBuffer = await client.get(url, {
    responseType: 'arraybuffer',
    withCredentials: false,
  });
  return response;
};

// deprecated
// export const convertVoiceStreamApi = async (
//   user: string,
//   uid: string,
//   speaker_id: string,
//   text: string,
//   speed: string,
//   pause: string,
//   emphasis: string
// ) => {
//   const response: ArrayBuffer = await pitchClient.post(
//     "/convert_text_feature",
//     { user, uid, speaker_id, text, speed, pause, emphasis },
//     {
//       responseType: "arraybuffer",
//     }
//   );
//   return response;
// };

export const convertAudioStreamApiForStudio = async (
  speaker_id: string,
  text: string,
  speed: string,
  pause: string,
  emphasis: string,
  is_custom: boolean,
) => {
  const response: ArrayBuffer = await client.post(
    '/workspace/convert_audio',
    { speaker_id, text, speed, pause, emphasis, is_custom },
    { responseType: 'arraybuffer' },
  );
  return response;
};

export const getWorkspaceListsApi = () => client.get('/workspace');

export const createWorkspaceApi = (
  title: string,
  skin: string,
  content: string,
  audio: string,
  sentence_data: string,
) => client.post('/workspace', { title, skin, content, audio, sentence_data });

export const updateWorkspaceApi = (
  id: string,
  title: string,
  skin: string,
  content: string,
  audio: string,
  sentence_data: string,
) =>
  client.put('/workspace', { id, title, skin, content, audio, sentence_data });

export const deleteWorkspaceApi = (id: string) =>
  client.delete(`/workspace/${id}`);

export const getWorkspaceOneApi = (id: string) =>
  client.get(`/workspace/${id}`);

export const replaceWordsApi = (skin: string, content: string) =>
  client.post(`/workspace/word`, { skin, content });

export const getReplaceWordLists = (skinId: string) =>
  client.get(`/word/skin/${skinId}`);
