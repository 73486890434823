import React from 'react';
import styled from 'styled-components';
import { logoColor, MAIN_CONTENT_WIDTH } from '../../lib/styles/palette';
import usageGuideImg from '../../images/usageGuideline.png';
import ParagraphWithTitle from './ParagraphWithTitle';

const PrivacyInfo = ({ className }) => {
  return (
    <Container className={className}>
      <h2>Privacy Policy</h2>
      <ParagraphWithTitle>
        Last updated: Oct. 15th 2019 <Space />
        This Privacy Policy (the "Policy") explains who we are, how we collect,
        share and use Personal Data about you, and how you can exercise your
        privacy rights.
        <Space /> We are LOVO Inc. ["LOVO"], a Delaware incorporated company. It
        is the policy of LOVO to respect your privacy, and the privacy of all
        users of our applications and Services. This Policy applies to Personal
        Data that we collect through our web/mobile applications and other
        products ("Apps") and websites we control, such as
        https://studio.lovo.ai ("Website") (collectively "Services"). The
        Services are also governed by the Terms of service. LOVO reserves the
        right, at any time and without prior notice, to modify the Policy.
        <Space /> Your privacy is very important to us and we want you to know
        everything we do and don't do with the data you provide. Our guiding
        principle is that any data we collect from you will be treated with the
        utmost care and respect, and every effort will be made to ensure the
        data is kept private. <Space />
        If you have any questions about the Policy, please contact:
        <Space /> LOVO Legal Services customersupport@lovo.ai
        <Space /> Under applicable law, LOVO Inc. is the "data controller" of
        Personal Data collected through the Services. Personal Data is any data
        relating to an identified or identifiable natural person.
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="What personal data does LOVO collect?">
        The Personal Data we may collect about you through the Services falls
        into the following general categories:
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Information that you provide voluntarily">
        Certain parts of our Services may ask you to provide Personal Data
        voluntarily. For example, we may ask for certain information in order to
        use features in our Apps, register an account with us, subscribe to
        marketing communications from us, improve our machine learning features
        and services, and/or to submit enquiries to us, such as: <Space />
        your name, email address or other contact details. any information you
        submit to our customer service team in the App or via email.
        <Space /> The Personal Data that you are asked to provide, and the
        reasons why you are asked to provide it, will be explained to you when
        we ask you to provide your Personal Data.
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Information we collect automatically">
        When you use our Services, we may also collect certain information
        automatically from you and/or your device which may be considered
        Personal Data under applicable data protection laws, such as:
        <Space /> Data and analytics about your use of our Services (e.g. in-app
        usage, session length, preferred settings, which in-app advertisements
        you may have viewed, crash reports, and the means by which you were
        referred to our Services).
        <Space /> Any voice recording data you make using any of our apps.{' '}
        <Space />
        Your IP address, device identifiers such as your Advertising ID, other
        device information, and your general geographic location (e.g. country
        or city location) based on your IP address. <Space />
        "Advertising ID" includes the Apple Identifier for Advertisers ("IDFA")
        on Apple, and the Google Advertising Identity ("GAID") on Android. These
        are unique identifiers for mobile devices that advertisers use for
        interest-based advertising. They are consistent across all apps and thus
        allow tracking across apps. These identifiers can be reset from the
        device's settings. On Apple, you can opt out by choosing the "limit ad
        tracking" option in the privacy settings. On Android, you can opt out by
        choosing "Opt out of interest-based ads."
        <Space /> Advertising IDs are non-permanent, non-personal identifiers
        uniquely associated with your device. Similar technologies include
        tracking pixels within ads. These technologies allow companies
        (including marketers and advertisers) to recognize your device when you
        use websites and applications.
        <Space /> "Device information" means device type, model, manufacturer,
        operating system and language, or other technical data like supported
        application programming interfaces ("APIs"), screen size, processor, or
        a combination of this data, available from a device for a developer to
        check the device's compatibility with a particular application.{' '}
        <Space />
        "Voice Recording data is data you input into the app while using our
        Service through the microphone. We may use your voice recording data
        solely to improve our services provided to you, and it will never be
        shared with anyone or any third parties.
        <Space /> Some of this information may be collected using cookies and/or
        similar technologies, such as software development kits ("SDKs"). The
        Services may use cookies and other technologies such as pixel tags,
        local shared objects, hardware-based device identifiers, flash cookies,
        operating system-based identifiers, clear GIFs and web beacons. Cookies
        are pieces of data that sites and services can set on your browser or
        device that can be read on future visits. We may expand our use of
        cookies to save additional data as new features are added to our
        Services. However, you can disable cookies by changing your browser
        settings. Further information about how to disable cookies can be found
        on your Internet browser provider's help screen.
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Information we obtain from third party sources">
        <b>(a) Third party service providers.</b>
        <Space /> We may receive Personal Data about you from third-party
        sources. We may obtain your Personal Data, for example, when you
        installed our App or when, prior to installation, you click on an
        advertisement for the App (see the "Third-Party Advertisements" section
        below for more information).
        <Space /> In order to better understand our users of the Services, we
        may conduct research on our customer demographics, interests and
        behavior based on the information collected. This research may be
        compiled and analyzed on an aggregate basis, and we may share this
        aggregate data with our affiliates, agents and business partners. We may
        also disclose aggregated user statistics in order to describe our
        services to current and prospective business partners, and to other
        third parties for other lawful purposes. <Space />
        LOVO may use Google Analytics (see{' '}
        <a href="http://www.google.com/analytics/">
          http://www.google.com/analytics/
        </a>
        ), Google Firebase Analytics (see{' '}
        <a href="https://firebase.google.com/features/analytics/">
          https://firebase.google.com/features/analytics/
        </a>
        ), Crashlytics (see{' '}
        <a href="https://try.crashlytics.com/terms/privacy-policy.pdf">
          https://try.crashlytics.com/terms/privacy-policy.pdf
        </a>
        ) or other similar services to collect such information. The Application
        makes use of Google Analytics Demographics and Interest Reporting (see{' '}
        <a href="https://support.google.com/analytics/answer/2799357">
          https://support.google.com/analytics/answer/2799357
        </a>
        ) which gives LOVO certain demographic and interest information about
        our Application users. None of this information, however, can identify
        you personally to LOVO. The information may be used to serve you
        advertisements that will be of interest to you. You can disable this
        feature through your Android settings of your Device or the Google
        Settings App. You can also opt out of such Google advertising at any
        time by visiting your Google Ads Settings page (see{' '}
        <a href="https://www.google.com/settings/ads">
          https://www.google.com/settings/ads
        </a>{' '}
        ). <Space />
        <b>(b) Advertising partners.</b>
        <Space /> From time to time, we may also receive Personal Data about you
        from other third-party sources. For example, if you clicked on an
        advertisement directing you to one of our Services (such as an ad for
        one of our Apps in another publishers' app), we will be provided with
        Data about which ad network and advertising campaign the install
        originated from. Please see the Advertising section below for more
        information.
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="How we use your personal data">
        We use your Personal Data for the following purposes:
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="To provide and operate our Services">
        To provide you with the correct versions of our apps (e.g. knowing which
        language to make our apps available to you in).
        <Space /> To provide you with the best user experience (e.g.;
        fine-tuning features based on data) To provide you with the features and
        capabilities you requested (e.g.; providing custom voices)
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle
        title="To improve our Services and keep them secure
"
      >
        To respond to your customer support requests (e.g. if you write to us
        with a complaint), <Space />
        To receive error messages about any issues you or your device encounter
        with our Services to enable us to fix bugs and interoperability issues,{' '}
        <Space />
        To detect and prevent illegal activities, <Space />
        To track App installs (e.g. for purposes of measuring the effectiveness
        of marketing campaigns), <Space />
        To conduct optional user feedback surveys,
        <Space /> To understand you, your preferences and user traffic to
        enhance your experience and enjoyment of our Services,
        <Space /> To conduct research.
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="To personalize our Services">
        To remember you the next time you visit one of our Services, <Space />
        To communicate with you about promotions, rewards, upcoming events, and
        other news about products and services we offer.
        <Space /> To personalize speech emotion recognition service to you
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="For promotional activities, including marketing and advertising">
        To provide you with in-app offers and rewards based on your in-app
        behaviors, <Space />
        To serve you with relevant targeted advertisements (necessary to fund
        our Services), <Space />
        To make you offers to purchase in-app items on discount based on your
        in-app activity and purchase history, <Space />
        To send you marketing communications and/or newsletters about rewards
        and promotions if you have subscribed to them.
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Other">
        To comply with our legal obligations,
        <Space /> To exercise, establish or defend our legal rights, or to
        protect your vital interests or those of any other person, <Space />
        To prevent and/or detect fraud or fraudulent behavior.
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Advertising">
        We use advertising to fund some of our Services and to make some of our
        apps or versions of our apps available for free. We sometimes show
        third-party advertising in our Apps and Services via third parties. We
        want to provide you with more detail about how we use information for
        advertising, both in our properties and in third-party properties.
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="When you view ads in LOVO properties (e.g. in our Apps)">
        To enable us to show in-app ads, we may send your advertising ID, IP
        address and device information to advertisers (or ad networks) to enable
        them to find appropriate ads to serve to you in our Apps and on
        third-party properties. You can control and limit our use of your
        advertising ID in your device settings. LOVO does not control which
        specific ads are shown in our apps (this is controlled by the ad
        networks), but we do prohibit certain types of ads.
        <Space /> Advertising IDs are used by ad networks for a number of
        purposes, including, for example, to: <Space />
        Limit the number of times you see the same ad. <Space />
        Ensure they are dealing with humans with real devices and not automated
        "bots". <Space />
        Serve you with advertisements likely to be relevant to you based on your
        advertising ID, your engagement with other brands and ads which is
        recorded using your advertising ID, and to use your general geographic
        area.
        <Space /> Other brands may buy ad placements via an ad network or ad
        exchange to advertise their products in our Apps. The ad network or ad
        exchange acts as an intermediary between the other app companies and
        LOVO. In order for the ad network to charge the company purchasing the
        ad, clicks from the ad and potential installs of other developers' apps
        are tracked. These views, clicks and installs from ads are measured
        using the Advertising ID. <Space />
        Track which apps you have used to market you similar kind of apps. Your
        IP address is typically used by advertisers to identify your general
        geographic location, for example, your country, state and, sometimes,
        your city.
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle
        title="When you view LOVO ads on third-party services
"
      >
        LOVO also advertises its Apps on other apps by asking ad networks to
        place ads for audiences that will be interested in them, determined
        using Advertising IDs. Ad networks use Advertising IDs for this and
        other purposes, including, for example, to: <Space />
        Measure ad effectiveness (including total views, clicks and installs
        generated by its advertising in other publishers' apps),
        <Space /> Not display ads for an app a user is already using. LOVO may
        send a list of active users in the form of Advertising IDs to an ad
        network. The ad network then excludes these users from seeing the ad.
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Third-party advertisements">
        Where you have downloaded one of our apps, we may use Google, a
        third-party vendor, to serve advertisements in the App, using the AdMob
        system (see{' '}
        <a href="http://www.google.com/ads/admob/">
          http://www.google.com/ads/admob/
        </a>
        ).
        <Space /> In addition to Google advertisements, from time to time we may
        allow other third-party companies to serve ads and/or collect certain
        anonymous data when you use the Application. <Space />
        You can opt out of interest-based advertising on mobile applications by
        changing the privacy settings of your device and selecting "limit ad
        tracking" (Apple iOS) or "opt out of interest-based ads" (Android).
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Legal basis for processing Personal Data">
        Our legal basis for collecting and using the Personal Data described
        above will depend on the Personal Data concerned and the specific
        context in which we collect it. However, we will normally collect
        Personal Data from you only where (i) we need the Personal Data to
        perform a contract with you, (ii) the processing is in our legitimate
        interests and not superseded by your rights, or (iii) we have your
        consent to do so.
        <Space /> (a) Under our terms of service, "performance of contract" as a
        legal basis for processing your Personal Data refers to when we make the
        App and Services available to you, provide customer support, integrate
        Apps with technology and distribution platforms such as Google Chrome,
        Google Android, and Apple iOS, and inform you of service-related changes
        and App progress. <Space />
        (b) We process your Personal Data on the basis of our legitimate
        interests: for example, when we provide you the App and App events,
        serve interest-based advertisements to you (both in-app and on
        third-party properties), carry out marketing, keep our apps secure,
        utilize for engineering purposes, analyze the users' behavior across our
        Apps, moderate chat discussion. <Space />
        (c) We have a "legal obligation" to process your Personal Data when
        complying with a legal obligation, such as the prevention of a crime,
        fraud, or maintenance of tax records. (d) If we ask you to provide
        Personal Data to comply with a legal requirement or to perform a
        contract with you, we will make this clear at the relevant time and
        advise you whether the provision of your Personal Data is mandatory or
        not (as well as of the possible consequences if you do not provide your
        Personal Data).
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Who does LOVO share my personal data with?">
        We may share your Personal Data with the following types of recipients:
        <Space />
        (a) With third-party service providers and partners who provide data
        processing services to us (for example, to support the delivery of,
        provide functionality on, or help enhance the security of our Services),
        or parties that otherwise process Personal Data for the purposes
        described in this Policy or communicated to you when we collect your
        Personal Data. <Space />
        (b) With any other person, with your consent for such disclosure.
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="How does LOVO keep my personal data secure?">
        LOVO uses standard industry practice (physical, electronic, and
        procedural) to protect all data collected (including Personal Data)
        secure. We take reasonable steps to protect the Data provided via the
        Services from loss, misuse, and unauthorized access, disclosure,
        alteration, or destruction. However, no Internet or email transmission
        is ever fully secure or error-free. In particular, emails sent to or
        from the Services may not be secure. Therefore, you should take special
        care in deciding what Personal Data you send to us via email. Please
        keep this in mind when disclosing any Personal Data via the Internet.
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Data retention">
        We retain Personal Data we collect from you where we have an ongoing
        legitimate business need to do so, for example, to provide you with a
        Service you have requested, or to comply with applicable legal, tax or
        accounting requirements. We generally retain Personal Data so long as it
        may be relevant to the purposes identified herein. To dispose of
        Personal Data, we may anonymize it, delete it or take other appropriate
        steps. Personal Data may persist in copies made for backup and business
        continuity purposes for additional time, but it will be deleted as soon
        as practicable.
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Your data protection rights">
        If you wish to inquire about and access what Personal Data LOVO holds
        about you to verify and/or correct it, or if you wish to delete any
        Personal Data held about you from our databases, please contact us using
        the information further up in this Policy. Please note that deletion of
        your Personal Data may make it impossible for you to use the Services.
        Please note that if you request deletion of your Personal Data, LOVO
        reserves the right to retain some of your Personal Data for a reasonable
        time in order to satisfy our legal obligations, or where we reasonably
        believe that we have a legitimate reason to do so. <Space />
        You have the following data protection rights: <Space />
        (a) To access, correct, update or request deletion of your Personal
        Data. <Space />
        (b) To object to the processing of your Personal Data (which is
        processed on the grounds of legitimate interests), ask us to restrict
        processing of your Personal Data or request portability of your Personal
        Data. <Space />
        (c) To opt out of marketing communications we send you, at any time.
        <Space /> (d) If we are processing your Personal Data with your consent,
        you can withdraw your consent at any time. Withdrawing your consent will
        not affect the lawfulness of any processing we conducted prior to your
        withdrawal, nor will it affect the processing of your Personal Data
        conducted pursuant to lawful processing grounds other than consent.
        <Space /> If you wish to exercise any of these rights, please contact us
        at <a href="mailto:customersupport@lovo.ai">customersupport@lovo.ai</a>
        <Space /> (e) You have the right to file a complaint with a data
        protection authority.
        <Space /> You can control and limit the type of Personal Data we collect
        and process about you in a number of ways. For example, you can reset
        your mobile advertising identifier or limit ad tracking altogether using
        the settings on your phone. You can also limit or disable the use of
        cookies (or similar technologies). We will respond to all requests we
        receive from individuals wishing to exercise their data protection
        rights in accordance with applicable data protection laws.
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Transfer of your Personal Data outside of the European Economic Area (EEA)">
        For European users, we endeavour to keep your personal data inside the
        EEA. However, a certain number of our data processors (and LOVO) are in
        other countries where your Personal Data may be transferred. However,
        these countries are limited to countries with particular circumstances
        that protect your data, specifically: <Space />
        <b>Canada</b>. Canada has been determined to have an "adequate level of
        protection" for your Personal Data{' '}
        <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en">
          under European data protection law.
        </a>{' '}
        <Space />
        <b>The United States</b>. Your personal data is only transferred to
        companies in the United States that: (1) participate in the{' '}
        <a href="https://www.privacyshield.gov/welcome">Privacy Shield</a>; and
        (2) have signed agreements with us or have informed us that they are
        GDPR-compliant. <Space />
        <b>Privacy Policy in Regard to Children</b> <Space />
        LOVO does not knowingly collect personal data from children under the
        age of 13. If you are under the age of 13, please do not submit any
        personal information through the Site, Service, or App. We encourage
        parents and legal guardians to monitor their children's Internet usage
        and to help enforce this Policy by instructing their children never to
        provide personal information through the App or Service without their
        permission.
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Use of device permissions">
        The following is a listing and description of certain functions on the
        Device that are accessed and/or modified by our Apps, and which may
        require explicit user permission to access. You acknowledge that denying
        explicit user permission may affect or reduce your user experience with
        the App, or prevent the App from working at all.
        <Space /> <b>Laptops / Computers</b>
        <Space />
        UPLOAD, DOWNLOAD, SHARE TEXT & AUDIO
        <Space /> Allows the App to access text and audio files on the Device.
        <Space /> ACCESS MICROPHONE
        <Space /> Allows the App to make Recordings using the microphone.
        <Space /> CHANGE YOUR AUDIO SETTINGS <Space />
        Allows the App to modify global audio settings such as volume and which
        speaker is used for input/output. <Space />
        FULL NETWORK ACCESS <Space />
        Allows the App to create network sockets and use custom network
        protocols.
        <Space /> PAIR WITH BLUETOOTH DEVICES / ADMINISTRATE BLUETOOTH
        <Space /> Allows the App to view the configuration of Bluetooth on the
        Device, and to make and accept connections with paired devices.
        <Space /> MODIFY OR DELETE THE CONTENTS OF YOUR USB STORAGE / MODIFY OR
        DELETE THE CONTENTS OF YOUR SD CARD
        <Space /> Allows the App to read and write to the USB storage and / or
        SD card.
        <Space /> PREVENT DEVICE FROM SLEEPING
        <Space /> Allows the App to prevent the Device from going to sleep to
        ensure continuous recording.
        <Space />
        MODIFY SYSTEM SETTINGS <Space />
        Allows the App to modify the system's settings data (i.e. to set a
        Recording as a ringtone).
        <Space /> VIEW NETWORK CONNECTIONS
        <Space />
        Allows the App to view information about network connections such as
        which networks exist and are connected.
        <Space /> CHECK LICENSE
        <Space /> Verifies the market license. <Space />
        <b>Android</b> <Space />
        ACCESS MICROPHONE <Space />
        Allows the App to make Recordings using the microphone.
        <Space /> CHANGE YOUR AUDIO SETTINGS <Space />
        Allows the App to modify global audio settings such as volume and which
        speaker is used for input/output.
        <Space /> FULL NETWORK ACCESS
        <Space />
        Allows the App to create network sockets and use custom network
        protocols.
        <Space /> PAIR WITH BLUETOOTH DEVICES / ADMINISTRATE BLUETOOTH
        <Space /> Allows the App to view the configuration of Bluetooth on the
        Device, and to make and accept connections with paired devices.
        <Space /> MODIFY OR DELETE THE CONTENTS OF YOUR USB STORAGE / MODIFY OR
        DELETE THE CONTENTS OF YOUR SD CARD <Space />
        Allows the App to read and write to the USB storage and / or SD card.
        <Space /> PREVENT DEVICE FROM SLEEPING / PREVENT PHONE FROM SLEEPING
        <Space /> Allows the App to prevent the Device from going to sleep to
        ensure continuous recording.
        <Space /> MODIFY SYSTEM SETTINGS
        <Space /> Allows the App to modify the system's settings data (i.e. to
        set a Recording as a ringtone).
        <Space /> VIEW NETWORK CONNECTIONS
        <Space /> Allows the App to view information about network connections
        such as which networks exist and are connected.
        <Space /> READ PHONE STATE
        <Space /> When enabled, allows the App to pause a Recording when a phone
        call is received. <Space />
        CHECK LICENSE <Space />
        Verifies the market license.
        <Space /> <b>iOS</b> <Space />
        MICROPHONE
        <Space /> Allows the App to make Recordings using the microphone.
        <Space />
        SPEECH RECOGNITION <Space />
        Allows the Application to generate transcriptions of the Recordings.
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Age limit">
        When we collect Personal Data, we do not know the age of our users. If
        we discover we have inadvertently gathered Personal Data from a minor
        under the 13 years of age, we will take reasonable measures to promptly
        delete such Personal Data from our records.
      </ParagraphWithTitle>

      <Space />

      <h2>Terms of Service</h2>
      <ParagraphWithTitle>
        Please read these Terms of Service ("Terms", "Terms of Service")
        carefully before using{' '}
        <a href="https://api.lovo.ai" rel="noopener">
          https://api.lovo.ai
        </a>
        ,{' '}
        <a href="https://studio.lovo.ai" rel="noopener">
          https://studio.lovo.ai
        </a>
        ,{' '}
        <a href="https://www.lovo.ai" rel="noopener">
          https://www.lovo.ai
        </a>{' '}
        website, or any of the products and service offerings operated by LOVO
        Inc. ["us", "we", or "our"].
        <Space />
        Your access to and use of the Service is conditioned on your acceptance
        of and compliance with these Terms. These Terms apply to all visitors,
        users and others who access or use the Service.
        <Space /> By accessing or using the Service you agree to be bound by
        these Terms. If you disagree with any part of the terms, then you may
        not access the Service. If you create your own Custom Voice, additional
        terms and conditions shall apply. Please read them{' '}
        <a
          className="tos"
          href="https://www.lovo.ai/tos-customvoice"
          rel="noopener"
        >
          here.
        </a>
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Accounts">
        When you create an account with us, you must provide us information that
        is accurate, complete, and current at all times. Failure to do so
        constitutes a breach of the Terms, which may result in immediate
        termination of your account on our Service. <Space />
        You are responsible for safeguarding the password that you use to access
        the Service and for any activities or actions under your password,
        whether your password is with our Service or a third-party service.{' '}
        <Space />
        You agree not to disclose your password to any third party. You must
        notify us immediately upon becoming aware of any breach of security or
        unauthorized use of your account.
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Links to Other Websites">
        Our Service may contain links to third-party web sites or services that
        are not owned or controlled by LOVO Inc.LOVO Inc. <Space /> has no
        control over, and assumes no responsibility for, the content, privacy
        policies, or practices of any third-party web sites or services.{' '}
        <Space />
        You further acknowledge and agree that LOVO shall not be responsible or
        liable, directly or indirectly, for any damage or loss caused or alleged
        to be caused by or in connection with use of or reliance on any such
        content, goods or services available on or through any such web sites or
        services. <Space />
        We strongly advise you to read the terms and conditions and privacy
        policies of any third-party web sites or services that you visit.
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Termination">
        We may terminate or suspend access to our Service immediately, without
        prior notice or liability, for any reason whatsoever, including without
        limitation if you breach the Terms. <Space />
        All provisions of the Terms which by their nature should
        survivetermination shall survive termination, including, without
        limitation, ownership provisions, warranty disclaimers, indemnity and
        limitations of liability. <Space />
        We may terminate or suspend your account immediately, without prior
        notice or liability, for any reason whatsoever, including without
        limitation if you breach the Terms. <Space />
        Upon termination, your right to use the Service will immediately cease.
        If you wish to terminate your account, you may simply discontinue using
        the Service. <Space />
        All provisions of the Terms which by their nature should survive
        termination shall survive termination, including, without limitation,
        ownership provisions, warranty disclaimers, indemnity and limitations of
        liability.
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Governing Law">
        These Terms shall be governed and construed in accordance with the laws
        of Delaware, without regard to its conflict of law provisions. <Space />
        Our failure to enforce any right or provision of these Terms will not be
        considered a waiver of those rights. If any provision of these Terms is
        held to be invalid or unenforceable by a court, the remaining provisions
        of these Terms will remain in effect. These Terms constitute the entire
        agreement between us regarding our Service, and supersede and replace
        any prior agreements we might have between us regarding the Service.
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Changes">
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material we will try to provide at
        least 30 days notice prior to any new terms taking effect. What
        constitutes a material change will be determined at our sole discretion.{' '}
        <Space />
        By continuing to access or use our Service after those revisions become
        effective, you agree to be bound by the revised terms. If you do not
        agree to the new terms, please stop using the Service
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Limitations on Liability">
        LOVO SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
        PUNITIVE, OR CONSEQUENTIAL DAMAGES, OR ANY LOSS OF REVENUE OR PROFITS
        (EXCLUDING FEES UNDER THIS AGREEMENT), DATA, OR DATA USEARISING IN
        CONNECTION WITH YOUR USE OR INABILITY TO USE THE SERVICE. <Space />{' '}
        LOVO’SMAXIMUM LIABILITY FOR ANY AND ALL DAMAGES ARISING OUT OF OR
        RELATED TO THIS AGREEMENT, THE SERVICEOR A YOUR PLAN, WHETHER INCONTRACT
        OR TORT, OR OTHERWISE, SHALL NOTEXCEED, IN THE AGGREGATE, THE TOTAL
        AMOUNTS ACTUALLY PAID TO LOVOFOR THE SERVICES IN THE THREE(3) MONTH
        PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO SUCH CLAIM LESS
        ANY REFUNDS OR CREDITS RECEIVED BY YOUFROM LOVOUNDER SUCH PLAN.
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Cancellation & Refund Policy">
        You can cancel your Plan at any time to stop the auto renewal of your
        plan. <Space /> If you decide to cancel your Plan, you will still have
        access to your Plan until the day of expiration and will be charged
        accordingly. You can also reconvene the auto renewal of your Plan
        anytime before the Plan expires. <Space />
        Due to the nature of our services, we will not be providing refunds once
        you have confirmed payment of your purchase. <Space />
        LOVO is not responsible for any bank charges, commissions or overdrafts.
        The only way to remove these charges is through direct negotiation with
        your bank. <Space />
        If you update your plan at any time, your existing plan will be
        cancelled and the new plan, with all its service capabilities and
        duration, will go in effect immediately upon payment confirmation unless
        otherwise instructed.
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Use Rights. Commercial Rights & Resell Rights">
        During the term of your Plan, you may use the Voice Skins and Custom
        Voice for the usages permitted by your Plan as provided in the Usage
        Guideline below. Voice Skins and Custom Voices you download during the
        term of your Plan may be copied, transmitted and synched with audio
        visual works so long as such use is within the scope of the rights
        granted in your Plan. The foregoing rights and licenses and those
        defined in the Usage Guideline are conditioned upon your strict
        adherence to theterms and conditions hereof and any other applicable
        terms and conditions that LOVO may present. <Space />
        Commercial Rights include any monetized, business-related uses such as
        videos, audio books, advertising, promotion, web page vlogging, product
        integration that you do with VoiceSkins and Custom Voice created by
        LOVO. <Space />
        You may not use the Voice Skinsor Custom Voicefor any illegal, immoral,
        or defamatory purposes. <Space />
        Commercial rights do not include reselling or white labeling access to
        the Services in order to obtain Voice Skins and Custom Voices for
        re-sale or re-license to your customer or other third parties. <Space />{' '}
        If you want to white label the LOVO Service to your customers, please
        contact LOVO separately. <Space />
        Some examples of use cases that require separately authorized resell
        rights not included in any of the Plan include web and mobile
        applications that resemble,accessor provide a gateway to LOVO Studio or
        LOVO API's core functionalityfor re-sale by you to third parties.{' '}
        <Space />
        LOVO shall own all right, title and interest in Voice Skins made
        available to you on the Service,but disclaims any ownership right in the
        text(s)you submit to create a Voice Skin audio file(s)or Custom Voice
        audio file(s).
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Custom Voice Cloning">
        If you create your own Custom Voice, additional terms and conditions
        shall apply. Please read them{' '}
        <a
          className="tos"
          href="https://www.lovo.ai/tos-customvoice"
          rel="noopener"
        >
          here
        </a>
        . <Space /> By using Custom Voice Cloning services offered by LOVO, you
        grant permission to LOVO and its affiliates to use the data you provided
        for research and development purposes to further improve the technology,
        products, and services they offer. Any data, including sensitive
        personal information, will not be shared with 3rd parties unless for the
        purposes of research, development, and enhancement of technology and
        services offered by LOVO and its affiliates.
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Supportand Service Level Agreement">
        Subject to the terms of these Terms of Service, LOVOwill use
        commercially reasonable efforts to provide youthe Services. As part of
        the registration process, youwill identify an administrative user name
        and password for yourLOVOaccount. LOVOreserves the right to refuse
        registration of, or cancel passwords it deems inappropriate. <Space />
        Subject to the terms hereof, LOVOwill provide youwith reasonable
        technical support services in accordance with LOVO’s standard practice.{' '}
        <Space />
        LOVOshall use reasonable efforts consistent with prevailing industry
        standards to maintain the Services in a manner which minimizes errors
        and interruptions in the Services in a professional and workmanlike
        manner. Services may be temporarily unavailable for scheduled
        maintenance or for unscheduled emergency maintenance, either by LOVOor
        by third-party providers, or because of other causes beyond LOVO’s
        reasonable control, but LOVOshall use reasonable efforts to provide
        advance notice in writing or by e-mailof any scheduled service
        disruption (at least 5 days’ notice in advance). EXCEPT AS EXPRESSLY SET
        FORTH IN THIS SECTION, THE SERVICES ARE PROVIDED “AS IS” AND
        LOVODISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDINGIMPLIED
        WARRANTIES OF MERCHANTABILITY.
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Affiliate Program Terms & Policy">
        Affiliate Program Terms & Policy can be found{' '}
        <a
          className="tos"
          href="https://www.lovo.ai/affiliate-tos"
          rel="noopener"
        >
          here
        </a>
        .
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="Voice Marketplace">
        "Usage of Voice" means the various authorized use of Voice Skins and
        Custom Voices downloadedby a user with a paid subscriptionPlan"
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle
        className="privacy-user-guide"
        title="Usage Guideline"
      >
        <img src={usageGuideImg} alt="user guide line" width="800" />
      </ParagraphWithTitle>

      <Space />

      <ParagraphWithTitle title="General Terms">
        Reverse Engineering. youwill not, directly or indirectly: reverse
        engineer, decompile, disassemble or otherwise attempt to discover the
        source code, object code or underlying structure, ideas, know-how or
        algorithms relevant to the Services or any software, documentation or
        datarelated to the Services (“Software”); modify, translate, or make
        competitive products or works based on the Services or any Software
        (except to the extent expressly permitted by LOVOor authorized within
        the Services); use the Services or any Software fortimesharing or
        service bureau purposes or otherwise for the benefit of a third; or
        remove any proprietary notices or labels. With respect to any Software
        that is distributed or provided to youfor use on youpremises or devices,
        LOVOhereby grants youa non-exclusive, non-transferable,
        non-sublicensable license to use such Software during the term of your
        Plan only in connection with the Services. <Space />
        Compliance with Laws. Yourepresent, covenant, and warrant that youwill
        use the Services only in compliance with all applicable laws and
        regulations. youhereby agree to indemnify and hold harmless LOVOagainst
        any damages, losses, liabilities, settlements and expenses (including
        without limitation costs and attorneys’ fees) awarded in a final court
        judgment, in connection with any claim or action that arises from a
        violation of the foregoing or otherwise from yourmisuse of Services.
        Although LOVOhas no obligation to monitor youruse of the Services,
        LOVOmay do so and may prohibit any use of the Services it believes may
        be (or alleged to be) in violation of the foregoing.If any provision of
        these Terms of Serviceis found to be unenforceable or invalid, that
        provision will be limited or eliminated to the minimum extentnecessary
        so that these Terms of Servicewill otherwise remain in full force and
        effect and enforceable. This Agreement is not assignable, transferable
        or sublicensable by LOVO except with yourprior written consent. youmay
        transfer and assign any of itsrights and obligations under these Terms
        of Servicewithout consent. These Terms of Servicethe complete and
        exclusive statement of the mutual understanding of the parties and
        supersedes and cancels all previous written and oral agreements,
        communicationsand other understandings relating to the subject matter of
        these Terms of Service, and that all waivers and modifications must be
        in a writing signed by both parties, except as otherwise provided
        herein. No agency, partnership, joint venture, or employment is created
        as a result of these Terms of Serviceand youdo not have any authority of
        any kind to bind LOVO in any respect whatsoever. In any action or
        proceeding to enforce rights under these Terms of Service, the
        prevailing party will be entitled to recover costs and attorneys’ fees.
        All notices under these Terms of Servicewill be in writing and will be
        deemed to have been duly given when received, if personally delivered;
        when receipt is electronically confirmed, if transmitted by facsimile or
        e-mail;the day after it is sent, if sent for next day delivery by
        recognized overnight delivery service; and upon receipt, if sent by
        certified or registered mail, return receipt requested. This Agreement
        shall be governed by the laws of Delawarewithout regard to its conflict
        of laws provisions Any claim, dispute or controversy between the Parties
        will be subject to the exclusive jurisdiction and venue of the courts
        located in Delaware, and each party hereby irrevocably submits to the
        personal jurisdiction of such courts and waives any jurisdictional,
        venue, or inconvenient forum objections to such courts.
      </ParagraphWithTitle>
    </Container>
  );
};

export default PrivacyInfo;

const Container = styled.div`
  /* max-width: 1200px; */
  margin: 0 auto;
  padding-bottom: 30px;
  text-align: initial;
  h2 {
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    margin: 100px 0 40px;
  }
  .privacy-user-guide {
    img {
      display: block;
      margin: 0 auto;
      image-rendering: -webkit-optimize-contrast;
    }
  }
`;

const Space = styled.span`
  display: block;
  height: 20px;
`;
