import React from 'react';
import styled from 'styled-components';
import { Button, CustomCheckBox, CloseSvg } from '..';
import { logoColor, hilightedLogoColor } from '../../../lib/styles/palette';
import discount_background from '../../../images/discount_background.jpg';

const Fullscreen = styled.div`
  position: fixed;
  z-index: 51;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AskModalBlock = styled.div`
  position: relative;
  width: 600px;
  padding-bottom: 32px;
  min-width: 554px;
  border-radius: 0px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
  background-image: url(${discount_background});
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;

  .buttons {
    display: flex;
    justify-content: flex-end;
  }
`;
const ModalTitle = styled.div`
  margin-top: 63px;
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: 900;
  letter-spacing: 1.08px;
`;
const ButtonWithMarginTop = styled(Button)`
  width: 323px;
  height: 59px;
  margin-top: 40px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 0;
  font-size: 18px;
  font-weight: bolder;
  letter-spacing: 0.36px;
  font-family: MyFont;
  color: ${logoColor};

  :hover {
    background-color: #fff;
    color: ${hilightedLogoColor};
  }
`;
const Description = styled.div`
  white-space: pre-wrap;
  width: 75%;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.3px;
  text-align: center;
  color: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 20px;
  div {
    margin-top: 20px;
    font-size: 20px;
    font-weight: normal;
  }
`;
const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const NeverShow = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  color: #fff;
  margin-left: 6px;
`;
const CloseButton = styled.button`
  position: absolute;
  width: 17px;
  height: 17px;
  border: 0;
  outline: none;
  text-align: center;
  padding: 0;
  color: #fff;
  background-color: transparent;
  cursor: pointer;

  top: 27px;
  right: 44px;
`;
const DontShowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  visible: boolean;
  onCancel: VoidFunction;
  onSubmit?: Function;
  submitText?: string;
  isCheckedDiscountNeverShow?: boolean;
  setDiscountNeverShow?: Function;
  sale_amount?: number;
  title: string;
  description: string;
  code?: string;
  modalStyle?: React.CSSProperties;
}

const Modal: React.FC<Props> = ({
  visible,
  onCancel,
  onSubmit,
  submitText,
  isCheckedDiscountNeverShow,
  setDiscountNeverShow,
  sale_amount,
  title,
  description,
  code,
  modalStyle,
}) => {
  if (!visible) return null;
  return (
    <Fullscreen>
      <AskModalBlock style={modalStyle}>
        <RightContainer>
          <CloseButton onClick={onCancel}>
            <CloseSvg />
          </CloseButton>
        </RightContainer>
        <ModalTitle>{title}</ModalTitle>
        <Description>
          {description}
          {code ? (
            <div>
              <span style={{ fontWeight: 'bold' }}>
                {`Use code at checkout ${code}`}
              </span>
            </div>
          ) : (
            ''
          )}
        </Description>
        <ButtonWithMarginTop cyan fullWidth onClick={onSubmit}>
          {submitText}
        </ButtonWithMarginTop>
        {setDiscountNeverShow ? (
          <DontShowContainer>
            <CustomCheckBox
              size="15px"
              viewSize={15}
              isChecked={isCheckedDiscountNeverShow || false}
              onChange={(e) => {
                setDiscountNeverShow(e);
              }}
            />
            <NeverShow>Don’t show this again</NeverShow>
          </DontShowContainer>
        ) : (
          ''
        )}
      </AskModalBlock>
    </Fullscreen>
  );
};

export default Modal;
