import React from 'react';
import styled from 'styled-components';
import { logoColor, MAIN_CONTENT_WIDTH } from '../../lib/styles/palette';

const Container = styled.div`
  padding-bottom: 30px;
`;

const Wrapper = styled.div`
  width: ${MAIN_CONTENT_WIDTH};
  flex: 1;
  margin: auto;
  h2 {
    margin: 35px 0;
    padding-left: 2.5rem;
    font-size: 35px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: ${logoColor};
  }

  p {
    margin-bottom: 35px;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    text-align: left;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    color: #242424;
  }

  span,
  a {
    color: ${logoColor};
  }

  a:hover {
    text-decoration: underline;
  }
`;

const EthicsInfo = () => {
  return (
    <Container>
      <Wrapper>
        <h2>
          It takes the Human Voice to Infuse [words] with deeper meaning-Maya
          Angelou
        </h2>
        <p>
          We believe in the power of voice. Voices can mend or break hearts,
          instigate or resolve conflicts, provide a meaning of life to some and
          take away their very existence.{' '}
        </p>
        <p>
          We are also aware of the challenges and the weight of responsibility
          that falls upon creators of such technology like us, and the users of
          such innovation - YOU.{' '}
        </p>
        <p>
          That is why we will continue to have conversations with industry
          experts, academics, educators, legal professionals, researchers,
          investors, and most importantly, customers, to establish a platform of
          discussion and a safe-ground where people can experiment and
          experience firsthand.
        </p>
        <p>
          Everything you provide, and everything created from and for you, will
          be kept confidential- all the data, information, and our interaction.
          You will have control over their usage, and no one will be able to
          utilize them without your explicit consent.{' '}
        </p>
        <p>
          Every great technology is a kitchen knife- we can make delicious food
          with it, or hurt others in malignancy.{' '}
        </p>
        <p>
          Let’s pave the path. <br /> Together.{' '}
        </p>
      </Wrapper>
    </Container>
  );
};

export default EthicsInfo;
