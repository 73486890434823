import { Parser } from 'htmlparser2';
import { DomHandler } from 'domhandler';
import tokenizer from 'sbd';

export const FEATURE_VALUE = {
  class: {
    emphasis: 'feature-emphasis',
    pause: 'feature-pause',
    speed: 'feature-speed',
  },
  value: {
    emphasis: 'data-emphasis-value',
    pause: 'data-pause-value',
    speed: 'data-speed-value',
  },
};

const tokenOptions = {
  newline_boundaries: true,
  html_boundaries: true,
  // preserve_whitespace: true
};

const pasreFeature = async (rawHtml) => {
  let promise = await new Promise((resolve, reject) => {
    const handler = new DomHandler(function (error, dom) {
      if (error) {
        console.error(error);
      } else {
        // Parsing completed, do something
        let parseText = '';
        let dataChunk;
        // data-sentence
        for (let i = 0; i < dom.length; i++) {
          dataChunk = dom[i];
          // 1. data-sentence is span
          let dataText;
          if (dataChunk.type === 'tag' && dataChunk.name === 'b') {
            let openTextTag;
            openTextTag = `<span class="emphasis"><b>`;
            for (let m = 0; m < dataChunk.children.length; m++) {
              dataText = dataChunk.children[m];
              if (
                dataText.type === 'text' &&
                dataText.data.trim().length !== 0
              ) {
                let wordData = dataText.data.split(/\s+/g);
                for (let l = 0; l < wordData.length; l++) {
                  if (l !== 0) {
                    parseText += ' ';
                  }
                  if (wordData[l].length !== 0) {
                    parseText += openTextTag + wordData[l] + '</b></span>';
                  }
                }
              }
            }
          } else if (
            dataChunk.type === 'text' &&
            dataChunk.data.trim().length !== 0
          ) {
            let openTextTag = `<span class="emphasis">`;
            let wordData = dataChunk.data.split(/\s+/g);
            for (let l = 0; l < wordData.length; l++) {
              if (l !== 0) {
                parseText += ' ';
              }
              if (wordData[l].length !== 0) {
                parseText += openTextTag + wordData[l] + '</span>';
              }
            }
          } else if (
            dataChunk.type === 'text' &&
            dataChunk.data.trim().length === 0
          ) {
            parseText += dataChunk.data;
          } else if (dataChunk.type === 'tag' && dataChunk.name === 'br') {
            parseText += '<br>';
          }
        }
        // callback(parseText);
        resolve(parseText);
      }
    });
    const parser = new Parser(handler);
    parser.write(rawHtml);
    parser.end();
  }).catch((err) => {
    throw err;
  });
  return promise;
};

export const parseHtmlForSplit = async (rawHtml, callback) => {
  let data = [];
  let response;
  let parseData;
  for (let i = 0; i < rawHtml.length; i++) {
    parseData = rawHtml[i].text
      .replace(/&nbsp;/g, ' ')
      .replace(/<\/b><b>/g, '')
      .replace(/<\/?span[^>]*>/g, '');
    response = await pasreFeature(parseData);
    data.push(response);
  }
  callback(data);
};

export const parseEmphasisFeature = async (rawHtml) => {
  let removeUselessTag = rawHtml
    .trim()
    .replace(/<\/?span[^>]*>/g, '')
    .replace(/&nbsp;/g, ' ')
    .replace(/<\/?br[^>]*>/g, ' ')
    .replace(/<\/b><b>/g, '');
  let promise = await new Promise((resolve, reject) => {
    const handler = new DomHandler(function (error, dom) {
      if (error) {
        console.error(error);
      } else {
        // Parsing completed, do something
        let dataChunk;
        // data-sentence
        let numberOfIndex = 0;
        let emphasisList = [];
        for (let i = 0; i < dom.length; i++) {
          dataChunk = dom[i];
          // 1. data-sentence is span
          let dataText;
          if (dataChunk.type === 'tag' && dataChunk.name === 'b') {
            for (let m = 0; m < dataChunk.children.length; m++) {
              dataText = dataChunk.children[m];
              if (
                dataText.type === 'text' &&
                dataText.data.trim().length !== 0
              ) {
                let wordData = dataText.data.trim().split(/\s+/g);
                for (let l = 0; l < wordData.length; l++) {
                  emphasisList.push(numberOfIndex);
                  numberOfIndex++;
                }
              }
            }
          } else if (
            dataChunk.type === 'text' &&
            dataChunk.data.trim().length !== 0
          ) {
            let wordData = dataChunk.data.trim().split(/\s+/g);
            for (let l = 0; l < wordData.length; l++) {
              numberOfIndex++;
            }
          }
        }
        resolve(emphasisList);
      }
    });
    const parser = new Parser(handler);
    parser.write(removeUselessTag);
    parser.end();
  }).catch((err) => {
    throw err;
  });
  return promise;
};

export const pasreEmphasizeForConvert = async (rawHtml) => {
  const removeUselessTag = rawHtml
    .trim()
    .replace(/<\/?span[^>]*>/g, '')
    .replace(/&nbsp;/g, ' ')
    .replace(/<\/?br[^>]*>/g, ' ')
    .replace(/<\/b><b>/g, '');
  const promise = await new Promise((resolve, reject) => {
    const handler = new DomHandler(function (error, dom) {
      if (error) {
        console.error(error);
      } else {
        // Parsing completed, do something
        let parseText = '';
        const emphasizeSymbol = ';;)';
        let dataChunk;
        // data-sentence
        for (let i = 0; i < dom.length; i++) {
          dataChunk = dom[i];
          // 1. data-sentence is span
          let dataText;
          if (dataChunk.type === 'tag' && dataChunk.name === 'b') {
            for (let m = 0; m < dataChunk.children.length; m++) {
              dataText = dataChunk.children[m];
              if (
                dataText.type === 'text' &&
                dataText.data.trim().length !== 0
              ) {
                const wordData = dataText.data.split(/\s+/g);
                for (let l = 0; l < wordData.length; l++) {
                  if (l !== 0) {
                    parseText += ' ';
                  }
                  if (wordData[l].length !== 0) {
                    const checkSpecialChar = /[.,?!:;]/g.exec(wordData[l]);
                    if (
                      checkSpecialChar &&
                      checkSpecialChar.index &&
                      checkSpecialChar.index !== 0
                    ) {
                      parseText +=
                        wordData[l].slice(0, checkSpecialChar.index) +
                        emphasizeSymbol +
                        wordData[l].slice(checkSpecialChar.index);
                    } else {
                      parseText += wordData[l] + emphasizeSymbol;
                    }
                  }
                }
              }
            }
          } else if (dataChunk.type === 'text') {
            parseText += dataChunk.data;
          }
        }
        // callback(parseText);
        resolve(parseText);
      }
    });
    const parser = new Parser(handler);
    parser.write(removeUselessTag);
    parser.end();
  }).catch((err) => {
    throw err;
  });
  return promise;
};

export const parseHtmlForConvert = (rawHtml, callback) => {
  const handler = new DomHandler(function (error, dom) {
    if (error) {
      // Handle error
    } else {
      // Parsing completed, do something
      let result;
      let editorDataList = [];
      let dataSentence;
      let numberOfSentence = 0;
      // data-sentence
      for (let i = 0; i < dom.length; i++) {
        dataSentence = dom[i];
        // 1. data-sentence is span
        if (dataSentence.type === 'tag' && dataSentence.name === 'span') {
          result = {
            id: numberOfSentence.toString(),
            text: '',
            audioData: undefined,
            emphasis: [],
            pause: '0',
            speed: undefined,
            convertData: '',
          };

          let pauseValue = dataSentence.attribs[FEATURE_VALUE.value.pause];
          let speedValue = dataSentence.attribs[FEATURE_VALUE.value.speed];

          // open span tag
          if (pauseValue || speedValue) {
            result.text += `<span id="${numberOfSentence.toString()}" data-sentence="true" class="pause-speed${
              pauseValue ? ` ${FEATURE_VALUE.class.pause}` : ''
            }${speedValue ? ` ${FEATURE_VALUE.class.speed}` : ''}"${
              pauseValue ? ` ${FEATURE_VALUE.value.pause}="${pauseValue}"` : ''
            }${
              speedValue ? ` ${FEATURE_VALUE.value.speed}="${speedValue}"` : ''
            }>`;
          } else {
            result.text += `<span id="${numberOfSentence.toString()}" class="pause-speed" data-sentence="true">`;
          }
          if (pauseValue) {
            result.pause = pauseValue;
          }
          result.speed = speedValue
            ? [parseFloat(speedValue), 'false']
            : [1, 'false'];

          // span text content
          let dataChild = dataSentence.children;
          let dataChunk;
          let dataText;
          let numberOfIndex = 0;
          let textLists = [];
          let emphasisValue;
          for (let k = 0; k < dataChild.length; k++) {
            dataChunk = dataChild[k];
            if (dataChunk.type === 'tag' && dataChunk.name === 'span') {
              emphasisValue = dataChunk.attribs[FEATURE_VALUE.value.emphasis];
              let openTextTag;
              if (emphasisValue) {
                openTextTag = `<span class="${dataChunk.attribs.class}" ${FEATURE_VALUE.value.emphasis}="${emphasisValue}">`;
              } else {
                openTextTag = `<span class="${dataChunk.attribs.class}">`;
              }
              for (let m = 0; m < dataChunk.children.length; m++) {
                dataText = dataChunk.children[m];
                if (
                  dataText.type === 'text' &&
                  dataText.data.trim().length !== 0
                ) {
                  let wordData = dataText.data.trim().split(/\s+/g);
                  for (let l = 0; l < wordData.length; l++) {
                    result.text += ' ';
                    result.text += openTextTag + wordData[l] + '</span>';
                    result.text += ' ';
                    textLists.push(wordData[l]);
                    if (emphasisValue) {
                      result.emphasis.push(numberOfIndex);
                    }
                    numberOfIndex++;
                  }
                }
              }
            } else if (
              dataChunk.type === 'text' &&
              dataChunk.data.trim().length !== 0
            ) {
              let openTextTag = `<span class="emphasis">`;
              let wordData = dataChunk.data.trim().split(/\s+/g);
              for (let l = 0; l < wordData.length; l++) {
                if (l !== 0) {
                  result.text += ' ';
                }
                result.text += openTextTag + wordData[l] + '</span>';
                textLists.push(wordData[l]);
                numberOfIndex++;
              }
            }
          }
          //end span text data
          result.text += '</span>';
          result.convertData = textLists.join(' ');
          if (result.convertData.trim().length !== 0) {
            editorDataList.push(result);
            numberOfSentence++;
          }
        }
        // 2. data-sentence is text
        else if (dataSentence.type === 'text' && dataSentence.data) {
          //space check
          if (dataSentence.data.trim().length === 0) {
          } else {
            let sentences = tokenizer.sentences(
              dataSentence.data,
              tokenOptions,
            );
            for (let j = 0; j < sentences.length; j++) {
              if (j !== 0) {
                result.text += ' ';
              }
              result = {
                id: numberOfSentence.toString(),
                text: '',
                audioData: undefined,
                emphasis: [],
                pause: '0',
                speed: [1, 'false'],
                convertData: '',
              };
              result.text += `<span id="${numberOfSentence.toString()}" class="pause-speed" data-sentence="true">${
                sentences[j]
              }</span>`;
              result.convertData = sentences[j];
              editorDataList.push(result);
              numberOfSentence++;
            }
          }
        }
        // 3. data-sentence is br
        else if (dataSentence.type === 'tag' && dataSentence.name === 'br') {
        }
      }
      callback(editorDataList);
      parser.reset();
    }
  });
  const parser = new Parser(handler);
  parser.write(rawHtml);
  parser.end();
};

export const removeSpanTag = async (rawHtml) => {
  let promise = await new Promise((resolve, reject) => {
    const handler = new DomHandler(function (error, dom) {
      if (error) {
        // Handle error
        console.error(error);
      } else {
        // Parsing completed, do something
        let parseText = '';
        let dataChunk;
        // data-sentence
        for (let i = 0; i < dom.length; i++) {
          dataChunk = dom[i];
          // 1. data-sentence is span
          let dataText;
          let emphasisValue;
          if (dataChunk.type === 'tag' && dataChunk.name === 'span') {
            emphasisValue = dataChunk.attribs[FEATURE_VALUE.value.emphasis];
            let openTextTag;
            if (emphasisValue) {
              openTextTag = `<span class="${dataChunk.attribs.class}" ${FEATURE_VALUE.value.emphasis}="${emphasisValue}">`;
            } else {
              openTextTag = '';
            }
            for (let m = 0; m < dataChunk.children.length; m++) {
              dataText = dataChunk.children[m];
              if (dataText.type === 'text' && dataText.data.length !== 0) {
                parseText += openTextTag + dataText.data;
                if (emphasisValue) {
                  parseText += '</span>';
                }
              }
            }
          } else if (dataChunk.type === 'text' && dataChunk.data.length !== 0) {
            parseText += dataChunk.data;
          } else if (dataChunk.type === 'tag' && dataChunk.name === 'br') {
            parseText += '<br>';
          }
        }
        // callback(parseText);
        resolve(parseText);
      }
    });
    const parser = new Parser(handler);
    parser.write(rawHtml);
    parser.end();
  }).catch((err) => {
    throw err;
  });
  return promise;
};
