import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Link, NavLink, withRouter } from 'react-router-dom';
import logoSingleSvg from '../../images/logo_single.svg';
import downArrowImage from '../../images/up_arrow.png';
import upArrowImage from '../../images/down_arrow.png';
import {
  logoColor,
  SIDEBAR_LEFT_PADDING,
  SIDEBAR_WIDTH,
} from '../../lib/styles/palette';
import { useBill, usePosts, useUser } from '../../hooks';
import { AsyncStatus } from '../../modules/types';
import { redirectToAuthPage } from '../../lib/utils/http';
import JoinUsBtn from './joinUsBtn';
import discord from '../../images/JoinBtn/discord.svg';
import cvEvent from '../../images/event/vc_event.svg';

const MENU_MARGIN_BOTTOM = '15px';

const SideBarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: ${SIDEBAR_WIDTH};
  min-height: 100%;
  background-color: #ffffff;
  border-right: 1px solid #dadada;
  text-align: left;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
`;

const LogoContainer = styled.div`
  height: 140px;
  padding: 52px 0 0 ${SIDEBAR_LEFT_PADDING};
`;

const LogoImage = styled.img`
  width: 43px;
  height: auto;
`;

const PageMenuContainer = styled.div`
  margin-bottom: ${MENU_MARGIN_BOTTOM};
`;

const PageMenu = styled.div<{ disabled?: boolean }>`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  color: ${(props) => (props.disabled ? '#c1c1c1' : '#000')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  &.customvoice-tab {
    padding-right: 0px;
  }
  .customvoice-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    line-height: 30px;
  }
  span {
    padding-left: ${SIDEBAR_LEFT_PADDING};
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
  }
  .beta-tag {
    display: inline-block;
    height: 26px;
    margin-right: 20px;
    padding: 0 5px;
    color: ${logoColor};
    border: 1px solid ${logoColor};
    border-radius: 5px;
    line-height: 26px;
  }
  img {
    display: inline-block;
    width: 10px;
    height: auto;
  }
  a {
    padding-left: ${SIDEBAR_LEFT_PADDING};
    color: #000;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
  }
  :hover {
    background-color: #7361d409;
  }
`;

const MenuContainer = styled(NavLink)`
  position: relative;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${MENU_MARGIN_BOTTOM};
  padding: 0 20px 0 ${SIDEBAR_LEFT_PADDING};
  color: #000;
  span {
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
  }
  img {
    display: inline-block;
    width: 10px;
    height: auto;
  }

  div {
    position: absolute;
    display: none;
    left: 0;
    width: 6px;
    height: 100%;
    background-color: ${logoColor};
  }

  &.active {
    div {
      display: block;
    }
  }
  &.sub_page {
    margin-bottom: 0;
    span {
      color: ${logoColor};
      padding-left: 15px;
    }
  }
  :hover {
    background-color: #7361d409;
  }
`;

const ContactContainer = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: calc(15px + ${SIDEBAR_LEFT_PADDING});
  a {
    font-size: 14px;
    font-weight: bold;
    color: ${logoColor};
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    /* letter-spacing: 0.1px; */
    text-decoration: underline;
    white-space: nowrap; // Add email text overflow ellipsis css
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const BottomMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  padding-left: ${SIDEBAR_LEFT_PADDING};
  padding-bottom: 30px;
`;

const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const UserButton = styled.div`
  width: 43px;
  height: 43px;
  min-width: 43px;
  line-height: 43px;
  background-color: ${logoColor};
  border-radius: 50%;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.48px;
  text-align: center;
  color: #fff;
`;

const UserDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 43px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 3px 0;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  overflow: hidden;

  span {
    display: inline-block;
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  div {
    color: ${logoColor};
    cursor: pointer;
  }
`;

const SideBar: React.FC = () => {
  const { user, handleLogout, handleInitializeUserStatus } = useUser();
  const { handleInitializeWorkspace } = usePosts();
  const { handleInitializeBillStatus } = useBill();
  const [isShowWorkspaceSubPage, setVisibleWorkspaceSubPage] = useState(false);
  const [isShowAccountSubPage, setVisibleAccountSubPage] = useState(false);

  useEffect(() => {
    if (user.logout.status === AsyncStatus.SUCCESS) {
      handleInitializeUserStatus();
      handleInitializeWorkspace('all');
      handleInitializeBillStatus('all');
    }
  }, [
    user.logout.status,
    handleInitializeUserStatus,
    handleInitializeWorkspace,
    handleInitializeBillStatus,
  ]);

  const onLogoutClick = () => {
    handleLogout();
    redirectToAuthPage();
  };

  const joinDiscordOnClick = useCallback(() => {
    const hideDiscordModal = localStorage.getItem('discordModal');

    if (hideDiscordModal !== 'hide') {
      window.open('https://discord.com/invite/t7xHNR7ZyE');
      localStorage.setItem('discordModal', 'hide');
    }
    window.open(
      'https://discord.com/channels/836800776256487474/838647332186226708',
    );
  }, []);

  const joinCvEvent = useCallback(() => {
    window.open('https://www.lovo.ai/event');
  }, []);

  const userId = user.data?._id;
  return (
    <SideBarContainer>
      <LogoContainer>
        <Link to="/">
          <LogoImage src={logoSingleSvg} alt="logo" />
        </Link>
      </LogoContainer>
      <PageMenuContainer>
        <PageMenu
          onClick={() => setVisibleWorkspaceSubPage(!isShowWorkspaceSubPage)}
        >
          <span>WORKSPACE</span>
          {isShowWorkspaceSubPage ? (
            <img src={downArrowImage} alt="down arrow" />
          ) : (
            <img src={upArrowImage} alt="up arrow" />
          )}
        </PageMenu>
        {isShowWorkspaceSubPage && (
          <>
            <MenuContainer exact to="/" className="sub_page">
              <div />
              <span>Workspace</span>
            </MenuContainer>
            <MenuContainer to="/library" className="sub_page">
              <div />
              <span>Library</span>
            </MenuContainer>
            <MenuContainer
              to="/pronunciation/1/10"
              className="sub_page"
              isActive={(match, location) => {
                if (location.pathname.includes('pronunciation')) {
                  return true;
                }
                return false;
              }}
            >
              <div />
              <span>Pronunciation Editor</span>
            </MenuContainer>
          </>
        )}
      </PageMenuContainer>
      {/* <MenuContainer to="/how-to-use">
        <div />
        <span>HOW TO USE</span>
      </MenuContainer> */}
      <PageMenuContainer>
        <PageMenu className="customvoice-tab">
          <Link
            className="customvoice-link"
            to={userId ? `/customvoice?userId=${userId}` : '/'}
          >
            CUSTOM VOICE SKIN
            <b className="beta-tag">Beta</b>
          </Link>
          {/* <a href="https://www.lovo.ai/custom" target="blank">
            CUSTOM VOICE SKIN
          </a> */}
        </PageMenu>
      </PageMenuContainer>
      {user.data?.authType !== 'appsumo' ? (
        <MenuContainer to="/plan">
          <div />
          <span>MEMBERSHIP</span>
        </MenuContainer>
      ) : (
        ''
      )}
      <PageMenuContainer>
        <PageMenu
          onClick={() => setVisibleAccountSubPage(!isShowAccountSubPage)}
        >
          <span>ACCOUNT</span>
          {isShowAccountSubPage ? (
            <img src={downArrowImage} alt="down arrow" />
          ) : (
            <img src={upArrowImage} alt="up arrow" />
          )}
        </PageMenu>
        {isShowAccountSubPage && (
          <>
            <MenuContainer to="/profile" className="sub_page">
              <div />
              <span>My Info</span>
            </MenuContainer>
            <MenuContainer to="/ethics" className="sub_page">
              <div />
              <span>Ethics</span>
            </MenuContainer>
            <MenuContainer to="/privacy" className="sub_page">
              <div />
              <span>Terms & Policy</span>
            </MenuContainer>
            {/* <ContactContainer>
              <a href="mailto:customersupport@lovo.ai">
                customersupport@lovo.ai
              </a>
            </ContactContainer> */}
          </>
        )}
      </PageMenuContainer>
      <BottomMenuContainer>
        <JoinUsBtn
          logo={discord}
          onSubmit={joinDiscordOnClick}
          text="Join our channel!"
          style={{
            margin: '0 29px 20px 0',
          }}
        />
        {user.data && (
          <UserInfoContainer>
            <UserButton>
              {user.data.name?.trim().charAt(0).toUpperCase() ||
                user.data.email?.[0].toUpperCase() ||
                'L'}
            </UserButton>
            <UserDetailContainer>
              <span>{user.data.email}</span>
              <div onClick={onLogoutClick}>Log out</div>
            </UserDetailContainer>
          </UserInfoContainer>
        )}
      </BottomMenuContainer>
    </SideBarContainer>
  );
};

export default withRouter(SideBar);
