import client from './client';
import { ErrorResponse, Response } from './gatewayClient';

export const verify2checkoutCouponCodeApi = (
  couponCode: string,
): Promise<Response | ErrorResponse> =>
  client.post('/twocheckout/coupons/users/validate', {
    couponCode,
  });

export const apply2checkoutCouponToUser = (
  couponCode: string,
): Promise<Response | ErrorResponse> =>
  client.post('/twocheckout/coupons/users', {
    couponCode,
  });

export const disable2checkoutSubscription = (): Promise<
  Response | ErrorResponse
> => client.delete('/twocheckout/subscriptions');
