import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { logoColor } from '../../lib/styles/palette';

const LoaderContainer = styled.div`
  width: 117px;
  height: 37px;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  border-radius: 0;
`;

const LoaderText = styled.div`
  color: ${logoColor};
  margin-top: 2px;
`;

const ConvertLoader = ({
  isLoading = false,
  forConvert = false,
  length = 0,
}) => {
  const secondsToTime = (sec) => {
    var pad = function (num, size) {
        return ('000' + num).slice(size * -1);
      },
      time = parseFloat(sec).toFixed(3),
      minutes = Math.floor(time / 60) % 60,
      seconds = Math.floor(time - minutes * 60);

    return pad(minutes, 2) + ':' + pad(seconds, 2);
  };
  const [second, setSecond] = useState(length >= 1 ? length : 1);

  useEffect(() => {
    if (!second || second < 1) {
      return;
    }
    const intervalId = setInterval(() => {
      setSecond(second - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [second]);

  if (!isLoading) return null;

  return (
    <LoaderContainer>
      <LoaderText>Converting…</LoaderText>
      <LoaderText>{forConvert ? secondsToTime(second) : null}</LoaderText>
    </LoaderContainer>
  );
};

export default ConvertLoader;
