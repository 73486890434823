import client from './client';

export const getPlanListsApi = () => client.get('/billing/plan');

export const getSubscriptionStatusApi = () => client.get('/billing/status');

export const createSubscriptionApi = (
  plan: string,
  email: string,
  coupon: string,
  token?: string,
) => client.post('/billing/plan', { plan, email, token, coupon });

export const cancelSubsriptionApi = (
  subscription: string,
  why: string,
  competitiveSolution: string,
) =>
  client.delete('/billing/plan', {
    data: { subscription, why, competitiveSolution },
  });

export const reactiveSubscriptionApi = (subscription: string) =>
  client.post('/billing/reactivate', { subscription });

export const updateSubscriptionApi = (plan: string) =>
  client.put('/billing/plan', { plan });

export const updateDefaultCardApi = (
  source: string,
  token: string,
  email: string,
) => client.post('/billing/card', { source, token, email });

export const getSubscriptionHistoryApi = () => client.get('/billing/invoice');

export const getProrationApi = (plan: string) =>
  client.get(`/billing/proration?plan=${plan}`);
