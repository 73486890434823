import React from 'react';

const PlaySvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 24 24"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect x="5" y="3" width="2" height="18"></rect>
      <polygon points="11,3 22,12 11,21"></polygon>
    </svg>
  );
};

export default PlaySvg;
