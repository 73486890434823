import { User, StudioUser } from '../../modules/user/types';

export const removeUser = () => localStorage.removeItem('user');

export const getUser = (): User | null => {
  try {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : user;
  } catch (err) {
    return null;
  }
};

export const setUser = (user: User) =>
  localStorage.setItem('user', JSON.stringify(user));

export const removeStudioUser = () => localStorage.removeItem('studioUser');

export const getStudioUser = (): StudioUser | null => {
  try {
    const studioUser = localStorage.getItem('studioUser');
    return studioUser ? JSON.parse(studioUser) : studioUser;
  } catch (err) {
    return null;
  }
};

export const setStudioUser = (studioUser: StudioUser) =>
  localStorage.setItem('studioUser', JSON.stringify(studioUser));

export const setStorageItem = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const removeStorageItem = (key: string) => {
  localStorage.removeItem(key);
};

export const getStorageItem = (key: string) => {
  return localStorage.getItem(key);
};
