// import React, { useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../modules/index';
import {
  getPronunciationsRequest,
  createPronunciationRequest,
  updatePronunciationRequest,
  deletePronunciationRequest,
  convertAudioRequest,
  addInput,
  deleteInput,
  changeEditorField,
  changeEditorDataField,
  changePronunciationListField,
  loadEditorData,
  setSkin,
  changePronunciationField,
  initEditor,
  PronunciationDataState,
} from '../modules/pronunciation';
import { useCallback } from 'react';
import { SkinDataState } from '../modules/posts/types';

export default function usePronunce() {
  const dispatch = useDispatch();
  const pronunciation = useSelector((state: StoreState) => state.pronunciation);

  const handleGetPronunciations = useCallback(
    () => dispatch(getPronunciationsRequest()),
    [dispatch],
  );
  const handleCreatePronunciation = useCallback(
    (
      words: {
        skin: string;
        source: string;
        target: string;
        source_url: string;
        target_url: string;
      }[],
    ) => dispatch(createPronunciationRequest(words)),
    [dispatch],
  );
  const handleUpdatePronunciation = useCallback(
    (
      pronunciation_id: string,
      skin_id: string,
      source: string,
      target: string,
      source_url: string,
      target_url: string,
    ) =>
      dispatch(
        updatePronunciationRequest(
          pronunciation_id,
          skin_id,
          source,
          target,
          source_url,
          target_url,
        ),
      ),
    [dispatch],
  );
  const handleDeletePronunciation = useCallback(
    (pronunciation_id: string) =>
      dispatch(deletePronunciationRequest(pronunciation_id)),
    [dispatch],
  );
  const handleConvertAudio = useCallback(
    (
      user: string,
      input_pair_id: string,
      input_type: 'source' | 'target',
      skin_id: string,
      text: string,
      is_custom: boolean,
    ) =>
      dispatch(
        convertAudioRequest(
          user,
          input_pair_id,
          input_type,
          skin_id,
          text,
          is_custom,
        ),
      ),
    [dispatch],
  );

  /* input */
  const handleAddInput = useCallback(() => dispatch(addInput()), [dispatch]);
  const handleDeleteInput = useCallback(
    (input_pair_id: string) => dispatch(deleteInput({ input_pair_id })),
    [dispatch],
  );
  const handleChangeEditorField = useCallback(
    (key: string, value: any) => dispatch(changeEditorField({ key, value })),
    [dispatch],
  );
  const handleChangeEditorDataField = useCallback(
    (
      input_pair_id: string,
      input_type: 'source' | 'target',
      key: string,
      value: any,
    ) =>
      dispatch(
        changeEditorDataField({
          input_pair_id,
          input_type,
          key,
          value,
        }),
      ),
    [dispatch],
  );
  const handleChangePronunciationListField = useCallback(
    (id: string, input_type: 'source' | 'target', key: string, value: any) =>
      dispatch(changePronunciationListField({ id, input_type, key, value })),
    [dispatch],
  );
  const handleLoadEditorData = useCallback(
    (pronunciation: PronunciationDataState) =>
      dispatch(loadEditorData(pronunciation)),
    [dispatch],
  );

  /* utils */
  const handleSetSkin = useCallback(
    (skin: SkinDataState) => dispatch(setSkin(skin)),
    [dispatch],
  );
  const handleChangePronunciationField = useCallback(
    (key: string, value: any) =>
      dispatch(changePronunciationField({ key, value })),
    [dispatch],
  );
  const handleInitEditor = useCallback(() => dispatch(initEditor()), [
    dispatch,
  ]);

  return {
    pronunciation,
    handleGetPronunciations,
    handleCreatePronunciation,
    handleUpdatePronunciation,
    handleDeletePronunciation,
    handleConvertAudio,

    handleAddInput,
    handleDeleteInput,
    handleChangeEditorField,
    handleChangeEditorDataField,
    handleChangePronunciationListField,
    handleLoadEditorData,

    handleSetSkin,
    handleChangePronunciationField,
    handleInitEditor,
  };
}
