import { combineReducers } from 'redux';
import user from './user/reducers';
import { UserState } from './user/types';
import posts from './posts/reducer';
import { PostState } from './posts/types';
import { BillingState, billingReducer as billing } from './bill';
import {
  PronunciationState,
  pronunciationReducer as pronunciation,
} from './pronunciation';

export interface StoreState {
  user: UserState;
  posts: PostState;
  billing: BillingState;
  pronunciation: PronunciationState;
}

export default combineReducers<StoreState>({
  user,
  posts,
  billing,
  pronunciation,
});
