import axios from 'axios';
import { store } from '../..';
import { apiServerUrl } from '../../config';
import { initializeBillStatus } from '../../modules/bill';
import { initializeWorkspace } from '../../modules/posts/actions';
import {
  initializeUserStatus,
  setSessionExpired,
} from '../../modules/user/actions';

export interface ResponseState {
  success?: boolean;
  data?: any;
  errors?: {
    message?: string;
  };
}

const client = axios.create();
client.defaults.baseURL = apiServerUrl;
client.defaults.withCredentials = true;

client.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        store.dispatch(initializeWorkspace('all'));
        store.dispatch(initializeBillStatus('all'));
        store.dispatch(initializeUserStatus());
        store.dispatch(setSessionExpired(true));
        window.location.replace(process.env.REACT_APP_AUTH_CLIENT_URL!);
      }

      return error.response.data;
    }

    if (error.request) {
      console.log('[Http Request Error]', error.message);
      return Promise.reject(error);
    }

    console.log('[Http Error]', error.message);
    return Promise.reject(error);
  },
);

export const externalClient = axios.create();
externalClient.interceptors.response.use(
  (res) => res.data,
  (error) => error,
);

export default client;
