import produce from 'immer';
import { createReducer } from 'typesafe-actions';
import sanitizeHtml from 'sanitize-html';
import { sentenceTokenizer } from '../../lib/helper/commonHelpers';
import { PostState } from './types';
import { sanitizeConf, generateSentenceData, initialState } from './utils';
import {
  PostAction,
  initializeWorkspace,
  getSkinList,
  getSkinListFailure,
  getSkinListSuccess,
  selectSkin,
  toggleFavoriteSkin,
  updateWorkspaceSuccess,
  uploadFile,
  uploadFileFailure,
  uploadFileSuccess,
  uploadOverlayAudio,
  uploadOverlayAudioFailure,
  uploadOverlayAudioSuccess,
  convertAudio,
  convertAudioFailure,
  convertAudioSuccess,
  accessPrivateSkin,
  accessPrivateSkinFailure,
  accessPrivateSkinSuccess,
  accessCustomSkin,
  accessCustomSkinFailure,
  accessCustomSkinSuccess,
  changeCodeField,
  changePostsField,
  getBgmList,
  getBgmListFailure,
  getBgmListSuccess,
  getWorkspaceListsSuccess,
  getWorkspaceOneSuccess,
  workspaceLibraryFailure,
  workspaceLibraryLoading,
  createWorkspaceSuccess,
  deleteWorkspaceSuccess,
  addDocumentData,
  addSentenceData,
  updateEditorListData,
  removeSentenceData,
} from './actions';

const postsReducer = createReducer<PostState, PostAction>(initialState)
  .handleAction(initializeWorkspace, (state, { payload }) => {
    if (payload === 'all') {
      return initialState;
    } else {
      return {
        ...state,
        [payload]: initialState[payload],
      };
    }
  })
  .handleAction(getSkinList, (state) =>
    produce(state, (draft) => {
      draft.skins.status = 'WAITING';
      draft.skins.error = '';
    }),
  )
  .handleAction(getSkinListSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.skins.status = 'SUCCESS';
      draft.skins.data = payload;
    }),
  )
  .handleAction(getSkinListFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.skins.status = 'FAILURE';
      draft.skins.error = payload;
    }),
  )
  .handleAction(selectSkin, (state, { payload }) =>
    produce(state, (draft) => {
      draft.selectedSkin = payload;
    }),
  )
  .handleAction(toggleFavoriteSkin, (state, { payload }) =>
    produce(state, (draft) => {
      const favoriteSkin = draft.skins.data.find((skin) => skin.id === payload);
      favoriteSkin!.is_favorite = !favoriteSkin!.is_favorite;
    }),
  )
  .handleAction(uploadFile, (state) =>
    produce(state, (draft) => {
      draft.uploadFile.status = 'WAITING';
      draft.uploadFile.error = '';
    }),
  )
  .handleAction(uploadFileSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.uploadFile.status = 'SUCCESS';
      const splitData = sentenceTokenizer(sanitizeHtml(payload, sanitizeConf));
      const sumOfData = [];
      for (let i = 0; i < splitData.length; i++) {
        const list = generateSentenceData();
        list.text = splitData[i];
        sumOfData.push(list);
      }
      draft.editorData.sentenceData = state.editorData.sentenceData.concat(
        sumOfData,
      );
    }),
  )
  .handleAction(uploadFileFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.uploadFile.status = 'FAILURE';
      draft.uploadFile.error = payload;
    }),
  )
  .handleAction(convertAudio, (state, { payload }) =>
    produce(state, (draft) => {
      draft.convertStatus.status = 'WAITING';
      draft.convertStatus.id = payload;
      draft.convertStatus.error = '';
    }),
  )
  .handleAction(convertAudioSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.convertStatus.status = 'SUCCESS';
      draft.convertStatus.id = '';
      draft.editorData.audio.id = payload.audioId;
      draft.editorData.audio.convertAudioUrl = payload.url;
      draft.editorData.audio.overlayAudioUrl = '';
    }),
  )
  .handleAction(convertAudioFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.convertStatus.status = 'FAILURE';
      draft.convertStatus.error = payload;
    }),
  )
  .handleAction(accessPrivateSkin, (state) =>
    produce(state, (draft) => {
      draft.accessPrivateSkin.status = 'WAITING';
      draft.accessPrivateSkin.error = '';
    }),
  )
  .handleAction(accessPrivateSkinSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.accessPrivateSkin.status = 'SUCCESS';
      draft.skins.data.unshift(payload);
    }),
  )
  .handleAction(accessPrivateSkinFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.accessPrivateSkin.status = 'FAILURE';
      draft.accessPrivateSkin.error = payload;
    }),
  )
  .handleAction(accessCustomSkin, (state) =>
    produce(state, (draft) => {
      draft.accessCustomSkin.status = 'WAITING';
      draft.accessCustomSkin.error = '';
    }),
  )
  .handleAction(accessCustomSkinSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.accessCustomSkin.status = 'SUCCESS';
      draft.skins.data.unshift(payload);
    }),
  )
  .handleAction(accessCustomSkinFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.accessCustomSkin.status = 'FAILURE';
      draft.accessCustomSkin.error = payload;
    }),
  )
  .handleAction(changeCodeField, (state, { payload: { form, key, value } }) =>
    produce(state, (draft) => {
      draft[form][key] = value;
    }),
  )
  .handleAction(changePostsField, (state, { payload: { key, value } }) =>
    produce(state, (draft) => {
      draft[key] = value;
    }),
  )
  .handleAction(getBgmList, (state) =>
    produce(state, (draft) => {
      draft.bgm.status = 'WAITING';
      draft.bgm.error = '';
    }),
  )
  .handleAction(getBgmListSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.bgm.status = 'SUCCESS';
      draft.bgm.data = payload;
    }),
  )
  .handleAction(getBgmListFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.bgm.status = 'FAILURE';
      draft.bgm.error = payload;
    }),
  )
  .handleAction(uploadOverlayAudio, (state) =>
    produce(state, (draft) => {
      draft.overlayAudio.status = 'WAITING';
      draft.overlayAudio.error = '';
    }),
  )
  .handleAction(uploadOverlayAudioSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.overlayAudio.status = 'SUCCESS';
      draft.editorData.audio.id = payload.audioId;
      draft.editorData.audio.overlayAudioUrl = payload.url;
    }),
  )
  .handleAction(uploadOverlayAudioFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.overlayAudio.status = 'FAILURE';
      draft.overlayAudio.error = payload;
    }),
  )
  .handleAction(updateEditorListData, (state, { payload }) =>
    produce(state, (draft) => {
      const currentIndex = state.editorData.sentenceData.findIndex(
        (li) => li.id === payload.data.id,
      );

      if (currentIndex !== -1) {
        draft.editorData.sentenceData[currentIndex] = payload.data;
      }
    }),
  )
  .handleAction(workspaceLibraryLoading, (state) => ({
    ...state,
    workspaceLibrary: {
      ...state.workspaceLibrary,
      status: 'WAITING',
      error: '',
    },
  }))
  .handleAction(workspaceLibraryFailure, (state, { payload }) => ({
    ...state,
    workspaceLibrary: {
      ...state.workspaceLibrary,
      status: 'FAILURE',
      error: payload,
    },
  }))
  .handleAction(getWorkspaceListsSuccess, (state, { payload }) => ({
    ...state,
    workspaceLibrary: {
      status: 'SUCCESS',
      error: '',
      data: payload,
    },
  }))
  .handleAction(createWorkspaceSuccess, (state, { payload }) => ({
    ...state,
    editorData: payload,
    workspaceLibrary: {
      status: 'SUCCESS',
      error: '',
      data: [payload, ...state.workspaceLibrary.data],
    },
  }))
  .handleAction(updateWorkspaceSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.editorData = payload.data;
      draft.workspaceLibrary.status = 'SUCCESS';
      draft.workspaceLibrary.data[
        draft.workspaceLibrary.data.findIndex((data) => data.id === payload.id)
      ] = payload.data;
    }),
  )
  .handleAction(deleteWorkspaceSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      if (state.editorData.id === payload) {
        draft.editorData = initialState['editorData'];
      }
      draft.workspaceLibrary.status = 'SUCCESS';
      draft.workspaceLibrary.data.splice(
        draft.workspaceLibrary.data.findIndex((data) => data.id === payload),
        1,
      );
    }),
  )
  .handleAction(getWorkspaceOneSuccess, (state, { payload }) => ({
    ...state,
    editorData: payload,
  }))
  .handleAction(addSentenceData, (state, { payload }) =>
    produce(state, (draft) => {
      let currentIndex = state.editorData.sentenceData.findIndex(
        (li) => li.id === payload.id,
      );
      if (currentIndex !== -1) {
        draft.editorData.sentenceData.splice(currentIndex + 1, 0, payload.data);
        draft.addSentenceStatus.index = currentIndex + 1;
      } else {
        draft.editorData.sentenceData.push(payload.data);
      }

      draft.addSentenceStatus.type = 'row';
    }),
  )
  .handleAction(removeSentenceData, (state, { payload }) =>
    produce(state, (draft) => {
      let currentData = state.editorData.sentenceData.filter(
        (l) => l.id !== payload,
      );
      if (currentData.length === 0) {
        currentData.push(generateSentenceData());
      }
      draft.editorData.sentenceData = currentData;
      draft.addSentenceStatus.type = 'delete';
    }),
  )
  .handleAction(addDocumentData, (state, { payload: { id, data } }) =>
    produce(state, (draft) => {
      let currentIndex = state.editorData.sentenceData.findIndex(
        (li) => li.id === id,
      );
      if (currentIndex !== -1) {
        draft.editorData.sentenceData.splice(currentIndex, 1, ...data);
        draft.addSentenceStatus.index = currentIndex;
      } else {
        draft.editorData.sentenceData = state.editorData.sentenceData.concat(
          data,
        );
        draft.addSentenceStatus.index = 0;
      }
      draft.addSentenceStatus.type = 'document';
    }),
  );

export default postsReducer;
