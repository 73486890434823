import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { logoColor } from '../../lib/styles/palette';
import bannerBackground from '../../images/banner/banner_newBackground.svg';

const Container = styled.div`
  width: 100%;
  min-width: 1140px;
  height: 52px;

  background: linear-gradient(0.25turn, ${logoColor}, #c4bfec, ${logoColor});
  background-repeat: no-repeat;

  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
const MainContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Main = styled.div`
  margin-left: 30px;
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const TitleContainer = styled.div`
  font-family: Avenir;
  font-size: 26px;
  font-weight: 500;
  line-height: 1;
  color: white;
`;
const Title = styled.span`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
`;
const Description = styled.span`
  font-family: Avenir;
  font-size: 18px;
  font-weight: 300;
  color: white;
  text-align: left;
`;
const CodeContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 500;
  color: white;
  margin-right: 5%;
`;
const Code = styled.span`
  margin-left: 15px;
  border: 1px solid white;
  border-radius: 4px;
  padding: 6px 16px;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 700;
  color: white;
`;

export default function BannerWithLogo({
  sale_amount,
  title,
  description,
  code,
}) {
  const history = useHistory();

  return (
    <Container
      onClick={() =>
        history.location.pathname !== '/plan'
          ? history.replace('/plan')
          : undefined
      }
    >
      <MainContainer>
        <Main>
          <TitleContainer>
            <Title>
              <span
                style={{
                  fontSize: '40px',
                  fontWeight: 700,
                  marginRight: '15px',
                }}
              >
                {sale_amount}% OFF
              </span>
              {title}
            </Title>
          </TitleContainer>
          <Description>{description}</Description>
        </Main>
        <img src={bannerBackground} alt="backgroundImg" />
      </MainContainer>
      <CodeContainer>
        <span>Use code at checkout</span>
        <Code>{code}</Code>
      </CodeContainer>
    </Container>
  );
}
