import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { logoColor } from '../../lib/styles/palette';
import bannerBackgrouhnd from '../../images/banner/banner_background.png';

const Container = styled.div`
  width: 100%;
  min-width: 1140px;
  height: 105px;

  background: ${logoColor};
  background-image: url(${bannerBackgrouhnd});
  background-size: 1033px 113px;
  background-repeat: no-repeat;

  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 3.2%;
`;
const TitleContainer = styled.div`
  font-family: Avenir;
  font-size: 28px;
  font-weight: 500;
  line-height: 1;
  color: white;
`;
const Title = styled.span`
  font-size: 40px;
  font-weight: 900;
`;
const Description = styled.span`
  font-family: Avenir;
  font-size: 18px;
  font-weight: 300;
  color: white;
`;
const CodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 300;
  color: white;
  margin-right: 10%;
`;
const Code = styled.span`
  font-family: Avenir;
  font-size: 40px;
  font-weight: 500;
  color: white;
`;

export default function Banner10KLOVO50() {
  const history = useHistory();

  return (
    <Container
      onClick={() =>
        history.location.pathname !== '/plan'
          ? history.replace('/plan')
          : undefined
      }
    >
      <MainContainer>
        <TitleContainer>
          <Title>30% OFF</Title> on any subscription up to 1 year!!
        </TitleContainer>
        <Description>The offer ends 11:59pm, June 30th, PST</Description>
      </MainContainer>
      <CodeContainer>
        <span>Use code</span>
        <Code>30LOVO30</Code>
      </CodeContainer>
    </Container>
  );
}
