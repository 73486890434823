import React from 'react';
import styled from 'styled-components';

const InfoBlock = styled.div`
  width: 420px;
  margin: 0 auto;

  h2 {
    height: 30px;
    font-size: 35px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: center;
    color: #7361d4;
  }
  p {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: center;
    color: #242424;
  }
`;

const RequestVoiceTop = () => {
  return (
    <>
      <InfoBlock>
        <h2>Request a New Voice</h2>
        <p>
          Please bear in mind we may not be able to provide you the requested
          voice for various reasons (i.e.;legal, political, etc.)
        </p>
      </InfoBlock>
    </>
  );
};

export default RequestVoiceTop;
