import ReactGA from 'react-ga';
import produce from 'immer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { createAction, ActionType, createReducer } from 'typesafe-actions';
import {
  getPlanListsApi,
  getSubscriptionStatusApi,
  createSubscriptionApi,
  cancelSubsriptionApi,
  updateSubscriptionApi,
  reactiveSubscriptionApi,
  updateDefaultCardApi,
  getSubscriptionHistoryApi,
  getProrationApi,
} from '../lib/api/bill';
import { parseUserData } from './user';
import { updateStudioUser } from './user/actions';
import { disable2checkoutSubscription } from '../lib/api/twocheckout';

const INITIALIZE_STATUS = 'bill/INITIALIZE_STATUS';
const CHANGE_BILL_FIELD = 'bill/CHANGE_BILL_FIELD';

const GET_PLAN_LISTS = 'bill/GET_PLAN_LISTS';
const GET_PLAN_LISTS_SUCCESS = 'bill/GET_PLAN_LISTS_SUCCESS';
const GET_PLAN_LISTS_FAILURE = 'bill/GET_PLAN_LISTS_FAILURE';

const GET_SUBSCRIPTION_STATUS = 'bill/GET_SUBSCRIPTION_STATUS';
const GET_SUBSCRIPTION_STATUS_SUCCESS = 'bill/GET_SUBSCRIPTION_STATUS_SUCCESS';
const GET_SUBSCRIPTION_STATUS_FAILURE = 'bill/GET_SUBSCRIPTION_STATUS_FAILURE';

const CREATE_SUBSCRIPTION = 'bill/CREATE_SUBSCRIPTION';
const CREATE_SUBSCRIPTION_SUCCESS = 'bill/CREATE_SUBSCRIPTION_SUCCESS';
const CREATE_SUBSCRIPTION_FAILURE = 'bill/CREATE_SUBSCRIPTION_FAILURE';

const CANCEL_SUBSCRIPTION = 'bill/CANCEL_SUBSCRIPTION';
const CANCEL_SUBSCRIPTION_SUCCESS = 'bill/CANCEL_SUBSCRIPTION_SUCCESS';
const CANCEL_SUBSCRIPTION_FAILURE = 'bill/CANCEL_SUBSCRIPTION_FAILURE';

const CANCEL_TWOCHECKOUT_SUBSCRIPTION =
  'bill/const CANCEL_TWOCHECKOUT_SUBSCRIPTION';
const CANCEL_TWOCHECKOUT_SUBSCRIPTION_SUCCESS =
  'bill/const CANCEL_TWOCHECKOUT_SUBSCRIPTION_SUCCESS';
const CANCEL_TWOCHECKOUT_SUBSCRIPTION_FAILURE =
  'bill/const CANCEL_TWOCHECKOUT_SUBSCRIPTION_FAILURE';

const REACTIVE_SUBSCRIPTION = 'bill/REACTIVE_SUBSCRIPTION';
const REACTIVE_SUBSCRIPTION_SUCCESS = 'bill/REACTIVE_SUBSCRIPTION_SUCCESS';
const REACTIVE_SUBSCRIPTION_FAILURE = 'bill/REACTIVE_SUBSCRIPTION_FAILURE';

const UPDATE_SUBSCRIPTION = 'bill/UPDATE_SUBSCRIPTION';
const UPDATE_SUBSCRIPTION_SUCCESS = 'bill/UPDATE_SUBSCRIPTION_SUCCESS';
const UPDATE_SUBSCRIPTION_FAILURE = 'bill/UPDATE_SUBSCRIPTION_FAILURE';

const UPDATE_DEFAULT_CARD = 'bill/UPDATE_DEFAULT_CARD';
const UPDATE_DEFAULT_CARD_SUCCESS = 'bill/UPDATE_DEFAULT_CARD_SUCCESS';
const UPDATE_DEFAULT_CARD_FAILURE = 'bill/UPDATE_DEFAULT_CARD_FAILURE';

const GET_SUBSCRIPTION_HISTORY = 'bill/GET_SUBSCRIPTION_HISTORY';
const GET_SUBSCRIPTION_HISTORY_SUCCESS =
  'bill/GET_SUBSCRIPTION_HISTORY_SUCCESS';
const GET_SUBSCRIPTION_HISTORY_FAILURE =
  'bill/GET_SUBSCRIPTION_HISTORY_FAILURE';

const GET_PRORATION = 'bill/GET_PRORATION';
const GET_PRORATION_SUCCESS = 'bill/GET_PRORATION_SUCCESS';
const GET_PRORATION_FAILURE = 'bill/GET_PRORATION_FAILURE';

const CREATE_COUPON = 'bill/CREATE_COUPON';
const GET_COUPON = 'bill/GET_COUPON';

interface ChangeBillFieldState {
  form: string;
  value: any;
}

export interface PlanDataState {
  id: string;
  amount: number;
  interval: string;
  nickname: string;
  planType: string;
}

interface BillInfoState {
  cardInfo?: {
    id: string;
    name: string;
    brand: string;
    last4: string;
    address_line1: string;
    address_line2: string;
    address_city: string;
    address_zip: string;
    address_country: string;
    address_state: string;
  };
  subscription?: {
    id: string;
    periodStart: number;
    periodEnd: number;
    status: string;
    cancelAt?: number;
    cancelAtPeriodEnd: boolean;
    plan: {
      id: string;
      amount: number;
      interval: string;
      name: string;
    };
  };
}

export interface BillHistoryState {
  id: string;
  planName: string;
  paidAmount: number;
  paidDate: number;
}

export interface NewCardInfoState {
  token?: any;
  email: string;
}

export interface SelectedPlanData {
  id: string;
  unitPrice: number;
  totalPrice: number;
  planType: string;
  interval: string;
}

export interface CouponData {
  id: string;
  name: string;
  description: string;
  popupDescription: string;
  code: string;
  discountRate: number;
  expireAt: number;
  isActive: boolean;
  isUsed: boolean;
  createdAt: string;
  updatedAt: string;
  interval: string;
}

export interface BillingState {
  stripeKey: string;
  planLists: {
    status: string;
    error: string;
    data: PlanDataState[];
  };
  billInfo: {
    status: string;
    error: string;
    data: BillInfoState;
  };
  newCardInfo: NewCardInfoState;
  currentPlanListInterval: string;
  createSub: {
    status: string;
    error: string;
  };
  cancelSub: {
    status: string;
    error: string;
  };
  cancel2checkoutSub: {
    status: string;
    error: string;
  };
  reactiveSub: {
    status: string;
    error: string;
  };
  updateSub: {
    status: string;
    error: string;
  };
  updateDefaultCard: {
    status: string;
    error: string;
  };
  getSubHistory: {
    status: string;
    error: string;
    data: BillHistoryState[];
  };
  selectedPlanData?: {
    plan: SelectedPlanData;
    forUpdate: boolean;
  };
  getProration: {
    status: string;
    error: string;
    data: number;
  };
  promotion: {
    couponLists: CouponData[];
  };
  [key: string]: any;
}

const parsePlanData = (data: any): PlanDataState => ({
  id: data.id,
  amount: data.amount,
  interval: data.interval,
  nickname: data.nickname,
  planType: data.metadata.plan,
});

const parseSubscriptionData = (
  source: any,
  subscription: any,
): BillInfoState => ({
  cardInfo: source && {
    id: source.id,
    name: source.name,
    brand: source.brand,
    last4: source.last4,
    address_line1: source.address_line1,
    address_line2: source.address_line2,
    address_city: source.address_city,
    address_zip: source.address_zip,
    address_country: source.address_country,
    address_state: source.address_state,
  },
  subscription: subscription && {
    id: subscription.id,
    periodStart: subscription.current_period_start,
    periodEnd: subscription.current_period_end,
    status: subscription.status,
    cancelAt: subscription.cancel_at,
    cancelAtPeriodEnd: subscription.cancel_at_period_end,
    plan: {
      id: subscription && subscription.plan ? subscription.plan.id : null,
      amount:
        subscription && subscription.plan ? subscription.plan.amount : null,
      interval:
        subscription && subscription.plan ? subscription.plan.interval : null,
      name:
        subscription && subscription.plan.metadata
          ? subscription.plan.metadata.plan
          : null,
    },
  },
});

const parseBillHistory = (data: any): BillHistoryState => ({
  id: data.id,
  planName: data.plan.metadata.plan,
  paidAmount: data.amount_paid,
  paidDate: data.created,
});

export const parseCouponData = (data: any): CouponData => ({
  id: data._id,
  name: data.name,
  description: data.description,
  popupDescription: data.popup_description,
  code: data.code,
  discountRate: data.discount_rate ? data.discount_rate / 100 : 0,
  expireAt: data.expires,
  isActive: data.is_active,
  isUsed: data.is_used,
  createdAt: data.createdAt,
  updatedAt: data.updatedAt,
  interval: data.interval || '',
});

export const initializeBillStatus = createAction(INITIALIZE_STATUS)<string>();

export const changeBillField = createAction(CHANGE_BILL_FIELD)<
  ChangeBillFieldState
>();

const getPlanLists = createAction(GET_PLAN_LISTS)();

const getPlanListsSuccess = createAction(GET_PLAN_LISTS_SUCCESS)<{
  stripeKey: string;
  planLists: PlanDataState[];
}>();

const getPlanListsFailure = createAction(GET_PLAN_LISTS_FAILURE)<string>();

export const getPlanListsRequest = () => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => {
  dispatch(getPlanLists());
  try {
    const response: any = await getPlanListsApi();
    if (response.success && response.data.plans.length !== 0) {
      dispatch(
        getPlanListsSuccess({
          stripeKey: response.data.public_key,
          planLists: response.data.plans.map((list: any) =>
            parsePlanData(list),
          ),
        }),
      );
    } else {
      dispatch(getPlanListsFailure(response['errors']['message']));
    }
  } catch (e) {
    dispatch(getPlanListsFailure(''));
  }
};

const getSubscriptionStatus = createAction(GET_SUBSCRIPTION_STATUS)();

const getSubscriptionStatusSuccess = createAction(
  GET_SUBSCRIPTION_STATUS_SUCCESS,
)<BillInfoState>();

const getSubscriptionStatusFailure = createAction(
  GET_SUBSCRIPTION_STATUS_FAILURE,
)<string>();

export const getSubscriptionStatusRequest = () => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => {
  dispatch(getSubscriptionStatus());
  try {
    const response: any = await getSubscriptionStatusApi();
    if (response.success) {
      dispatch(
        getSubscriptionStatusSuccess(
          parseSubscriptionData(
            response.data.source,
            response.data.subscription,
          ),
        ),
      );
    } else {
      dispatch(getSubscriptionStatusFailure(response['errors']['message']));
    }
  } catch (error) {
    dispatch(getSubscriptionStatusFailure(error.message));
  }
};

const createSubscription = createAction(CREATE_SUBSCRIPTION)();

const createSubscriptionSuccess = createAction(CREATE_SUBSCRIPTION_SUCCESS)<
  BillInfoState
>();

const createSubscriptionFailure = createAction(CREATE_SUBSCRIPTION_FAILURE)<
  string
>();

export const createSubscriptionRequest = (
  planId: string,
  email: string,
  coupon: string,
  tokenId?: string,
) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(createSubscription());
  try {
    const response: any = await createSubscriptionApi(
      planId,
      email,
      coupon,
      tokenId,
    );
    if (response.success) {
      const val = response.data.subscription.plan.amount;
      const plan = response.data.role.name;
      const interval = response.data.role.interval;
      // ReactGA.initialize("UA-175912114-1"); // lovo.ai Full URL View
      const label = plan + ' - ' + interval + ' $' + val / 100;
      ReactGA.event({
        category: 'Studio.Membership',
        action: 'Subscribe',
        label: label,
      });

      dispatch(
        createSubscriptionSuccess(
          parseSubscriptionData(
            response.data.source,
            response.data.subscription,
          ),
        ),
      );
      dispatch(updateStudioUser(parseUserData(response.data)));
    } else {
      dispatch(createSubscriptionFailure(response['errors']['message']));
    }
  } catch (e) {
    dispatch(createSubscriptionFailure(''));
  }
};

const cancelSubscription = createAction(CANCEL_SUBSCRIPTION)();

const cancelSubscriptionSuccess = createAction(CANCEL_SUBSCRIPTION_SUCCESS)<
  BillInfoState
>();

const cancelSubscriptionFailure = createAction(CANCEL_SUBSCRIPTION_FAILURE)<
  string
>();

export const cancelSubscriptionRequest = (
  subscriptionId: string,
  cancelReason: string,
  competitiveSolution: string,
) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(cancelSubscription());
  try {
    const response: any = await cancelSubsriptionApi(
      subscriptionId,
      cancelReason,
      competitiveSolution,
    );
    if (response.success) {
      dispatch(
        cancelSubscriptionSuccess(
          parseSubscriptionData(
            response.data.source,
            response.data.subscription,
          ),
        ),
      );
      dispatch(updateStudioUser(parseUserData(response.data)));
    } else {
      dispatch(cancelSubscriptionFailure(response['errors']['message']));
    }
  } catch (e) {
    dispatch(cancelSubscriptionFailure(''));
  }
};

const cancel2checkoutSubscription = createAction(
  CANCEL_TWOCHECKOUT_SUBSCRIPTION,
)();
const cancel2checkoutSubscriptionSuccess = createAction(
  CANCEL_TWOCHECKOUT_SUBSCRIPTION_SUCCESS,
)();
const cancel2checkoutSubscriptionFailure = createAction(
  CANCEL_TWOCHECKOUT_SUBSCRIPTION_FAILURE,
  (error) => error,
)();
export const cancel2checkoutSubscriptionRequest = () => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => {
  dispatch(cancel2checkoutSubscription());

  try {
    const response = await disable2checkoutSubscription();

    if (response.success) {
      dispatch(cancel2checkoutSubscriptionSuccess());
      dispatch(updateStudioUser(parseUserData(response.data)));
    }
  } catch (error) {
    dispatch(cancel2checkoutSubscriptionFailure(error));
  }
};

const updateSubscription = createAction(UPDATE_SUBSCRIPTION)();

const updateSubscriptionSuccess = createAction(UPDATE_SUBSCRIPTION_SUCCESS)<
  BillInfoState
>();

const updateSubscriptionFailure = createAction(UPDATE_SUBSCRIPTION_FAILURE)<
  string
>();

export const updateSubscriptionRequest = (planId: string) => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => {
  dispatch(updateSubscription());
  try {
    const response: any = await updateSubscriptionApi(planId);
    if (response.success) {
      dispatch(
        updateSubscriptionSuccess(
          parseSubscriptionData(
            response.data.source,
            response.data.subscription,
          ),
        ),
      );
      dispatch(updateStudioUser(parseUserData(response.data)));
    } else {
      dispatch(updateSubscriptionFailure(response['errors']['message']));
    }
  } catch (e) {
    dispatch(updateSubscriptionFailure(''));
  }
};

const reactiveSubscription = createAction(REACTIVE_SUBSCRIPTION)();

const reactiveSubscriptionSuccess = createAction(REACTIVE_SUBSCRIPTION_SUCCESS)<
  BillInfoState
>();

const reactiveSubscriptionFailure = createAction(REACTIVE_SUBSCRIPTION_FAILURE)<
  string
>();

export const reactiveSubscriptionRequest = (subscriptionId: string) => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => {
  dispatch(reactiveSubscription());
  try {
    const response: any = await reactiveSubscriptionApi(subscriptionId);
    if (response.success) {
      dispatch(
        reactiveSubscriptionSuccess(
          parseSubscriptionData(
            response.data.source,
            response.data.subscription,
          ),
        ),
      );
      dispatch(updateStudioUser(parseUserData(response.data)));
    } else {
      dispatch(reactiveSubscriptionFailure(response['errors']['message']));
    }
  } catch (e) {
    dispatch(reactiveSubscriptionFailure(''));
  }
};

const updateDefaultCard = createAction(UPDATE_DEFAULT_CARD)();

const updateDefaultCardSuccess = createAction(UPDATE_DEFAULT_CARD_SUCCESS)<
  BillInfoState
>();

const updateDefaultCardFailure = createAction(UPDATE_DEFAULT_CARD_FAILURE)<
  string
>();

export const updateDefaultCardRequest = (
  cardId: string,
  tokenId: string,
  email: string,
) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(updateDefaultCard());
  try {
    const response: any = await updateDefaultCardApi(cardId, tokenId, email);
    if (response.success) {
      dispatch(
        updateDefaultCardSuccess(
          parseSubscriptionData(
            response.data.source,
            response.data.subscription,
          ),
        ),
      );
    } else {
      dispatch(updateDefaultCardFailure(response['errors']));
    }
  } catch (e) {
    dispatch(updateDefaultCardFailure(''));
  }
};

const getSubscriptionHistory = createAction(GET_SUBSCRIPTION_HISTORY)();

const getSubscriptionHistorySuccess = createAction(
  GET_SUBSCRIPTION_HISTORY_SUCCESS,
)<BillHistoryState[]>();

const getSubscriptionHistoryFailure = createAction(
  GET_SUBSCRIPTION_HISTORY_FAILURE,
)<string>();

export const getSubscriptionHistoryRequest = () => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => {
  dispatch(getSubscriptionHistory());
  try {
    const response: any = await getSubscriptionHistoryApi();
    if (response.success) {
      const lists = response.data.map((data: any) => parseBillHistory(data));
      dispatch(getSubscriptionHistorySuccess(lists));
    } else {
      dispatch(getSubscriptionHistoryFailure(response['errors']['message']));
    }
  } catch (e) {
    dispatch(getSubscriptionHistoryFailure(''));
  }
};

const getProration = createAction(GET_PRORATION)();

const getProrationSuccess = createAction(GET_PRORATION_SUCCESS)<number>();

const getProrationFailure = createAction(GET_PRORATION_FAILURE)<string>();

export const getProrationRequest = (plan: string) => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => {
  dispatch(getProration());
  try {
    const response: any = await getProrationApi(plan);
    if (response.success) {
      dispatch(getProrationSuccess(response.data.proration / 100));
    } else {
      dispatch(getProrationFailure(response['errors']['message']));
    }
  } catch (e) {
    dispatch(getProrationFailure(''));
  }
};

export const createCouponAction = createAction(CREATE_COUPON)<CouponData>();

export const getCouponAction = createAction(GET_COUPON)<CouponData[]>();

const actions = {
  getPlanLists,
  getPlanListsSuccess,
  getPlanListsFailure,
  getSubscriptionStatus,
  getSubscriptionStatusSuccess,
  getSubscriptionStatusFailure,
  createSubscription,
  createSubscriptionSuccess,
  createSubscriptionFailure,
  initializeBillStatus,
  cancelSubscription,
  cancelSubscriptionSuccess,
  cancelSubscriptionFailure,
  cancel2checkoutSubscription,
  cancel2checkoutSubscriptionSuccess,
  cancel2checkoutSubscriptionFailure,
  updateSubscription,
  updateSubscriptionSuccess,
  updateSubscriptionFailure,
  reactiveSubscription,
  reactiveSubscriptionSuccess,
  reactiveSubscriptionFailure,
  updateDefaultCard,
  updateDefaultCardSuccess,
  updateDefaultCardFailure,
  getSubscriptionHistory,
  getSubscriptionHistorySuccess,
  getSubscriptionHistoryFailure,
  changeBillField,
  getProration,
  getProrationSuccess,
  getProrationFailure,
  createCouponAction,
  getCouponAction,
};

type BillingActionType = ActionType<typeof actions>;

const initialState: BillingState = {
  stripeKey: '',
  planLists: {
    status: 'INIT',
    error: '',
    data: [],
  },
  billInfo: {
    status: 'INIT',
    error: '',
    data: { cardInfo: undefined, subscription: undefined },
  },
  newCardInfo: {
    email: '',
  },
  currentPlanListInterval: 'yearly',
  createSub: {
    status: 'INIT',
    error: '',
  },
  cancelSub: {
    status: 'INIT',
    error: '',
  },
  cancel2checkoutSub: {
    status: 'INIT',
    error: '',
  },
  reactiveSub: {
    status: 'INIT',
    error: '',
  },
  updateSub: {
    status: 'INIT',
    error: '',
  },
  updateDefaultCard: {
    status: 'INIT',
    error: '',
  },
  getSubHistory: {
    status: 'INIT',
    error: '',
    data: [],
  },
  getProration: {
    status: 'INIT',
    error: '',
    data: 0,
  },
  selectedPlanData: undefined,
  promotion: {
    couponLists: [],
  },
};

export const billingReducer = createReducer<BillingState, BillingActionType>(
  initialState,
)
  .handleAction(getPlanLists, (state) =>
    produce(state, (draft) => {
      draft.planLists.status = 'WAITING';
      draft.planLists.error = '';
      draft.stripeKey = '';
    }),
  )
  .handleAction(
    getPlanListsSuccess,
    (state, { payload: { stripeKey, planLists } }) =>
      produce(state, (draft) => {
        draft.planLists.status = 'SUCCESS';
        draft.planLists.data = planLists;
        draft.stripeKey = stripeKey;
      }),
  )
  .handleAction(getPlanListsFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.planLists.status = 'FAILURE';
      draft.planLists.error = payload;
      draft.stripeKey = '';
    }),
  )
  .handleAction(getSubscriptionStatus, (state) =>
    produce(state, (draft) => {
      draft.billInfo.status = 'WAITING';
      draft.billInfo.error = '';
    }),
  )
  .handleAction(getSubscriptionStatusSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.billInfo.status = 'SUCCESS';
      draft.billInfo.data = payload;
    }),
  )
  .handleAction(getSubscriptionStatusFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.billInfo.status = 'FAILURE';
      draft.billInfo.error = payload;
    }),
  )
  .handleAction(createSubscription, (state) =>
    produce(state, (draft) => {
      draft.createSub.status = 'WAITING';
      draft.createSub.error = '';
    }),
  )
  .handleAction(createSubscriptionSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.createSub.status = 'SUCCESS';
      draft.billInfo.data = payload;
    }),
  )
  .handleAction(createSubscriptionFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.createSub.status = 'FAILURE';
      draft.createSub.error = payload;
    }),
  )
  .handleAction(initializeBillStatus, (state, { payload }) => {
    if (payload === 'all') {
      return initialState;
    }
    return {
      ...state,
      [payload]: initialState[payload],
    };
  })
  .handleAction(cancelSubscription, (state) =>
    produce(state, (draft) => {
      draft.cancelSub.status = 'WAITING';
      draft.cancelSub.error = '';
    }),
  )
  .handleAction(cancelSubscriptionSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.cancelSub.status = 'SUCCESS';
      draft.billInfo.data = payload;
    }),
  )
  .handleAction(cancelSubscriptionFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.cancelSub.status = 'FAILURE';
      draft.cancelSub.error = payload;
    }),
  )
  .handleAction(cancel2checkoutSubscription, (state) =>
    produce(state, (draft) => {
      draft.cancel2checkoutSub.status = 'WAITING';
      draft.cancel2checkoutSub.error = '';
    }),
  )
  .handleAction(cancel2checkoutSubscriptionSuccess, (state) =>
    produce(state, (draft) => {
      draft.cancel2checkoutSub.status = 'SUCCESS';
    }),
  )
  .handleAction(cancel2checkoutSubscriptionFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.cancel2checkoutSub.status = 'FAILURE';
      draft.cancel2checkoutSub.error = payload;
    }),
  )
  .handleAction(reactiveSubscription, (state) =>
    produce(state, (draft) => {
      draft.reactiveSub.status = 'WAITING';
      draft.reactiveSub.error = '';
    }),
  )
  .handleAction(reactiveSubscriptionSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.reactiveSub.status = 'SUCCESS';
      draft.billInfo.data = payload;
    }),
  )
  .handleAction(reactiveSubscriptionFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.reactiveSub.status = 'FAILURE';
      draft.reactiveSub.error = payload;
    }),
  )
  .handleAction(updateSubscription, (state) =>
    produce(state, (draft) => {
      draft.updateSub.status = 'WAITING';
      draft.updateSub.error = '';
    }),
  )
  .handleAction(updateSubscriptionSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.updateSub.status = 'SUCCESS';
      draft.billInfo.data = payload;
    }),
  )
  .handleAction(updateSubscriptionFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.updateSub.status = 'FAILURE';
      draft.updateSub.error = payload;
    }),
  )
  .handleAction(updateDefaultCard, (state) =>
    produce(state, (draft) => {
      draft.updateDefaultCard.status = 'WAITING';
      draft.updateDefaultCard.error = '';
    }),
  )
  .handleAction(updateDefaultCardSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.updateDefaultCard.status = 'SUCCESS';
      draft.billInfo.data = payload;
    }),
  )
  .handleAction(updateDefaultCardFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.updateDefaultCard.status = 'FAILURE';
      draft.updateDefaultCard.error = payload;
    }),
  )
  .handleAction(getSubscriptionHistory, (state) =>
    produce(state, (draft) => {
      draft.getSubHistory.status = 'WAITING';
      draft.getSubHistory.error = '';
    }),
  )
  .handleAction(getSubscriptionHistorySuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.getSubHistory.status = 'SUCCESS';
      draft.getSubHistory.data = payload;
    }),
  )
  .handleAction(getSubscriptionHistoryFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.getSubHistory.status = 'FAILURE';
      draft.getSubHistory.error = payload;
    }),
  )
  .handleAction(changeBillField, (state, { payload: { form, value } }) =>
    produce(state, (draft) => {
      draft[form] = value;
    }),
  )
  .handleAction(getProration, (state) =>
    produce(state, (draft) => {
      draft.getProration.status = 'WAITING';
      draft.getProration.error = '';
    }),
  )
  .handleAction(getProrationSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.getProration.status = 'SUCCESS';
      draft.getProration.data = payload;
    }),
  )
  .handleAction(getProrationFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.getProration.status = 'FAILURE';
      draft.getProration.error = payload;
    }),
  )
  .handleAction(getCouponAction, (state, { payload }) => ({
    ...state,
    promotion: {
      ...state.promotion,
      couponLists: payload,
    },
  }))
  .handleAction(createCouponAction, (state, { payload }) => ({
    ...state,
    promotion: {
      ...state.promotion,
      couponLists: [payload, ...state.promotion.couponLists],
    },
  }));
