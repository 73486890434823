import React from 'react';
import styled from 'styled-components';
import Button from './Button';
import { logoColor } from '../../lib/styles/palette';
import CancelButton from './CancelButton';

const Fullscreen = styled.div`
  position: fixed;
  z-index: 51;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AskModalBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 600px;
  background: white;
  padding: 69px 0;
  border-radius: 0px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
  h2 {
    margin-top: 0;
    margin-bottom: 41px;
    color: ${logoColor};
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0.5px;
  }
  .buttons {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

const ButtonWithMarginTop = styled(Button)`
  width: 40%;
  border-radius: 0;
  background-color: ${logoColor};
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.36px;
`;

const Description = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  text-align: center;
  color: #242424;
  padding-left: 1rem;
  padding-right: 1rem;
`;

interface Props {
  visible: boolean;
  onCancel: VoidFunction;
}

const BrowserSupportModal: React.FC<Props> = ({ visible, onCancel }) => {
  if (!visible) return null;
  return (
    <Fullscreen>
      <AskModalBlock>
        <div className="buttons">
          <CancelButton onCancel={onCancel} />
        </div>
        <h2>Heads Up!</h2>
        <Description>
          Microsoft Edge isn't the most compatible with LOVO Studio :(
          <br />
          Please use Google Chrome or Firefox for the best experience!
        </Description>
        <ButtonWithMarginTop style={{ marginTop: '36px' }} onClick={onCancel}>
          OK!
        </ButtonWithMarginTop>
      </AskModalBlock>
    </Fullscreen>
  );
};

export default BrowserSupportModal;
