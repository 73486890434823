import React from 'react';
import styled from 'styled-components';

import { logoColor } from '../../lib/styles/palette';
import Button from '../common/Button';

const TextInput = styled.div`
  textarea {
    width: 568px;
    height: 294px;
    background-color: #242424;
    padding: 12px 20px;
    box-sizing: border-box;
    margin-top: 0.5rem;
    font-family: MyFont;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: 0.36px;
    color: rgba(255, 255, 255, 1);
    border: 0;
  }

  textarea:focus {
    outline: none !important;
    border: 0;
    box-shadow: 0 0 10px #719ece;
  }
`;

const ButtonWithMarginTop = styled(Button)`
  margin-bottom: 2rem;
  max-width: 40%;
  background-color: ${logoColor};
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  border-radius: 0;
`;

const RequestVoiceSubmit = ({ inputValue, onFormUpdate, onFormSubmit }) => {
  return (
    <>
      <TextInput>
        <form>
          <textarea
            value={inputValue}
            onChange={onFormUpdate}
            placeholder="Please tell us characteristics, and if possible, a known celebrity who might sound similar to your idea."
          ></textarea>
        </form>
      </TextInput>
      <ButtonWithMarginTop
        cyan
        fullWidth
        style={{ marginTop: '3vh' }}
        onClick={onFormSubmit}
      >
        SUBMIT
      </ButtonWithMarginTop>
    </>
  );
};

export default RequestVoiceSubmit;
