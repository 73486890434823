import React from 'react';
import styled, { css } from 'styled-components';
import { logoColor } from '../../lib/styles/palette';

const Container = styled.div<{ size: string }>`
  position: relative;
  ${(props) =>
    props.size &&
    css`
      width: ${props.size};
      height: ${props.size};
    `}
`;

const Label = styled.label<{ size: string; disabled?: boolean }>`
  cursor: pointer;
  input {
    position: absolute;
    opacity: 0;
  }

  .overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    ${(props) =>
      props.size &&
      css`
        width: ${props.size};
        height: ${props.size};
      `}
    background-color: ${(props) =>
      props.disabled ? '#c1c1c1' : 'transparent'};
    border-radius: 3px;
    border: 1px solid #c1c1c1;
    transform: rotate(-90deg);
    transition: all 0.3s;
  }

  .icon {
    color: white;
    display: none;
    margin-bottom: 10px;
  }

  input:checked ~ .overlay {
    background-color: ${(props) => (props.disabled ? '#c1c1c1' : logoColor)};
    border-radius: 3px;
    transform: rotate(0deg);
    opacity: 1;
    border: 1px solid ${(props) => (props.disabled ? '#c1c1c1' : logoColor)};
  }

  input:checked ~ .overlay .icon {
    display: block;
  }
`;

interface Props {
  isChecked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  size: string;
  viewSize: number;
  disable?: boolean;
}

const CustomCheckBox: React.FC<Props> = ({
  isChecked,
  onChange,
  size,
  viewSize,
  disable,
}) => (
  <Container size={size}>
    <Label size={size} disabled={disable}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
        disabled={disable}
      />
      <span className="overlay">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={viewSize}
          height={viewSize}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="icon"
        >
          <polyline points="20 6 9 17 4 12" />
        </svg>
      </span>
    </Label>
  </Container>
);

export default CustomCheckBox;
