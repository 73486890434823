import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import '@lovo/lovo-custom-voice/dist/index.css';
import './index.css';
import thunk from 'redux-thunk';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, AnyAction } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer, { StoreState } from './modules';
import ScrollToTop from './components/common/ScrollToTop';
import { buildEnv } from './config';
import { PromoContext, promo } from './modules/contexts';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { HiCreatorMoblie } from './components/common';

/**
 * activate sentry
 */
Sentry.init({
  dsn:
    'https://945b2435e0fa4ef0872e71d0e73fb2de@o1031753.ingest.sentry.io/5998552',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1,
  enabled: buildEnv === 'production',
});

let useDevTool;

if (buildEnv === 'production') {
  useDevTool = applyMiddleware(thunk);
  ReactGA.initialize(
    [
      { trackingId: String(process.env.REACT_APP_GA_ID) }, // lovo.ai Full URL View
    ],
    {
      gaOptions: { cookieDomain: 'auto' },
    },
  );
} else {
  useDevTool = composeWithDevTools(applyMiddleware(thunk));
  ReactGA.initialize(String(process.env.REACT_APP_GA_ID)); // lovo.ai Full URL View
}

export const store = createStore<StoreState, AnyAction, any, any>(
  rootReducer,
  useDevTool,
);

ReactDOM.render(
  <Sentry.ErrorBoundary showDialog>
    <Provider store={store}>
      <PromoContext.Provider value={promo}>
        <BrowserRouter>
          <ScrollToTop>
            {isMobile ? <HiCreatorMoblie visible /> : <App />}
          </ScrollToTop>
        </BrowserRouter>
      </PromoContext.Provider>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
