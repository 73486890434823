import React from 'react';
import styled from 'styled-components';
import { PAGE_TITLE_TOP_POSITION } from '../../lib/styles/palette';

const TitleContainer = styled.div`
  padding: 0 200px;
  padding-top: calc(${PAGE_TITLE_TOP_POSITION} - 10px);
  font-size: 35px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  color: #000;
  text-align: left;
  white-space: pre-wrap;
`;

interface Props {
  title: string;
  className?: string;
}

const PageTitle: React.FC<Props> = ({ className, title }) => {
  return <TitleContainer className={className}>{title}</TitleContainer>;
};

export default PageTitle;
