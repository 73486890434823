import { ThunkDispatch } from 'redux-thunk';
import { createAction, ActionType, createReducer } from 'typesafe-actions';
import { OfflineAudioContext } from 'standardized-audio-context';
import { SkinDataState } from './posts/types';
import {
  getPronunciationsApi,
  createPronunciationApi,
  updatePronunciationApi,
  deletePronunciationApi,
} from '../lib/api/pronunciation';
import {
  convertAudioStreamApiForStudio,
  uploadSynthesisFileApi,
} from '../lib/api/post';
import { AnyAction } from 'redux';
import uuid from 'uuid/v4';
import toWav from 'audiobuffer-to-wav';
import AudioContextClass from '../lib/helper/AudioContext';

/**
 * Action Types
 */
/* core */
const GET_PRONUNCIATIONS = 'pronunciation/GET_PRONUNCIATIONS';
const GET_PRONUNCIATIONS_SUCCESS = 'pronunciation/GET_PRONUNCIATIONS_SUCCESS';
const GET_PRONUNCIATIONS_FAILURE = 'pronunciation/GET_PRONUNCIATIONS_FAILURE';

const CREATE_PRONUNCIATION = 'pronunciation/CREATE_PRONUNCIATION';
const CREATE_PRONUNCIATION_SUCCESS =
  'pronunciation/CREATE_PRONUNCIATION_SUCCESS';
const CREATE_PRONUNCIATION_FAILURE =
  'pronunciation/CREATE_PRONUNCIATION_FAILURE';

const UPDATE_PRONUNCIATION = 'pronunciation/UPDATE_PRONUNCIATION';
const UPDATE_PRONUNCIATION_SUCCESS =
  'pronunciation/UPDATE_PRONUNCIATION_SUCCESS';
const UPDATE_PRONUNCIATION_FAILURE =
  'pronunciation/UPDATE_PRONUNCIATION_FAILURE';

const DELETE_PRONUNCIATION = 'pronunciation/DELETE_PRONUNCIATION';
const DELETE_PRONUNCIATION_SUCCESS =
  'pronunciation/DELETE_PRONUNCIATION_SUCCESS';
const DELETE_PRONUNCIATION_FAILURE =
  'pronunciation/DELETE_PRONUNCIATION_FAILURE';

const CONVERT_AUDIO = 'pronunciation/CONVERT_AUDIO';
const CONVERT_AUDIO_SUCCESS = 'pronunciation/CONVERT_AUDIO_SUCCESS';
const CONVERT_AUDIO_FAILURE = 'pronunciation/CONVERT_AUDIO_FAILURE';

/* editor */
const ADD_INPUT = 'pronunciation/ADD_INPUT';
const DELETE_INPUT = 'pronunciation/DELETE_INPUT';
const CHANGE_EDITOR_FIELD = 'pronunciation/CHANGE_EDITOR_FIELD';
const CHANGE_EDITOR_DATA_FIELD = 'pronunciation/CHANGE_EDITOR_DATA_FIELD';
const CHANGE_LIST_FIELD = 'pronunciation/CHANGE_LIST_FIELD';
const LOAD_EDITOR_DATA = 'pronunciation/LOAD_EDITOR_DATA';

/* utils */
const SELECT_SKIN = 'pronunciation/SELECT_SKIN';
const CHANGE_FIELD = 'pronunciation/CHANGE_FIELD';
const INIT_EDITOR = 'pronunciation/INIT_EDITOR';

/**
 * Action Creator Function
 */
/* get pronunciations */
const getPronunciations = createAction(GET_PRONUNCIATIONS)();
const getPronunciationsSuccess = createAction(GET_PRONUNCIATIONS_SUCCESS)<{
  pronunciations: PronunciationDataState[];
}>();
const getPronunciationsFailure = createAction(GET_PRONUNCIATIONS_FAILURE)<{
  message: string;
}>();
export const getPronunciationsRequest = () => async (
  dispatch: ThunkDispatch<PronunciationState, {}, AnyAction>,
) => {
  dispatch(getPronunciations());
  try {
    const response: any = await getPronunciationsApi();
    if (response.success) {
      const parsedPronunciations = response.data.map((pronunciation: any) =>
        parsePronunciation(pronunciation),
      );
      dispatch(
        getPronunciationsSuccess({ pronunciations: parsedPronunciations }),
      );
    } else {
      dispatch(
        getPronunciationsFailure({ message: response['errors']['message'] }),
      );
    }
  } catch (e) {
    dispatch(getPronunciationsFailure({ message: '' }));
  }
};

/* create pronunciation */
const createPronunciation = createAction(CREATE_PRONUNCIATION)();
const createPronunciationSuccess = createAction(CREATE_PRONUNCIATION_SUCCESS)();
const createPronunciationFailure = createAction(CREATE_PRONUNCIATION_FAILURE)<{
  message: string;
}>();
export const createPronunciationRequest = (
  words: {
    skin: string;
    source: string;
    target: string;
    source_url: string;
    target_url: string;
  }[],
) => async (dispatch: ThunkDispatch<PronunciationState, {}, AnyAction>) => {
  dispatch(createPronunciation());
  try {
    const response: any = await createPronunciationApi(words);
    if (response.success) {
      dispatch(createPronunciationSuccess());
    } else {
      dispatch(
        createPronunciationFailure({ message: response['errors']['message'] }),
      );
    }
  } catch (e) {
    dispatch(createPronunciationFailure({ message: '' }));
  }
};

/* update pronunciation */
const updatePronunciation = createAction(UPDATE_PRONUNCIATION)();
const updatePronunciationSuccess = createAction(UPDATE_PRONUNCIATION_SUCCESS)();
const updatePronunciationFailure = createAction(UPDATE_PRONUNCIATION_FAILURE)<{
  message: string;
}>();
export const updatePronunciationRequest = (
  pronunciation_id: string,
  skin_id: string,
  source: string,
  target: string,
  source_url: string,
  target_url: string,
) => async (dispatch: ThunkDispatch<PronunciationState, {}, AnyAction>) => {
  dispatch(updatePronunciation());
  try {
    const response: any = await updatePronunciationApi(
      pronunciation_id,
      skin_id,
      source,
      target,
      source_url,
      target_url,
    );
    if (response.success) {
      dispatch(updatePronunciationSuccess());
    } else {
      dispatch(
        updatePronunciationFailure({ message: response['errors']['message'] }),
      );
    }
  } catch (e) {
    dispatch(updatePronunciationFailure({ message: '' }));
  }
};

/* delete pronunciation */
const deletePronunciation = createAction(DELETE_PRONUNCIATION)();
const deletePronunciationSuccess = createAction(DELETE_PRONUNCIATION_SUCCESS)();
const deletePronunciationFailure = createAction(DELETE_PRONUNCIATION_FAILURE)<{
  message: string;
}>();
export const deletePronunciationRequest = (pronunciation_id: string) => async (
  dispatch: ThunkDispatch<PronunciationState, {}, AnyAction>,
) => {
  dispatch(deletePronunciation());
  try {
    const response: any = await deletePronunciationApi(pronunciation_id);
    if (response.success) {
      dispatch(deletePronunciationSuccess());
    } else {
      dispatch(
        deletePronunciationFailure({ message: response['errors']['message'] }),
      );
    }
  } catch (e) {
    dispatch(deletePronunciationFailure({ message: '' }));
  }
};

/* convert audio */
const convertAudio = createAction(CONVERT_AUDIO)<{
  input_pair_id: string;
  input_type: 'source' | 'target';
}>();
const convertAudioSuccess = createAction(CONVERT_AUDIO_SUCCESS)<{
  input_pair_id: string;
  input_type: 'source' | 'target';
  skin_id: string;
  text: string;
  url: string;
  audioBuffer: AudioBuffer;
}>();
const convertAudioFailure = createAction(CONVERT_AUDIO_FAILURE)<{
  input_pair_id: string;
  input_type: 'source' | 'target';
  message: any;
}>();
export const convertAudioRequest = (
  user: string,
  input_pair_id: string,
  input_type: 'source' | 'target',
  skin_id: string,
  text: string,
  is_custom: boolean,
) => async (dispatch: ThunkDispatch<PronunciationState, {}, AnyAction>) => {
  dispatch(convertAudio({ input_pair_id, input_type }));
  // singleton 패턴을 활용하여 단 하나의 AudioContextClass 인스턴스를 생성한다
  // 그리고 해당 인스턴스의 audioContext 를 참조하여 재사용한다
  const AudioContextClassInstance = AudioContextClass.getInstance();
  const ctx = AudioContextClassInstance.getAudioCtx();
  try {
    const response: any = await convertAudioStreamApiForStudio(
      skin_id,
      text,
      JSON.stringify([1, 'false']), // feature speed
      '0', // feature pause
      JSON.stringify([]), // feature emphasis
      is_custom,
    );
    // 응답받은 arrayBuffer를 audioBuffer로 decode한 뒤, 다시 arrayBuffer로 변환한다
    const audioBuffer = await ctx.decodeAudioData(response);
    const arrayBuffer = toWav(audioBuffer);
    // 파일 이름은 unique하게 생성하고 form에 추가한다
    const fileName = uuid();
    const audioFile = new File([arrayBuffer], `${fileName}.wav`);
    const formData = new FormData();
    formData.append('file', audioFile);
    // audio file 을 s3에 업로드한다
    const uploadResult: any = await uploadSynthesisFileApi(formData);
    if (uploadResult.success) {
      // audio play
      const offlineAudioContext = new OfflineAudioContext(
        1,
        audioBuffer.length,
        44100,
      );
      const source = offlineAudioContext.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(offlineAudioContext.destination);
      source.start();
      // render
      const renderedBuffer = await offlineAudioContext.startRendering();
      const audioSource = ctx.createBufferSource();
      audioSource.buffer = renderedBuffer;
      audioSource.connect(ctx.destination);
      audioSource.start();
      dispatch(changePronunciationField({ key: 'isPlaying', value: true }));
      // 재생이 끝나면 효과를 없앤다
      audioSource.onended = () => {
        dispatch(
          changeEditorDataField({
            input_pair_id,
            input_type,
            key: 'playStatus',
            value: 'INIT',
          }),
        );
        dispatch(changePronunciationField({ key: 'isPlaying', value: false }));
      };
      // play 효과를 준다
      dispatch(
        changeEditorDataField({
          input_pair_id,
          input_type,
          key: 'playStatus',
          value: 'PLAY',
        }),
      );
      dispatch(
        convertAudioSuccess({
          input_pair_id,
          input_type,
          skin_id,
          text,
          url: uploadResult.data.link,
          audioBuffer,
        }),
      );
    } else {
      dispatch(
        convertAudioFailure({
          input_pair_id,
          input_type,
          message: uploadResult['errors']['message'],
        }),
      );
    }
  } catch (e) {
    console.error(e);
    dispatch(convertAudioFailure({ input_pair_id, input_type, message: '' }));
  }
};

/* inputs */
export const addInput = createAction(ADD_INPUT)();
export const deleteInput = createAction(DELETE_INPUT)<{
  input_pair_id: string;
}>();
export const changeEditorField = createAction(CHANGE_EDITOR_FIELD)<{
  key: string;
  value: any;
}>();
export const changeEditorDataField = createAction(CHANGE_EDITOR_DATA_FIELD)<{
  input_pair_id: string;
  input_type: 'source' | 'target';
  key: string;
  value: any;
}>();
export const changePronunciationListField = createAction(CHANGE_LIST_FIELD)<{
  id: string;
  input_type: 'source' | 'target';
  key: string;
  value: any;
}>();
export const loadEditorData = createAction(LOAD_EDITOR_DATA)<
  PronunciationDataState
>();

/* utils */
export const setSkin = createAction(SELECT_SKIN)<SkinDataState>();
export const changePronunciationField = createAction(CHANGE_FIELD)<{
  key: string;
  value: any;
}>();
export const initEditor = createAction(INIT_EDITOR)();
const parsePronunciation = (pronunciation: any) => ({
  id: pronunciation._id, // 서버에서 저장한 Oid
  input_pair_id: uuid(), // input pair를 식별하기 위한 id
  user_id: pronunciation.user,
  source: {
    skin_id: pronunciation.skin,
    originText: pronunciation.source,
    text: pronunciation.source,
    convertStatus: 'INIT',
    error: '',
    url: pronunciation.source_url,
    audioBuffer: null,
    playStatus: 'INIT',
  },
  target: {
    skin_id: pronunciation.skin,
    originText: pronunciation.target,
    text: pronunciation.target,
    convertStatus: 'INIT',
    error: '',
    url: pronunciation.target_url,
    audioBuffer: null,
    playStatus: 'INIT',
  },
  createdAt: pronunciation.createdAt,
  updatedAt: pronunciation.updatedAt,
  is_global: pronunciation.is_global,
});
/* 함수가 호출되는 시간에 고유한 ID 를 생성할 수 있도록 하여
   식별 가능한 PronunciationData 를 생성한다 */
const generateInitialPronunciationData = () => ({
  id: null,
  input_pair_id: uuid(),
  // user_id: "",
  source: {
    skin_id: '',
    is_custom: false,
    originText: '',
    text: '',
    convertStatus: 'INIT',
    error: '',
    url: '',
    audioBuffer: null,
    playStatus: 'INIT',
  },
  target: {
    skin_id: '',
    is_custom: false,
    originText: '',
    text: '',
    convertStatus: 'INIT',
    error: '',
    url: '',
    audioBuffer: null,
    playStatus: 'INIT',
  },
  createdAt: '',
  updatedAt: '',
  // is_global: false,
});

/**
 * Action Typescript Type
 */
const actions = {
  getPronunciations,
  getPronunciationsRequest,
  getPronunciationsSuccess,
  getPronunciationsFailure,
  createPronunciation,
  createPronunciationRequest,
  createPronunciationSuccess,
  createPronunciationFailure,
  updatePronunciation,
  updatePronunciationRequest,
  updatePronunciationSuccess,
  updatePronunciationFailure,
  deletePronunciation,
  deletePronunciationRequest,
  deletePronunciationSuccess,
  deletePronunciationFailure,
  convertAudio,
  convertAudioRequest,
  convertAudioSuccess,
  convertAudioFailure,

  addInput,
  deleteInput,
  changeEditorField,
  changeEditorDataField,
  changePronunciationListField,
  loadEditorData,

  setSkin,
  changePronunciationField,
  initEditor,
};
type PronunciationAction = ActionType<typeof actions>;

/**
 * State Typescript Type
 */
const initialPronunciationData = generateInitialPronunciationData();
export type PronunciationDataState = typeof initialPronunciationData;

export type PronunciationState = {
  pronunciationList: PronunciationDataState[];
  editor: {
    selectedSkin: SkinDataState | null;
    appliedSkin: SkinDataState | null;
    data: PronunciationDataState[];
  };
  isPlaying: boolean;

  getPronunciations: {
    status: string;
    error: string;
  };
  createPronunciation: {
    status: string;
    error: string;
  };
  updatePronunciation: {
    status: string;
    error: string;
  };
  deletePronunciation: {
    status: string;
    error: string;
  };
};

/**
 * State Initial Value
 */
const initialState = {
  pronunciationList: [],
  editor: {
    selectedSkin: null,
    appliedSkin: null,
    data: [initialPronunciationData],
  },
  isPlaying: false,

  getPronunciations: {
    status: 'INIT',
    error: '',
  },
  createPronunciation: {
    status: 'INIT',
    error: '',
  },
  updatePronunciation: {
    status: 'INIT',
    error: '',
  },
  deletePronunciation: {
    status: 'INIT',
    error: '',
  },
};

/**
 * Reducer
 */
export const pronunciationReducer = createReducer<
  PronunciationState,
  PronunciationAction
>(initialState)
  .handleAction(getPronunciations, (state) => ({
    ...state,
    getPronunciations: {
      status: 'WAITING',
      error: '',
    },
  }))
  .handleAction(getPronunciationsSuccess, (state, { payload }) => ({
    ...state,
    pronunciationList: payload.pronunciations,
    getPronunciations: {
      status: 'SUCCESS',
      error: '',
    },
  }))
  .handleAction(getPronunciationsFailure, (state, { payload }) => ({
    ...state,
    getPronunciations: {
      status: 'FAILURE',
      error: payload.message,
    },
  }))
  .handleAction(createPronunciation, (state) => ({
    ...state,
    createPronunciation: {
      status: 'WAITING',
      error: '',
    },
  }))
  .handleAction(createPronunciationSuccess, (state) => ({
    ...state,
    createPronunciation: {
      status: 'SUCCESS',
      error: '',
    },
  }))
  .handleAction(createPronunciationFailure, (state, { payload }) => ({
    ...state,
    createPronunciation: {
      status: 'FAILURE',
      error: payload.message,
    },
  }))
  .handleAction(updatePronunciation, (state) => ({
    ...state,
    updatePronunciation: {
      status: 'WAITING',
      error: '',
    },
  }))
  .handleAction(updatePronunciationSuccess, (state) => ({
    ...state,
    updatePronunciation: {
      status: 'SUCCESS',
      error: '',
    },
  }))
  .handleAction(updatePronunciationFailure, (state, { payload }) => ({
    ...state,
    updatePronunciation: {
      status: 'FAILURE',
      error: payload.message,
    },
  }))
  .handleAction(deletePronunciation, (state) => ({
    ...state,
    deletePronunciation: {
      status: 'WAITING',
      error: '',
    },
  }))
  .handleAction(deletePronunciationSuccess, (state) => ({
    ...state,
    deletePronunciation: {
      status: 'SUCCESS',
      error: '',
    },
  }))
  .handleAction(deletePronunciationFailure, (state, { payload }) => ({
    ...state,
    deletePronunciation: {
      status: 'FAILURE',
      error: payload.message,
    },
  }))
  .handleAction(convertAudio, (state, { payload }) => ({
    ...state,
    editor: {
      ...state.editor,
      data: state.editor.data.map((input) =>
        input.input_pair_id === payload.input_pair_id
          ? {
              ...input,
              [payload.input_type]: {
                ...input[payload.input_type],
                convertStatus: 'WAITING',
                error: '',
              },
            }
          : input,
      ),
    },
  }))
  .handleAction(convertAudioSuccess, (state, { payload }) => ({
    ...state,
    editor: {
      ...state.editor,
      data: state.editor.data.map((input) =>
        input.input_pair_id === payload.input_pair_id
          ? {
              ...input,
              [payload.input_type]: {
                ...input[payload.input_type],
                skin_id: payload.skin_id,
                convertStatus: 'SUCCESS',
                error: '',
                originText: payload.text,
                url: payload.url,
                audioBuffer: payload.audioBuffer,
              },
            }
          : input,
      ),
    },
  }))
  .handleAction(convertAudioFailure, (state, { payload }) => ({
    ...state,
    editor: {
      ...state.editor,
      data: state.editor.data.map((input) =>
        input.input_pair_id === payload.input_pair_id
          ? {
              ...input,
              [payload.input_type]: {
                ...input[payload.input_type],
                convertStatus: 'FAILURE',
                error: payload.message,
              },
            }
          : input,
      ),
    },
  }))
  .handleAction(addInput, (state) => ({
    ...state,
    editor: {
      ...state.editor,
      data: [...state.editor.data, generateInitialPronunciationData()],
    },
  }))
  .handleAction(deleteInput, (state, { payload }) => ({
    ...state,
    editor: {
      ...state.editor,
      data: state.editor.data.filter(
        (input) => input.input_pair_id !== payload.input_pair_id,
      ),
    },
  }))
  .handleAction(setSkin, (state, { payload }) => ({
    ...state,
    editor: {
      ...state.editor,
      selectedSkin: payload,
    },
  }))
  .handleAction(changeEditorField, (state, { payload }) => ({
    ...state,
    editor: {
      ...state.editor,
      [payload.key]: payload.value,
    },
  }))
  .handleAction(changeEditorDataField, (state, { payload }) => ({
    ...state,
    editor: {
      ...state.editor,
      data: state.editor.data.map((input) =>
        input.input_pair_id === payload.input_pair_id
          ? {
              ...input,
              [payload.input_type]: {
                ...input[payload.input_type],
                [payload.key]: payload.value,
              },
            }
          : input,
      ),
    },
  }))
  .handleAction(changePronunciationListField, (state, { payload }) => ({
    ...state,
    pronunciationList: state.pronunciationList.map((pronounce) =>
      pronounce.id === payload.id
        ? {
            ...pronounce,
            [payload.input_type]: {
              ...pronounce[payload.input_type],
              [payload.key]: payload.value,
            },
          }
        : pronounce,
    ),
  }))
  .handleAction(loadEditorData, (state, { payload }) => ({
    ...state,
    editor: {
      ...state.editor,
      data: [payload],
    },
  }))
  .handleAction(changePronunciationField, (state, { payload }) => ({
    ...state,
    [payload.key]: payload.value,
  }))
  .handleAction(initEditor, (state) => ({
    ...state,
    editor: {
      selectedSkin: null,
      appliedSkin: null,
      data: [initialPronunciationData],
    },
  }));
