import React, { StyleHTMLAttributes } from 'react';
import styled from 'styled-components';
import { logoColor } from '../../lib/styles/palette';

const Wrapper = styled.div`
  position: relative;
  width: 80%;
  height: 45px;
  border: 2px solid ${logoColor};
  z-index: 1;
  cursor: pointer;
  color: #000;
  font-weight: bold;
  font-size: 15px;
  text-align: left;
  line-height: 45px;
  :before {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    width: 0;
    height: 0;
    margin-top: -1px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${logoColor};
  }

  :after {
    border-color: transparent transparent red transparent;
    top: 25%;
  }

  label {
    position: absolute;
    font-family: MyFont;
    top: 0px; /* 위치정렬 */
    left: 0px; /* 위치정렬 */
    width: 90%;
    height: 100%;
    padding: 0em 0.5em; /* select의 여백 크기 만큼 */
    z-index: -1;
    /* line-height: 45px; */
    color: #000;
    /* IE8에서 label이 위치한 곳이 클릭되지 않는 것 해결 */
    cursor: pointer;
  }
`;

const SelectContainer = styled.select`
  width: 100%;
  height: auto; /* 높이 초기화 */
  line-height: normal; /* line-height 초기화 */
  font-family: MyFont; /* 폰트 상속 */
  padding: 0.8em 0.5em; /* 여백과 높이 결정 */
  opacity: 0; /* 숨기기 */
  filter: alpha(opacity=0); /* IE8 숨기기 */
  -webkit-appearance: none; /* 네이티브 외형 감추기 */
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-size: 15px;
`;

const Option = styled.option`
  color: #000;
`;

interface Props {
  name: string;
  selectedValue: string;
  options: string[];
  onChange: React.ChangeEventHandler;
  style?: React.StyleHTMLAttributes<any>;
  selectContainerStyle?: React.StyleHTMLAttributes<any>;
  forRegister?: boolean;
  placeholder?: string;
}

const Select: React.FC<Props> = ({
  name,
  selectedValue,
  options,
  onChange,
  style,
  selectContainerStyle,
  forRegister,
  placeholder,
}) => {
  return (
    <Wrapper style={style}>
      <label
        style={{
          color: forRegister && !selectedValue ? '#808080' : '#000',
          paddingLeft: forRegister ? '0.1em' : '0.5em',
        }}
      >
        {forRegister && !selectedValue ? placeholder : selectedValue}
      </label>
      <SelectContainer
        name={name}
        onChange={onChange}
        value={selectedValue}
        style={selectContainerStyle}
      >
        <option hidden value=""></option>
        {options.map((data) => (
          <Option key={data} value={data}>
            {data}
          </Option>
        ))}
      </SelectContainer>
    </Wrapper>
  );
};

export default Select;
