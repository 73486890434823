const authClientUrl = String(process.env.REACT_APP_AUTH_CLIENT_URL);

export const redirectToAuthPage = () => {
  window.location.href = authClientUrl;
};

export const getIsSharePage = () => {
  const { pathname } = window.document.location;
  const regex = new RegExp('/iframe/.*');
  return regex.test(pathname);
};

export const redirectToAppsumo = (appsumoUri: string | null) => {
  window.location.href = `https://appsumo.com/account/redemption/${appsumoUri}`;
};
