import React, { useContext, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import loadable from '@loadable/component';
import { SideBar, BannerWithLogo, Loader } from './components/common';
import { SIDEBAR_WIDTH } from './lib/styles/palette';
import { PromoContext } from './modules/contexts';
import { useUser } from './hooks';
import { AsyncStatus } from './modules/types';
import PrivateRouter from './components/auth/PrivateRouter';
import './App.css';
import { getIsSharePage, redirectToAuthPage } from './lib/utils/http';

import CustomVoicePage from './containers/CustomVoicePage';

const loadableConfig = {
  fallback: <Loader isLoading />,
};

const Home = loadable(() => import('./containers/Home'), loadableConfig);
const RequestVoicePage = loadable(
  () => import('./containers/RequestVoice'),
  loadableConfig,
);
const EthicsPage = loadable(
  () => import('./containers/Ethics'),
  loadableConfig,
);
const TeachcrunchBooth = loadable(
  () => import('./containers/TeachcrunchBooth'),
  loadableConfig,
);
const PrivacyPage = loadable(
  () => import('./containers/Privacy'),
  loadableConfig,
);
const SharePlayerPage = loadable(
  () => import('./containers/SharePlayerPage'),
  loadableConfig,
);
const IframePlayerPage = loadable(
  () => import('./containers/IframePlayerPage'),
  loadableConfig,
);
const ProfilePage = loadable(
  () => import('./containers/ProfilePage'),
  loadableConfig,
);
const UserInfoContainer = loadable(
  () => import('./containers/UserInfoContainer'),
  loadableConfig,
);
const Subscription = loadable(
  () => import('./containers/Subscription'),
  loadableConfig,
);
const BillingPage = loadable(
  () => import('./containers/BillingPage'),
  loadableConfig,
);
const CardUpdatePage = loadable(
  () => import('./containers/CardUpdatePage'),
  loadableConfig,
);
const MyPaymentHistory = loadable(
  () => import('./containers/MyPaymentHistory'),
  loadableConfig,
);
const Features = loadable(
  () => import('./containers/Features'),
  loadableConfig,
);
const PlanGuide = loadable(
  () => import('./containers/PlanGuide'),
  loadableConfig,
);
const Library = loadable(() => import('./containers/Library'), loadableConfig);
const Pronunciation = loadable(
  () => import('./containers/Pronunciation'),
  loadableConfig,
);
const EndOfTrialPromotion = loadable(
  () => import('./containers/EndOfTrialPromotion'),
  loadableConfig,
);

const App: React.FC = () => {
  const { user, handleCheckUser, handleGetCurrentUser } = useUser();
  const promo = useContext(PromoContext);
  const isSharePage = getIsSharePage();
  const history = useHistory();

  /**
   * check user's token validation.
   */
  useEffect(() => {
    if (isSharePage) {
      return;
    }
    handleCheckUser();
  }, [handleCheckUser, isSharePage]);

  /**
   * if user checking is success, get api user.
   */
  useEffect(() => {
    if (user.checkUser.status === AsyncStatus.SUCCESS) {
      handleGetCurrentUser();
    } else if (user.checkUser.status === AsyncStatus.FAILURE) {
      const regex = /share/;
      if (regex.test(window.location.pathname)) return;
      redirectToAuthPage();
    }
  }, [user.checkUser.status, handleGetCurrentUser]);

  return (
    <div
      className="App"
      style={{
        minWidth: isSharePage ? 0 : isMobile ? '100vw' : '1100px',
        paddingLeft: isSharePage ? 0 : user.studioUser ? SIDEBAR_WIDTH : 0,
      }}
    >
      {user.studioUser && !isSharePage && <SideBar />}
      {user.studioUser &&
        !isSharePage &&
        user.studioUser?.role.name === 'free' &&
        !user.studioUser.isSubscribed &&
        promo.isDoingPromo && (
          <BannerWithLogo
            sale_amount={promo.sale_amount}
            title={promo.banner.title}
            description={promo.banner.description}
            code={promo.code}
          />
        )}
      <Switch>
        <PrivateRouter path={['/', '/ugc']} exact>
          <Home />
        </PrivateRouter>
        <PrivateRouter path="/profile">
          <ProfilePage />
        </PrivateRouter>
        <PrivateRouter path="/pronunciation">
          <Pronunciation />
        </PrivateRouter>
        <PrivateRouter path="/voice">
          <RequestVoicePage />
        </PrivateRouter>
        <PrivateRouter path="/customvoice" component={CustomVoicePage} />
        <PrivateRouter path="/usermod/:meta">
          <UserInfoContainer forVerification />
        </PrivateRouter>
        <PrivateRouter path="/ethics">
          <EthicsPage />
        </PrivateRouter>
        <PrivateRouter path="/privacy">
          <PrivacyPage />
        </PrivateRouter>
        <PrivateRouter path="/Library">
          <Library />
        </PrivateRouter>
        <PrivateRouter path="/ethics">
          <EthicsPage />
        </PrivateRouter>
        <PrivateRouter path="/ethics">
          <EthicsPage />
        </PrivateRouter>
        <PrivateRouter path="/ethics">
          <EthicsPage />
        </PrivateRouter>

        <Route component={TeachcrunchBooth} path="/invitation/techcrunch" />
        <Route component={SharePlayerPage} path="/share/:kind/:id/:userid?" />
        <Route component={IframePlayerPage} path="/iframe/:kind/:id/:userid?" />
        <Route
          component={EndOfTrialPromotion}
          path="/promotion/coupon/eot/:user_id"
        />

        {user.data?.authType !== 'appsumo' && (
          <>
            <PrivateRouter path="/plan" exact>
              <Subscription />
            </PrivateRouter>
            <PrivateRouter path="/plan/feature">
              <Features />
            </PrivateRouter>
            <PrivateRouter path="/plan/card">
              <CardUpdatePage />
            </PrivateRouter>
            <PrivateRouter path="/plan/guide">
              <PlanGuide />
            </PrivateRouter>
            <PrivateRouter path="/plan/history">
              <MyPaymentHistory />
            </PrivateRouter>
            <PrivateRouter path="/plan/bill">
              <BillingPage />
            </PrivateRouter>
          </>
        )}
      </Switch>
    </div>
  );
};

export default App;
