import React from 'react';
import styled from 'styled-components';

import { logoColor } from '../../lib/styles/palette';
import ErrorIcon from '../../images/Error_X.png';
import Chrome from '../../images/Chrome.png';
import Safari from '../../images/Safari.png';
// import Edge from "../../images/Microsoft_Edge.png";

const Fullscreen = styled.div`
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AskModalBlock = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
  padding: 1.5rem;
`;

const ContentWrapper = styled.div`
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
  min-width: 300px;

  img {
    width: 56px;
    height: 56px;
  }

  h1 {
    font-size: 60px;
    font-weight: bold;
    letter-spacing: 1.2px;
    font-style: normal;
    font-stretch: normal;
    line-height: 0px;
    text-align: center;
    color: ${logoColor};
  }

  h2 {
    font-size: 35px;
    font-weight: bold;
    letter-spacing: 0.7px;
    font-style: normal;
    font-stretch: normal;
    line-height: 42px;
    text-align: center;
    color: ${logoColor};
  }

  p {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.36px;
    text-align: center;
    color: black;
  }
`;

const BrowsersWrapper = styled.div`
  margin-top: 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  -webkit-justify-content: center;
`;

const BrowserIcon = styled.div`
  width: 140px;
  height: 140px;

  img {
    width: 84px;
    height: 84px;
  }

  p {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.36px;
    text-align: center;
    color: black;
  }
`;

const BrowserNotSupported = ({ visible }) => {
  if (!visible) return null;
  return (
    <Fullscreen>
      <AskModalBlock>
        <ContentWrapper>
          <img src={ErrorIcon} alt={'Uh-oh!'} />
          <h1>Uh-oh!</h1>
          <h2>Your browser is not supported</h2>
          <p>To view this content, please use one of the following browsers!</p>
          <BrowsersWrapper>
            <BrowserIcon>
              <img src={Chrome} alt={'Chrome'} />
              <p>Chrome</p>
            </BrowserIcon>
            <BrowserIcon>
              <img src={Safari} alt={'Safari'} />
              <p>Safari</p>
            </BrowserIcon>
          </BrowsersWrapper>
        </ContentWrapper>
      </AskModalBlock>
    </Fullscreen>
  );
};

export default BrowserNotSupported;
