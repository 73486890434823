import styled from 'styled-components';

export const Fullscreen = styled.div`
  position: fixed;
  z-index: 51;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalBlock = styled.div`
  position: relative;
  width: 620px;
  height: 340px;
  padding-bottom: 32px;
  min-width: 554px;
  border-radius: 0px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;

  display: flex;
  flex-direction: column;

  .buttons {
    display: flex;
    justify-content: flex-end;
  }
`;

export const ModalTitle = styled.div`
  margin-top: 63px;
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: 900;
  letter-spacing: 1.08px;
`;

export const Description = styled.div`
  margin-left: 28px;
  white-space: pre-wrap;
  width: 80%;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.3px;
  text-align: center;
  color: #fff;
  text-align: left;
  div {
    margin-top: 20px;
    font-size: 20px;
    font-weight: normal;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

export const NeverShow = styled.span`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #fff;
  margin-left: 6px;
`;

export const CloseButton = styled.button`
  position: absolute;
  width: 20px;
  height: 20px;
  border: 0;
  outline: none;
  text-align: center;
  padding: 0;
  color: #fff;
  background-color: transparent;
  cursor: pointer;

  top: 27px;
  right: 20px;
`;

export const DontShowContainer = styled.div`
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
