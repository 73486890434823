import React from 'react';
import { Textbox } from 'react-inputs-validation';
import { logoColor } from '../../lib/styles/palette';

const inputStyle = {
  fontFamily: 'MyFont',
  fontSize: '15px',
  fontWeight: 'bold',
  outline: 'none',
  width: '100%',
  border: `2px solid ${logoColor}`,
  padding: '10px 10px',
};

const inputContainer = {
  fontSize: '12px',
  color: '#ff5a57',
  width: '80%',
};

const emailInputStyle = {
  fontSize: '15px',
  fontWeight: 'normal',
  outline: 'none',
  width: '100%',
  padding: '10px 10px',
  border: 'none',
  borderBottom: `1.5px solid ${logoColor}`,
};

const emailInputContainer = {
  fontSize: '12px',
  color: '#ff5a57',
  width: '90%',
};
const fullWidthContainer = {
  fontSize: '12px',
  color: '#ff5a57',
  width: '100%',
};

const CustomTextbox = (props) => {
  return (
    <Textbox
      {...props}
      customStyleInput={props.forEmail ? emailInputStyle : inputStyle}
      customStyleWrapper={
        props.forEmail
          ? emailInputContainer
          : props.fullWidth
          ? fullWidthContainer
          : inputContainer
      }
    />
  );
};

export default CustomTextbox;
