import moment from 'moment';
import { StudioUser } from './types';
import { PLAN_EXCEPTION_DATE } from '../../config';

export const parseUserData = (data: any): StudioUser => {
  let isLimitDownload;
  if (
    data.role.exception_download_limit !== undefined &&
    data.role.exception_download_limit !== 0
  ) {
    isLimitDownload =
      data.tracking.downloads >= data.role.exception_download_limit;
  } else {
    isLimitDownload =
      (data.role.name === 'free' && data.tracking.downloads >= 3) ||
      (data.role.name === 'starter' && data.tracking.downloads >= 10) ||
      (data.role.name === 'personal' && data.tracking.downloads >= 30) ||
      (data.role.name === 'freelancer' && data.tracking.downloads >= 100) ||
      (data.role.name === 'lovo_tier1' && data.tracking.downloads >= 20) ||
      (data.role.name === 'lovo_tier2' && data.tracking.downloads >= 100);
  }

  const parsedUserData = {
    billing_type: data.billing_type,
    stripe: data.stripe,
    appsumo: data.appsumo,
    downloads: data.tracking.downloads,
    numberOfDownloadCharacters: data.tracking.downloaded_characters_amount,
    numberOfConvertCharacters: data.tracking.converted_characters_amount,
    isLimitDownload,
    isLimitConvert:
      data.role.name === 'free' &&
      data.tracking.converted_characters_amount > 5000,
    role: {
      name: data.role.name || '',
      refreshDate: data.role.refresh_date || '',
      expireDate: data.role.expires_at || '',
      interval: data.role.interval || '',
      displayName: data.role.name.toUpperCase(),
      exception_download_limit: data.role.exception_download_limit,
    },
    isTrialMode: !!(data.trial && data.trial.status === 'active'),
    isPlanException: moment(data.created_at).isBefore(PLAN_EXCEPTION_DATE),
    isSubscribed: data.subscribed,
    id: data._id,
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
  };

  return parsedUserData;
};
