import React from 'react';

const CloseSvg = () => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 21 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Text_edit_pause-Copy-2"
          transform="translate(-603.000000, -25.000000)"
          stroke="#FFFFFF"
          strokeWidth="3.25"
        >
          <g id="Group-Copy-3" transform="translate(605.000000, 27.000000)">
            <line
              x1="16.9303304"
              y1="7.99360578e-14"
              x2="0"
              y2="16.9303304"
              id="Path-3-Copy-2"
            ></line>
            <line
              x1="17.097842"
              y1="16.9303304"
              x2="0.167511522"
              y2="7.99360578e-14"
              id="Path-3-Copy-3"
            ></line>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CloseSvg;
