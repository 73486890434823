import React from 'react';

const PauseSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 24 24"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect x="6" y="4" width="3" height="16"></rect>
      <rect x="16" y="4" width="3" height="16"></rect>
    </svg>
  );
};

export default PauseSvg;
