import React from 'react';
import './CheckBox.css';

const CheckBox = ({ isChecked, onChange, text = '' }) => (
  <div>
    <label className="container">
      <input type="checkbox" checked={isChecked} onChange={onChange} />
      <span className="checkmark">{text}</span>
    </label>
  </div>
);

export default CheckBox;
