import client from './client';

export const getPronunciationsApi = () => client.get(`/word`);

export const createPronunciationApi = (
  words: {
    skin: string;
    source: string;
    target: string;
    source_url: string;
    target_url: string;
  }[],
) => client.post(`/word`, { words });

export const updatePronunciationApi = (
  pronunciation_id: string,
  skin_id: string,
  source: string,
  target: string,
  source_url: string,
  target_url: string,
) =>
  client.put(`/word/${pronunciation_id}`, {
    skin: skin_id,
    source,
    target,
    source_url,
    target_url,
  });

export const deletePronunciationApi = (pronunciation_id: string) =>
  client.delete(`/word/${pronunciation_id}`);
