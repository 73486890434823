import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { logoColor, footerHeight } from '../../lib/styles/palette';
import { useUser } from '../../hooks';

const HeaderBlock = styled.div`
  width: 100%;
  height: ${footerHeight};
  background: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${logoColor};
  padding: 0 2rem;
  a {
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.36px;
    text-align: center;
    color: #fff;
    padding-left: 2rem;
    &:hover {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 1100px) {
    width: 1100px;
  }
`;

const MenuContainer = styled(NavLink)`
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  text-align: center;
  color: #fff;
`;

const Footer: React.FC = () => {
  const { user } = useUser();

  if (!user) return null;
  else
    return (
      <>
        <HeaderBlock>
          <a href="mailto:customersupport@lovo.ai">customersupport@lovo.ai</a>
          <MenuContainer to="/privacy" activeStyle={{ fontWeight: 'bold' }}>
            <span>Terms & Policy</span>
          </MenuContainer>
          <MenuContainer to="/ethics" activeStyle={{ fontWeight: 'bold' }}>
            <span>Ethics</span>
          </MenuContainer>
        </HeaderBlock>
      </>
    );
};

export default Footer;
