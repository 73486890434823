import uuidv4 from 'uuid/v4';
import {
  SentenceDataState,
  EditorDataState,
  SkinDataState,
  PostState,
} from './types';

export const sanitizeConf = {
  allowedTags: [],
  allowedAttributes: {},
};

export const generateSentenceData = (): SentenceDataState => ({
  id: uuidv4(),
  text: '',
  audioData: undefined,
  emphasis: [],
  isSelected: false,
  audioUrl: '',
});

export const generateEditData = (): EditorDataState => {
  let newSentenceData = generateSentenceData();
  return {
    id: '',
    title: 'Untitled',
    originTitle: 'Untitled',
    skin: initialSkinDataState,
    content: '',
    originContent: '',
    sentenceData: [newSentenceData],
    originSentenceData: [newSentenceData],
    audio: {
      id: '',
      convertAudioUrl: '',
      overlayAudioUrl: '',
    },
    createdAt: '',
    updatedAt: '',
    contentLength: 0,
  };
};

export const initialSkinDataState: SkinDataState = {
  id: '',
  name: '',
  links: {
    audio: '',
    image: '',
  },
  tags: [],
  isCeleb: false,
  isPublic: true,
  isPremium: false,
  isHD: false,
  can_emphasize: false,
  is_favorite: false,
  is_custom: false,
};

export const initialState: PostState = {
  editorData: generateEditData(),
  progress: 0,
  currentFeature: {
    name: '',
    data: [],
  },
  addSentenceStatus: {
    type: '',
  },
  library: [],
  currentIndex: 0,
  skins: {
    status: 'INIT',
    data: [],
    error: '',
  },
  selectedSkin: initialSkinDataState,
  uploadFile: {
    status: 'INIT',
    error: '',
  },
  selectedLibrary: {
    data: [],
    status: 'INIT',
    error: '',
  },
  convertStatus: {
    status: 'INIT',
    id: '',
    error: '',
  },
  accessPrivateSkin: {
    status: 'INIT',
    code: '',
    error: '',
  },
  accessCustomSkin: {
    status: 'INIT',
    error: '',
  },
  bgm: {
    status: 'INIT',
    data: [],
    selectedBgmId: '',
    error: '',
  },
  overlayAudio: {
    status: 'INIT',
    error: '',
  },
  workspaceLibrary: {
    status: 'INIT',
    error: '',
    data: [],
  },
};
