import React from 'react';
import styled from 'styled-components';
import { Textbox } from 'react-inputs-validation';
import validator from 'validator';

import { logoColor } from '../../lib/styles/palette';
import checkedImage from '../../images/checked_btn.png';

import Button from '../common/Button';

const inputStyle = {
  fontSize: '15px',
  border: 'none',
  borderBottom: `1px solid ${logoColor}`,
  paddingBottom: '0.5rem',
  paddingLeft: '0',
  outline: 'none',
  width: '100%',
  fontFamily: 'Helvetica',
  borderRadius: '0',
};

const inputContainer = {
  marginTop: '2px',
  fontSize: '12px',
  color: '#ff5a57',
};

const FormWrapper = styled.div`
  width: 85vw;
  max-width: 392px;
`;

const CommentsWrapper = styled.div`
  text-align: left;
  font-family: 'Helvetica';
  font-size: 15px;
  color: grey;
  margin-top: 2rem;
`;

const CheckContainer = styled.div`
  position: relative;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const CheckedImage = styled.img`
  position: absolute;
  top: 10px;
  right: 0;
  width: 15px;
`;

const CommentBox = styled.div`
  text-align: left;

  p {
    font-family: Helvetica;
    padding-left: 5px;
    margin-top: 30px;
    font-size: 1rem;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    color: #838383;
  }

  textarea {
    width: 85vw;
    max-width: 392px;
    height: 226px;
    background-color: #242424;
    padding: 12px 20px;
    box-sizing: border-box;
    margin-top: 0.5rem;

    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: 0.36px;
    color: rgba(255, 255, 255, 0.5);
    border: 0;
  }
`;

const ButtonWithMarginTop = styled(Button)`
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 70%;
  background-color: ${logoColor};
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  border-radius: 0;
`;

const TCBoothForm = ({
  formName,
  formEmail,
  formCompany,
  formComment,
  onChange,
  onSubmit,
  validate,
  setValidate,
  hasNameError,
  setNameError,
  hasEmailError,
  setEmailError,
}) => {
  const checkImgContainer = (
    <CheckedImage src={checkedImage} alt="checked image" />
  );
  return (
    <>
      <FormWrapper>
        <CheckContainer>
          <Textbox
            attributesInput={{
              placeholder: '*NAME',
              type: 'text',
              name: 'name',
            }}
            value={formName}
            onChange={(e) => {
              onChange('name', e);
            }}
            onBlur={(e) => {}}
            customStyleInput={inputStyle}
            customStyleWrapper={inputContainer}
            validate={validate}
            validationCallback={(res) => {
              setNameError(res);
              setValidate(false);
            }}
            validationOption={{
              customFunc: (name) => {
                if (!validator.isEmpty(name)) {
                  return true;
                } else {
                  return 'Please enter your name';
                }
              },
              msgOnError: 'This field is required*',
            }}
          />
          {!hasNameError && checkImgContainer}
        </CheckContainer>
        <CheckContainer>
          <Textbox
            attributesInput={{
              placeholder: '*EMAIL',
              type: 'text',
              name: 'email',
            }}
            value={formEmail}
            onChange={(e) => {
              onChange('email', e);
            }}
            onBlur={(e) => {}}
            customStyleInput={inputStyle}
            customStyleWrapper={inputContainer}
            validate={validate}
            validationCallback={(res) => {
              setEmailError(res);
              setValidate(false);
            }}
            validationOption={{
              customFunc: (email) => {
                if (validator.isEmail(email)) {
                  return true;
                } else {
                  return 'Please enter a valid email address';
                }
              },
              msgOnError: 'This field is required*',
            }}
          />
          {!hasEmailError && checkImgContainer}
        </CheckContainer>
        <Textbox
          attributesInput={{
            placeholder: 'COMPANY / ROLE',
            type: 'text',
            name: 'company',
          }}
          value={formCompany}
          onChange={(e) => {
            onChange('company', e);
          }}
          customStyleInput={inputStyle}
          customStyleWrapper={inputContainer}
        />
        <CommentsWrapper>COMMENTS & QUESTIONS</CommentsWrapper>
        <CommentBox>
          <textarea
            value={formComment}
            onChange={(e) => {
              onChange('comment', e);
            }}
          ></textarea>
        </CommentBox>
        <ButtonWithMarginTop
          cyan
          fullWidth
          style={{ marginTop: '1rem' }}
          onClick={onSubmit}
        >
          SUBMIT
        </ButtonWithMarginTop>
      </FormWrapper>
    </>
  );
};

export default TCBoothForm;
