import React from 'react';
import styled from 'styled-components';
import { usePosts } from '../../hooks';
import { logoColor } from '../../lib/styles/palette';
import loaderImage from '../../images/loader.gif';

const LoaderContainer = styled.div`
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const LoaderImageContainer = styled.div`
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
`;
const LoaderImage = styled.img`
  width: 55px;
  height: auto;
`;

const LoaderText = styled.div`
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-align: center;
  color: ${logoColor};
  margin-top: 7px;
`;

const Loader = ({ isLoading = false }) => {
  const { posts } = usePosts();
  if (!isLoading) return null;

  return (
    <LoaderContainer>
      <LoaderImageContainer>
        <LoaderImage src={loaderImage} alt="loading" />
        <LoaderText>
          {posts.progress !== 0 && posts.progress < 100
            ? `${posts.progress}%`
            : 'Loading…'}
        </LoaderText>
      </LoaderImageContainer>
    </LoaderContainer>
  );
};

export default Loader;
