import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  changePasswordApi,
  changeUserInfoApi,
  checkUserApi,
  exitUserApi,
  getCurrentUserApi,
  verifyChangePasswordApi,
  logoutApi,
} from '../../lib/api/user';
import {
  verify2checkoutCouponCodeApi,
  apply2checkoutCouponToUser,
} from '../../lib/api/twocheckout';
import {
  changeUserInfo,
  changeUserInfoFailure,
  changeUserInfoSuccess,
  checkUser,
  checkUserFailure,
  checkUserSuccess,
  getCurrentUser,
  getCurrentUserFailure,
  getCurrentUserSuccess,
  logout,
  verifyChangePassword,
  verifyChangePasswordSuccess,
  verifyChangePasswordFailure,
  changePassword,
  changePasswordSuccess,
  changePasswordFailure,
  exitUser,
  exitUserSuccess,
  exitUserFailure,
  verify2checkout,
  verify2checkoutFailure,
  verify2checkoutSuccess,
  apply2checkoutCouponCodeToUser,
  apply2checkoutCouponCodeToUserSuccess,
  apply2checkoutCouponCodeToUserFailure,
} from './actions';
import { removeUser, removeStudioUser } from '../../lib/utils/storage';
import { errorMessage } from '../../lib/api/constants';
import { parseUserData } from '.';

export const logoutRequest = () => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => {
  dispatch(logout());
  try {
    await logoutApi();
  } catch (e) {
    console.error(e);
  }
};

export const checkUserRequest = () => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => {
  dispatch(checkUser());
  try {
    const res = await checkUserApi();
    if (res.success) {
      dispatch(checkUserSuccess(res.data));
    } else {
      dispatch(checkUserFailure(res.error));
    }
  } catch (e) {
    removeUser();
    removeStudioUser();
    dispatch(checkUserFailure(errorMessage));
  }
};

export const getCurrentUserRequest = () => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => {
  dispatch(getCurrentUser());
  try {
    const res = await getCurrentUserApi();
    if (res.success) {
      const user = parseUserData(res.data);
      dispatch(getCurrentUserSuccess(user));
    } else {
      dispatch(getCurrentUserFailure(res.error));
    }
  } catch (e) {
    removeStudioUser();
    removeUser();
    dispatch(getCurrentUserFailure(errorMessage));
  }
};

export const changeUserInfoRequest = (
  name: string,
  phone: string,
  job: string,
) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(changeUserInfo());
  try {
    const res = await changeUserInfoApi(name, phone, job);
    if (res.success) {
      dispatch(changeUserInfoSuccess(res.data));
    } else {
      dispatch(changeUserInfoFailure(res.error));
    }
  } catch (e) {
    dispatch(changeUserInfoFailure(errorMessage));
  }
};

export const verifyChangePasswordRequest = (password: string) => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => {
  dispatch(verifyChangePassword());
  try {
    const res = await verifyChangePasswordApi(password);
    if (res.success) {
      dispatch(verifyChangePasswordSuccess());
    } else {
      dispatch(verifyChangePasswordFailure(res.error));
    }
  } catch (e) {
    dispatch(verifyChangePasswordFailure(errorMessage));
  }
};

export const changePasswordRequest = (password: string) => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => {
  dispatch(changePassword());
  try {
    const res = await changePasswordApi(password);
    if (res.success) {
      dispatch(changePasswordSuccess());
    } else {
      dispatch(changePasswordFailure(res.error));
    }
  } catch (e) {
    dispatch(changePasswordFailure(errorMessage));
  }
};

export const exitUserRequest = (exitReason: string) => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => {
  dispatch(exitUser());
  try {
    const res = await exitUserApi(exitReason);
    if (res.success) {
      dispatch(exitUserSuccess());
    } else {
      dispatch(exitUserFailure(res.error));
    }
  } catch (e) {
    dispatch(exitUserFailure(errorMessage));
  }
};

export const verify2checkoutCouponCodeRequest = (couponCode: string) => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => {
  dispatch(verify2checkout());

  try {
    const res = await verify2checkoutCouponCodeApi(couponCode);

    if (res.success) {
      dispatch(verify2checkoutSuccess());
    } else {
      dispatch(verify2checkoutFailure(res.errors.message));
    }
  } catch (err) {
    dispatch(verify2checkoutFailure(errorMessage));
  }
};

export const apply2checkoutCouponCodeToUserRequest = (
  couponCode: string,
) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(apply2checkoutCouponCodeToUser());

  try {
    const res = await apply2checkoutCouponToUser(couponCode);

    if (res.success) {
      dispatch(apply2checkoutCouponCodeToUserSuccess());
    } else {
      dispatch(apply2checkoutCouponCodeToUserFailure(res.errors.message));
    }
  } catch (err) {
    dispatch(apply2checkoutCouponCodeToUserFailure(errorMessage));
  }
};
