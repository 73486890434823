import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { AudioContext, OfflineAudioContext } from 'standardized-audio-context';
import { createAction, ActionType } from 'typesafe-actions';
import toWav from 'audiobuffer-to-wav';
import uuidv4 from 'uuid/v4';
import sanitizeHtml from 'sanitize-html';
import { toast } from 'react-toastify';
import { toastStyle } from '../../lib/styles/palette';
import {
  getSkinListsApi,
  uploadFileApi,
  accessPrivateSkinApi,
  accessCustomSkinApi,
  getBgmListApi,
  uploadSynthesisFileApi,
  convertAudioStreamApiForStudio,
  getWorkspaceListsApi,
  createWorkspaceApi,
  updateWorkspaceApi,
  deleteWorkspaceApi,
  getWorkspaceOneApi,
  getReplaceWordLists,
} from '../../lib/api/post';
import { sendConvertLogApi, sendConvertErrorApi } from '../../lib/api/user';
import { updateStudioUser } from '../user/actions';
import { parseUserData } from '../user';
import {
  INITIALIZE_FORM,
  GET_SKIN_LIST,
  GET_SKIN_LIST_FAILURE,
  GET_SKIN_LIST_SUCCESS,
  SELECT_SKIN,
  TOGGLE_FAVORITE_SKIN,
  GET_BGM_LIST,
  GET_BGM_LIST_FAILURE,
  GET_BGM_LIST_SUCCESS,
  GET_WORKSPACE_LISTS,
  GET_WORKSPACE_LISTS_FAILURE,
  GET_WORKSPACE_LISTS_SUCCESS,
  GET_WORKSPACE_ONE,
  GET_WORKSPACE_ONE_FAILURE,
  GET_WORKSPACE_ONE_SUCCESS,
  CHANGE_CODE_FIELD,
  CHANGE_POSTS_FIELD,
  CONVERT_TO_AUDIO,
  CONVERT_TO_AUDIO_FAILURE,
  CONVERT_TO_AUDIO_SUCCESS,
  CREATE_WORKSPACE,
  CREATE_WORKSPACE_FAILURE,
  CREATE_WORKSPACE_SUCCESS,
  UPLOAD_FILE,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_SUCCESS,
  UPDATE_EDITOR_LIST_DATA,
  UPDATE_WORKSPACE,
  UPDATE_WORKSPACE_FAILURE,
  UPDATE_WORKSPACE_SUCCESS,
  UPLOAD_OVERLAY_FILE,
  UPLOAD_OVERLAY_FILE_FAILURE,
  UPLOAD_OVERLAY_FILE_SUCCESS,
  DELETE_WORKSPACE,
  DELETE_WORKSPACE_FAILURE,
  DELETE_WORKSPACE_SUCCESS,
  ACCESS_PRIVATE_SKIN,
  ACCESS_PRIVATE_SKIN_SUCCESS,
  ACCESS_PRIVATE_SKIN_FAILURE,
  ACCESS_CUSTOM_SKIN,
  ACCESS_CUSTOM_SKIN_SUCCESS,
  ACCESS_CUSTOM_SKIN_FAILURE,
  ADD_DOCUMENTDATA,
  ADD_SENTENCEDATA,
  REMOVE_SENTENCEDATA,
  WORKSPACE_LIBRARY_FAILURE,
  WORKSPACE_LIBRARY_LOADING,
} from './constants';
import {
  SkinDataState,
  SentenceDataState,
  EditorDataState,
  BgmDataState,
  ChangeFieldState,
} from './types';
import { sanitizeConf, generateSentenceData } from './utils';
import { sentenceTokenizer } from '../../lib/helper/commonHelpers';
import { pasreEmphasizeForConvert } from '../../lib/helper/parseHtml';
import { StoreState } from '../index';
import { PronunciationEditorHelper } from '../../lib/helper/PronunciationEditorHelper';

const parseSkinData = (data: any): SkinDataState => ({
  id: data._id,
  name: data.name,
  links: {
    audio: data.links?.audio,
    image: data.links?.image,
  },
  tags: data.tags?.map((tag: any) => ({
    content: tag.content,
  })),
  isCeleb: data.is_celeb,
  isPublic: data.is_public,
  isPremium: data.is_premium,
  isHD: data.is_hd,
  can_emphasize: data.can_emphasize,
  is_favorite: data.is_favorite,
  is_custom: data.is_custom ? data.is_custom : false,
});

const removeAudioDataForSaveWorkspace = async (data: SentenceDataState[]) => {
  const parseData: SentenceDataState[] = [];
  const targetData = data.map((l) => Object.assign({}, l));
  for (let i = 0; i < targetData.length; i++) {
    let audioData = targetData[i].audioData;
    if (audioData && !targetData[i].audioUrl) {
      let fileName = uuidv4();
      const convertData = new FormData();
      const fileOfBlob = new File([toWav(audioData)], `${fileName}.wav`);
      convertData.append('file', fileOfBlob);
      const overlayResult: any = await uploadSynthesisFileApi(convertData);
      if (overlayResult.success) {
        targetData[i].audioUrl = overlayResult.data.link;
      }
    } else if (!audioData && targetData[i].audioUrl) {
      targetData[i].audioUrl = '';
    }
    targetData[i].audioData = undefined;
    parseData.push(targetData[i]);
  }
  return parseData;
};

const parseWorkspaceData = (data: any): EditorDataState => {
  let parseSentenceData;
  if (data.sentence_data) {
    const preData = JSON.parse(data.sentence_data);

    for (let i = 0; i < preData.length; i++) {
      if (preData[i].text) {
        preData[i].text = preData[i].text.replace(
          /<span[^>]*class[\s]?=[\s"']+emphasis[\s]?feature-emphasis["'].*?>(.*?)<\/span>/g,
          `<b>$1</b>`,
        );
      }
    }
    parseSentenceData = preData;
  } else {
    const splitData = sentenceTokenizer(
      sanitizeHtml(data.content, sanitizeConf),
    );
    const sumOfData = [];
    for (let i = 0; i < splitData.length; i++) {
      let list = generateSentenceData();
      list.text = splitData[i];
      sumOfData.push(list);
    }
    if (sumOfData.length === 0) {
      sumOfData.push(generateSentenceData());
    }
    parseSentenceData = sumOfData;
  }
  return {
    id: data._id,
    title: data.title,
    originTitle: data.title,
    skin: parseSkinData(data.skin),
    content: data.content,
    originContent: data.content,
    sentenceData: parseSentenceData,
    originSentenceData: parseSentenceData,
    audio: {
      id: data.audio.id,
      convertAudioUrl: data.audio.convert_audio_url,
      overlayAudioUrl: data.audio.overlay_audio_url,
    },
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
    contentLength: 0,
  };
};

export const parseEditoAudioDataToServerData = (audio: any) =>
  JSON.stringify({
    id: audio.id,
    convert_audio_url: audio.convertAudioUrl,
    overlay_audio_url: audio.overlayAudioUrl,
  });

export const initializeWorkspace = createAction(INITIALIZE_FORM)<string>();

export const getSkinList = createAction(GET_SKIN_LIST)();

export const getSkinListSuccess = createAction(GET_SKIN_LIST_SUCCESS)<
  SkinDataState[]
>();

export const getSkinListFailure = createAction(GET_SKIN_LIST_FAILURE)<string>();

export const getSkinListRequest = () => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => StoreState,
) => {
  dispatch(getSkinList());
  try {
    const response: any = await getSkinListsApi();
    if (response.success) {
      const skinData: SkinDataState[] = response.data.map((data: any) =>
        parseSkinData(data),
      );
      dispatch(getSkinListSuccess(skinData));
      if (!getState().posts.editorData.skin.id) {
        let skinName = 'Austin Hopkins';
        const userData = getState().user.studioUser;

        if (
          userData &&
          ((userData.role.name === 'free' && !userData.isTrialMode) ||
            userData.role.name === 'starter')
        ) {
          skinName = 'Joy Sembhi';
        }
        const defaultSkin = skinData.find((skins) => skins.name === skinName);
        if (defaultSkin) {
          dispatch(
            changeCodeField({
              form: 'editorData',
              key: 'skin',
              value: defaultSkin,
            }),
          );
        }
      }
    } else {
      dispatch(getSkinListFailure(response.data.errors.message));
    }
  } catch (e) {
    dispatch(getSkinListFailure(''));
  }
};

export const selectSkin = createAction(SELECT_SKIN)<SkinDataState>();

export const toggleFavoriteSkin = createAction(TOGGLE_FAVORITE_SKIN)<string>();

// toggle 하면서 state 바꾸자.
export const uploadFile = createAction(UPLOAD_FILE)();

export const uploadFileSuccess = createAction(UPLOAD_FILE_SUCCESS)<string>();

export const uploadFileFailure = createAction(UPLOAD_FILE_FAILURE)<string>();

export const uploadFileRequest = (data: FormData) => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => {
  dispatch(uploadFile());
  try {
    const response: any = await uploadFileApi(data);
    if (response.success) {
      dispatch(uploadFileSuccess(response.data.text));
    } else {
      dispatch(uploadFileFailure(response.errors.message));
    }
  } catch (e) {
    dispatch(uploadFileFailure(''));
  }
};

export const convertAudio = createAction(CONVERT_TO_AUDIO)<
  string | undefined
>();

export const convertAudioSuccess = createAction(CONVERT_TO_AUDIO_SUCCESS)<{
  audioId: string;
  url: string;
}>();

export const convertAudioFailure = createAction(CONVERT_TO_AUDIO_FAILURE)<
  string
>();

export const updateEditorListData = createAction(UPDATE_EDITOR_LIST_DATA)<{
  data: SentenceDataState;
}>();

export const convertAudioRequest = (
  user: string,
  skinId: string,
  is_custom: boolean,
  text: SentenceDataState[],
  contentLength: number,
  clickedSentenceId?: string,
) => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => StoreState,
) => {
  dispatch(convertAudio(clickedSentenceId));
  const audioContext = new AudioContext();
  try {
    const streamData: AudioBuffer[] = [];
    let duration = 0;

    for (let i = 0; i < text.length; i++) {
      if (getState().posts.convertStatus.status !== 'WAITING') {
        return;
      }
      try {
        const currentAudio = text[i].audioData;
        if (currentAudio) {
          streamData.push(currentAudio);
        } else {
          const reqSpeed = text[i].speed
            ? [text[i].speed, 'false']
            : [1, 'false'];
          const reqPause = text[i].pause || '0';
          const reqEmphasis: number[] = text[i].emphasis;
          const replaceResponse: any = await getReplaceWordLists(skinId);
          if (!replaceResponse.success) {
            dispatch(convertAudioFailure('Failed to replace words.'));
          }

          const replaceResult = new PronunciationEditorHelper(
            replaceResponse.data,
            text[i].text,
          ).text;

          const replacedText: string = await pasreEmphasizeForConvert(
            replaceResult,
          );

          const response = await convertAudioStreamApiForStudio(
            skinId,
            replacedText,
            JSON.stringify(reqSpeed),
            reqPause,
            JSON.stringify(reqEmphasis),
            is_custom,
          );

          const bufferSource: AudioBuffer = await audioContext.decodeAudioData(
            response,
          );
          streamData.push(bufferSource);
          if (getState().posts.convertStatus.status !== 'WAITING') {
            return;
          }
          dispatch(
            updateEditorListData({
              data: {
                ...text[i],
                audioUrl: '',
                audioData: bufferSource,
              },
            }),
          );
        }
      } catch (e) {
        throw new Error('ml server error');
      }
    }

    const sumOfDuration = streamData.reduce(
      (acc, curr) => acc + curr.duration,
      0,
    );
    const offlineAudioContext = new OfflineAudioContext(
      1,
      sumOfDuration * 44100,
      44100,
    );
    for (let j = 0; j < streamData.length; j++) {
      const source = offlineAudioContext.createBufferSource();
      source.buffer = streamData[j];
      source.connect(offlineAudioContext.destination);
      source.start(duration);
      duration += streamData[j].duration;
    }

    const renderedBuffer = await offlineAudioContext.startRendering();
    const fileName = uuidv4();
    const convertData = new FormData();
    const fileOfBlob = new File([toWav(renderedBuffer)], `${fileName}.wav`);
    convertData.append('file', fileOfBlob);
    const overlayResult: any = await uploadSynthesisFileApi(convertData);
    if (overlayResult.success) {
      dispatch(
        convertAudioSuccess({
          audioId: fileName,
          url: overlayResult.data.link,
        }),
      );

      const convertLog: any = await sendConvertLogApi(contentLength, skinId);
      if (convertLog.success) {
        dispatch(updateStudioUser(parseUserData(convertLog.data)));
      }
    } else {
      dispatch(convertAudioFailure(''));
      sendConvertErrorApi('browser');
    }
  } catch (e) {
    dispatch(convertAudioFailure(''));
    if (e.message && e.message === 'ml server error') {
      sendConvertErrorApi('ml-server');
    } else {
      sendConvertErrorApi('browser');
    }
  } finally {
    audioContext.close();
  }
};

export const accessPrivateSkin = createAction(ACCESS_PRIVATE_SKIN)();

export const accessPrivateSkinSuccess = createAction(
  ACCESS_PRIVATE_SKIN_SUCCESS,
)<SkinDataState>();

export const accessPrivateSkinFailure = createAction(
  ACCESS_PRIVATE_SKIN_FAILURE,
)<string>();

export const accessPrivateSkinRequest = (code: string) => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => {
  dispatch(accessPrivateSkin());
  try {
    const response: any = await accessPrivateSkinApi(code);
    if (response.success) {
      const { data } = response;
      const skinData: SkinDataState = parseSkinData(data);
      dispatch(accessPrivateSkinSuccess(skinData));
    } else {
      dispatch(accessPrivateSkinFailure(response.errors.message));
    }
  } catch (e) {
    dispatch(accessPrivateSkinFailure(''));
  }
};

export const accessCustomSkin = createAction(ACCESS_CUSTOM_SKIN)();

export const accessCustomSkinSuccess = createAction(ACCESS_CUSTOM_SKIN_SUCCESS)<
  SkinDataState
>();

export const accessCustomSkinFailure = createAction(ACCESS_CUSTOM_SKIN_FAILURE)<
  string
>();

export const accessCustomSkinRequest = (
  speaker_id: string,
  access_code: string,
) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(accessCustomSkin());
  try {
    const response: any = await accessCustomSkinApi(speaker_id, access_code);
    if (response.success) {
      const { data } = response;
      const skinData: SkinDataState = parseSkinData(data);
      dispatch(accessCustomSkinSuccess(skinData));
    } else {
      dispatch(accessCustomSkinFailure(response.errors.message));
    }
  } catch (e) {
    dispatch(accessCustomSkinFailure(''));
  }
};

export const changeCodeField = createAction(CHANGE_CODE_FIELD)<
  ChangeFieldState
>();
export const changePostsField = createAction(CHANGE_POSTS_FIELD)<{
  key: string;
  value: any;
}>();

export const getBgmList = createAction(GET_BGM_LIST)();
export const getBgmListSuccess = createAction(GET_BGM_LIST_SUCCESS)<
  BgmDataState[]
>();
export const getBgmListFailure = createAction(GET_BGM_LIST_FAILURE)<string>();

export const getBgmListRequest = () => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => {
  dispatch(getBgmList());
  try {
    const response: any = await getBgmListApi();
    if (response.success) {
      const bgmData: BgmDataState[] = response.data.map((data: any) => ({
        id: data._id,
        artist: data.artist,
        title: data.title,
        links: {
          audio: data.links && data.links.audio,
          image: data.links && data.links.image,
        },
      }));
      dispatch(getBgmListSuccess(bgmData));
    } else {
      dispatch(getBgmListFailure(response.errors.message));
    }
  } catch (e) {
    dispatch(getBgmListFailure(''));
  }
};

export const uploadOverlayAudio = createAction(UPLOAD_OVERLAY_FILE)();

export const uploadOverlayAudioSuccess = createAction(
  UPLOAD_OVERLAY_FILE_SUCCESS,
)<{ audioId: string; url: string }>();

export const uploadOverlayAudioFailure = createAction(
  UPLOAD_OVERLAY_FILE_FAILURE,
)<string>();

export const uploadOverlayAudioRequest = (
  audioId: string,
  audio: FormData,
) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(uploadOverlayAudio());
  try {
    const response: any = await uploadSynthesisFileApi(audio);
    if (response.success) {
      dispatch(uploadOverlayAudioSuccess({ audioId, url: response.data.link }));
    } else {
      dispatch(uploadOverlayAudioFailure(response.errors.message));
    }
  } catch (e) {
    dispatch(uploadOverlayAudioFailure(''));
  }
};

export const workspaceLibraryLoading = createAction(
  WORKSPACE_LIBRARY_LOADING,
)();

export const workspaceLibraryFailure = createAction(WORKSPACE_LIBRARY_FAILURE)<
  string
>();

export const getWorkspaceLists = createAction(GET_WORKSPACE_LISTS)();

export const getWorkspaceListsSuccess = createAction(
  GET_WORKSPACE_LISTS_SUCCESS,
)<EditorDataState[]>();

export const getWorkspaceListsFailure = createAction(
  GET_WORKSPACE_LISTS_FAILURE,
)<string>();

export const getWorkspaceListsRequest = () => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => {
  dispatch(workspaceLibraryLoading());
  try {
    const response: any = await getWorkspaceListsApi();
    if (response.success) {
      const workspaceLists = response.data.map((data: any) =>
        parseWorkspaceData(data),
      );
      dispatch(getWorkspaceListsSuccess(workspaceLists));
    } else {
      dispatch(workspaceLibraryFailure(response.errors.message));
    }
  } catch (e) {
    dispatch(workspaceLibraryFailure(''));
  }
};

export const createWorkspace = createAction(CREATE_WORKSPACE)();

export const createWorkspaceSuccess = createAction(CREATE_WORKSPACE_SUCCESS)<
  EditorDataState
>();

export const createWorkspaceFailure = createAction(CREATE_WORKSPACE_FAILURE)<
  string
>();

export const createWorkspaceRequest = (
  title: string,
  skinId: string,
  content: string,
  audio: string,
  sentenceData: SentenceDataState[],
) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(workspaceLibraryLoading());
  try {
    let parseData = await removeAudioDataForSaveWorkspace(sentenceData);
    const response: any = await createWorkspaceApi(
      title,
      skinId,
      content,
      audio,
      JSON.stringify(parseData),
    );
    if (response.success) {
      let currentData = parseWorkspaceData(response.data);
      if (sentenceData) {
        currentData.sentenceData = sentenceData;
        currentData.originSentenceData = sentenceData;
      }
      dispatch(createWorkspaceSuccess(currentData));
      toast('Saved!', toastStyle);
    } else {
      dispatch(workspaceLibraryFailure(response.error.message));
    }
  } catch (e) {
    dispatch(workspaceLibraryFailure(''));
  }
};

export const updateWorkspace = createAction(UPDATE_WORKSPACE)();

export const updateWorkspaceSuccess = createAction(UPDATE_WORKSPACE_SUCCESS)<{
  id: string;
  data: EditorDataState;
}>();

export const updateWorkspaceFailure = createAction(UPDATE_WORKSPACE_FAILURE)<
  string
>();

export const updateWorkspaceRequest = (
  id: string,
  title: string,
  skinId: string,
  content: string,
  audio: string,
  sentenceData: SentenceDataState[],
) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch(workspaceLibraryLoading());
  try {
    let parseData = await removeAudioDataForSaveWorkspace(sentenceData);
    const response: any = await updateWorkspaceApi(
      id,
      title,
      skinId,
      content,
      audio,
      JSON.stringify(parseData),
    );
    if (response.success) {
      let currentData = parseWorkspaceData(response.data);
      if (sentenceData) {
        currentData.sentenceData = sentenceData;
        currentData.originSentenceData = sentenceData;
      }
      dispatch(updateWorkspaceSuccess({ id, data: currentData }));
      toast('Saved!', toastStyle);
    } else {
      dispatch(workspaceLibraryFailure(response.errors.message));
    }
  } catch (e) {
    dispatch(workspaceLibraryFailure(''));
  }
};

export const deleteWorkspace = createAction(DELETE_WORKSPACE)();

export const deleteWorkspaceSuccess = createAction(DELETE_WORKSPACE_SUCCESS)<
  string
>();

export const deleteWorkspaceFailure = createAction(DELETE_WORKSPACE_FAILURE)<
  string
>();

export const deleteWorkspaceRequest = (id: string) => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => {
  dispatch(workspaceLibraryLoading());
  try {
    const response: any = await deleteWorkspaceApi(id);
    if (response.success) {
      dispatch(deleteWorkspaceSuccess(id));
    } else {
      dispatch(workspaceLibraryFailure(response.errors.message));
    }
  } catch (e) {
    dispatch(workspaceLibraryFailure(''));
  }
};

const getWorkspaceOne = createAction(GET_WORKSPACE_ONE)();

export const getWorkspaceOneSuccess = createAction(GET_WORKSPACE_ONE_SUCCESS)<
  EditorDataState
>();

const getWorkspaceOneFailure = createAction(GET_WORKSPACE_ONE_FAILURE)<
  string
>();

export const getWorkspaceOneRequest = (id: string) => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => {
  dispatch(workspaceLibraryLoading());
  try {
    const response: any = await getWorkspaceOneApi(id);
    if (response.success) {
      dispatch(getWorkspaceOneSuccess(parseWorkspaceData(response.data)));
    } else {
      dispatch(workspaceLibraryFailure(response.errors.message));
    }
  } catch (e) {
    dispatch(workspaceLibraryFailure(''));
  }
};

export const addSentenceData = createAction(ADD_SENTENCEDATA)<{
  id: string;
  data: SentenceDataState;
}>();

export const removeSentenceData = createAction(REMOVE_SENTENCEDATA)<string>();

export const addDocumentData = createAction(ADD_DOCUMENTDATA)<{
  id: string;
  data: SentenceDataState[];
}>();

export const hanldeFeauterClickEvent = (forFeature: boolean) => async (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: () => StoreState,
) => {
  const data = forFeature
    ? getState().posts.currentFeature.data
    : getState().posts.editorData.sentenceData;
  let isSelected = false;
  let i = 0;
  while (i < data.length) {
    if (data[i].isSelected) {
      isSelected = true;
      break;
    }
    i = i + 1;
  }
  return isSelected;
};

const actions = {
  initializeWorkspace,
  getSkinList,
  getSkinListSuccess,
  getSkinListFailure,
  selectSkin,
  toggleFavoriteSkin,
  uploadFile,
  uploadFileSuccess,
  uploadFileFailure,
  convertAudio,
  convertAudioSuccess,
  convertAudioFailure,
  accessPrivateSkin,
  accessPrivateSkinSuccess,
  accessPrivateSkinFailure,
  accessCustomSkin,
  accessCustomSkinSuccess,
  accessCustomSkinFailure,
  changeCodeField,
  changePostsField,
  getBgmList,
  getBgmListSuccess,
  getBgmListFailure,
  uploadOverlayAudio,
  uploadOverlayAudioSuccess,
  uploadOverlayAudioFailure,
  updateEditorListData,
  getWorkspaceLists,
  getWorkspaceListsSuccess,
  getWorkspaceListsFailure,
  createWorkspace,
  createWorkspaceSuccess,
  createWorkspaceFailure,
  updateWorkspace,
  updateWorkspaceSuccess,
  updateWorkspaceFailure,
  deleteWorkspace,
  deleteWorkspaceSuccess,
  deleteWorkspaceFailure,
  getWorkspaceOne,
  getWorkspaceOneSuccess,
  getWorkspaceOneFailure,
  workspaceLibraryLoading,
  workspaceLibraryFailure,
  addSentenceData,
  addDocumentData,
  removeSentenceData,
};

export type PostAction = ActionType<typeof actions>;
