import React from 'react';
import styled from 'styled-components';
import Button from './Button';
import { logoColor } from '../../lib/styles/palette';
import CancelButton from '../common/CancelButton';

const Fullscreen = styled.div`
  position: fixed;
  z-index: 60;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AskModalBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  background: white;
  padding: 69px 1.5rem;
  border-radius: 0;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
  .buttons {
    position: absolute;
    right: 10px;
    top: 8px;
  }
`;

const ButtonWithMarginTop = styled(Button)`
  width: 40%;
  background-color: ${logoColor};
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  border-radius: 0;
`;

const Description = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  text-align: center;
  color: #242424;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const ConsentModal = ({ visible, onCancel, onProceed }) => {
  if (!visible) return null;
  return (
    <Fullscreen>
      <AskModalBlock>
        <div className="buttons">
          <CancelButton onCancel={onCancel} />
        </div>
        <Description>
          I agree not to use this voice skin for the purposes of endorsement or
          impersonation, and for any other purposes where it may be deemed
          unethical or illegal. I fully acknowledge that Orbis AI and LOVO is
          not responsible or liable for content generated through this voice
          skin.
        </Description>
        <ButtonWithMarginTop style={{ marginTop: '45px' }} onClick={onProceed}>
          Agree and continue
        </ButtonWithMarginTop>
      </AskModalBlock>
    </Fullscreen>
  );
};

export default ConsentModal;
