import React, { Component } from 'react';
import styled from 'styled-components';
import './custom_country_style.css';

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 250px;
`;

const LabelContainer = styled.div`
  width: 100%;
  height: 15px;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: #838383;
  overflow: hidden;
`;

const SelectArrowContainer = styled.div`
  content: '';
  width: 0;
  height: 0;
  margin-bottom: 0.1em;
  margin-top: 0.3em;
  margin-left: 0.3em;
  border-width: 0.35em 0.2em 0 0.2em;
  border-style: solid;
  border-left-color: transparent;
  border-right-color: transparent;
  color: #7361d4;
  opacity: 0.7;
  transition: color 0.1s;
`;

export default class CountrySelectNative extends Component {
  static defaultProps = {
    selectArrowComponent: () => (
      <div className="react-phone-number-input__country-select-arrow" />
    ),
  };

  onChange = (event) => {
    const { onChange } = this.props;
    const value = event.target.value;
    onChange(value === 'ZZ' ? undefined : value);
  };

  render() {
    const {
      name,
      value,
      options,
      onFocus,
      onBlur,
      disabled,
      tabIndex,
    } = this.props;

    let selectedOption;
    for (const option of options) {
      if (!option.divider && option.value === value) {
        selectedOption = option;
      }
    }

    return (
      <div className="react-phone-number-input__country--native">
        {selectedOption && (
          <SelectContainer>
            <LabelContainer>{selectedOption.label}</LabelContainer>
            <SelectArrowContainer />
          </SelectContainer>
        )}

        <select
          name={name}
          value={value || 'ZZ'}
          onChange={this.onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          tabIndex={tabIndex}
          aria-label={this.props['aria-label']}
          className="react-phone-number-input__country-select"
        >
          {options.map(({ value, label, divider }) => (
            <option
              key={divider ? '|' : value || 'ZZ'}
              value={divider ? '|' : value || 'ZZ'}
              disabled={divider ? true : false}
              className={
                divider
                  ? 'react-phone-number-input__country-select-divider'
                  : undefined
              }
            >
              {label}
            </option>
          ))}
        </select>
      </div>
    );
  }
}
