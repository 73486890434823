import { AudioContext } from 'standardized-audio-context';

class AudioContextClass {
  private static instance: AudioContextClass;
  private _audioCtx: AudioContext;

  private constructor() {
    AudioContextClass.instance = this;
    this._audioCtx = new AudioContext();
  }

  public static getInstance(): AudioContextClass {
    if (!AudioContextClass.instance) {
      AudioContextClass.instance = new AudioContextClass();
    }
    return AudioContextClass.instance;
  }

  public getAudioCtx(): AudioContext {
    if (this._audioCtx.state === 'closed') {
      this._audioCtx = new AudioContext();
    }
    if (this._audioCtx.state === 'suspended') {
      this._audioCtx.resume().then(() => {});
    }
    return this._audioCtx;
  }

  public closeAudioCtx() {
    if (this._audioCtx.state !== 'closed') {
      this._audioCtx.close();
    }
  }
}

export default AudioContextClass;
