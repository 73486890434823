import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../modules';
import { changeBillField, initializeBillStatus } from '../modules/bill';

export default function useBill() {
  const dispatch = useDispatch();
  const billing = useSelector((state: StoreState) => state.billing);

  const handleChangeBillField = useCallback(
    (form: string, value: any) => dispatch(changeBillField({ form, value })),
    [dispatch],
  );

  const handleInitializeBillStatus = useCallback(
    (key: string) => dispatch(initializeBillStatus(key)),
    [dispatch],
  );

  return {
    billing,
    handleChangeBillField,
    handleInitializeBillStatus,
  };
}
