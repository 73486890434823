import { createAction, ActionType } from 'typesafe-actions';
import {
  CHECK_USER,
  CHECK_USER_SUCCESS,
  CHECK_USER_FAILURE,
  LOG_OUT,
  CHANGE_FIELD,
  GET_CURRENT_USER,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAILURE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_USER_INFO,
  CHANGE_USER_INFO_FAILURE,
  CHANGE_USER_INFO_SUCCESS,
  VERIFY_CHANGE_PASSWORD,
  VERIFY_CHANGE_PASSWORD_FAILURE,
  VERIFY_CHANGE_PASSWORD_SUCCESS,
  EXIT_USER_SUCCESS,
  EXIT_USER_FAILURE,
  EXIT_USER,
  UPDATE_STUDIO_USER,
  SET_SESSION_EXPIRED,
  INITIALIZE_USER_STATUS,
  INITIALIZE_USER_FIELD,
  CHANGE_BILLING_TYPE,
  VERIFY_TWOCHECKOUT_COUPON_CODE,
  VERIFY_TWOCHECKOUT_COUPON_CODE_FAILURE,
  VERIFY_TWOCHECKOUT_COUPON_CODE_SUCCESS,
  VERIFY_TWOCHECKOUT_COUPON_CODE_INITIATION,
  APPLY_TWOCHECKOUT_COUPON_CODE_TO_USER,
  APPLY_TWOCHECKOUT_COUPON_CODE_TO_USER_FAILURE,
  APPLY_TWOCHECKOUT_COUPON_CODE_TO_USER_SUCCESS,
} from './constants';
import { User, StudioUser, BillingType } from './types';

export const logout = createAction(LOG_OUT)();

export const checkUser = createAction(CHECK_USER)();
export const checkUserSuccess = createAction(
  CHECK_USER_SUCCESS,
  (user: User) => user,
)();
export const checkUserFailure = createAction(
  CHECK_USER_FAILURE,
  (error: string) => error,
)();

export const getCurrentUser = createAction(GET_CURRENT_USER)();
export const getCurrentUserSuccess = createAction(
  GET_CURRENT_USER_SUCCESS,
  (studioUser: StudioUser) => studioUser,
)();
export const getCurrentUserFailure = createAction(
  GET_CURRENT_USER_FAILURE,
  (error: string) => error,
)();

export const changeUserInfo = createAction(CHANGE_USER_INFO)();
export const changeUserInfoSuccess = createAction(
  CHANGE_USER_INFO_SUCCESS,
  (user: User) => user,
)();
export const changeUserInfoFailure = createAction(
  CHANGE_USER_INFO_FAILURE,
  (error: string) => error,
)();

export const verifyChangePassword = createAction(VERIFY_CHANGE_PASSWORD)();
export const verifyChangePasswordSuccess = createAction(
  VERIFY_CHANGE_PASSWORD_SUCCESS,
)();
export const verifyChangePasswordFailure = createAction(
  VERIFY_CHANGE_PASSWORD_FAILURE,
  (error: string) => error,
)();

export const changePassword = createAction(CHANGE_PASSWORD)();
export const changePasswordSuccess = createAction(CHANGE_PASSWORD_SUCCESS)();
export const changePasswordFailure = createAction(
  CHANGE_PASSWORD_FAILURE,
  (error: string) => error,
)();

export const exitUser = createAction(EXIT_USER)();
export const exitUserSuccess = createAction(EXIT_USER_SUCCESS)();
export const exitUserFailure = createAction(
  EXIT_USER_FAILURE,
  (error: string) => error,
)();

export const changeUserField = createAction(
  CHANGE_FIELD,
  (key: string, value: any) => ({ key, value }),
)();
export const updateStudioUser = createAction(
  UPDATE_STUDIO_USER,
  (studioUser: StudioUser) => studioUser,
)();

export const initializeUserStatus = createAction(INITIALIZE_USER_STATUS)();
export const initializeUserField = createAction(
  INITIALIZE_USER_FIELD,
  (field: string) => field,
)();
export const setSessionExpired = createAction(
  SET_SESSION_EXPIRED,
  (sessionExpired: boolean) => sessionExpired,
)();

export const changeBillingType = createAction(
  CHANGE_BILLING_TYPE,
  (billingType): BillingType => billingType,
)();

export const verify2checkout = createAction(VERIFY_TWOCHECKOUT_COUPON_CODE)();
export const verify2checkoutSuccess = createAction(
  VERIFY_TWOCHECKOUT_COUPON_CODE_SUCCESS,
)();
export const verify2checkoutFailure = createAction(
  VERIFY_TWOCHECKOUT_COUPON_CODE_FAILURE,
  (error: string) => error,
)();

export const verify2checkoutInitiation = createAction(
  VERIFY_TWOCHECKOUT_COUPON_CODE_INITIATION,
)();

export const apply2checkoutCouponCodeToUser = createAction(
  APPLY_TWOCHECKOUT_COUPON_CODE_TO_USER,
)();

export const apply2checkoutCouponCodeToUserSuccess = createAction(
  APPLY_TWOCHECKOUT_COUPON_CODE_TO_USER_SUCCESS,
)();

export const apply2checkoutCouponCodeToUserFailure = createAction(
  APPLY_TWOCHECKOUT_COUPON_CODE_TO_USER_FAILURE,
  (error): string => error,
)();

const actions = {
  logout,
  checkUser,
  checkUserSuccess,
  checkUserFailure,
  getCurrentUser,
  getCurrentUserSuccess,
  getCurrentUserFailure,
  changeUserInfo,
  changeUserInfoFailure,
  changeUserInfoSuccess,
  changeUserField,
  verifyChangePassword,
  verifyChangePasswordSuccess,
  verifyChangePasswordFailure,
  changePassword,
  changePasswordSuccess,
  changePasswordFailure,
  exitUser,
  exitUserSuccess,
  exitUserFailure,
  updateStudioUser,
  initializeUserStatus,
  initializeUserField,
  setSessionExpired,
  changeBillingType,
  verify2checkout,
  verify2checkoutSuccess,
  verify2checkoutFailure,
  verify2checkoutInitiation,
  apply2checkoutCouponCodeToUser,
  apply2checkoutCouponCodeToUserSuccess,
  apply2checkoutCouponCodeToUserFailure,
};

export type UserAction = ActionType<typeof actions>;
