import { createReducer } from 'typesafe-actions';
import { produce } from 'immer';
import { UserState } from './types';
import {
  getUser,
  getStudioUser,
  setUser,
  removeUser,
  removeStudioUser,
  setStudioUser,
} from '../../lib/utils/storage';
import { AsyncStatus } from '../types';
import {
  updateStudioUser,
  UserAction,
  checkUser,
  checkUserSuccess,
  checkUserFailure,
  logout,
  changeUserInfo,
  changeUserInfoFailure,
  changeUserInfoSuccess,
  changeUserField,
  getCurrentUser,
  getCurrentUserSuccess,
  getCurrentUserFailure,
  verifyChangePasswordSuccess,
  verifyChangePasswordFailure,
  changePasswordSuccess,
  verifyChangePassword,
  changePassword,
  changePasswordFailure,
  exitUser,
  exitUserSuccess,
  exitUserFailure,
  setSessionExpired,
  changeBillingType,
  verify2checkout,
  verify2checkoutFailure,
  verify2checkoutSuccess,
  initializeUserStatus,
  initializeUserField,
  verify2checkoutInitiation,
  apply2checkoutCouponCodeToUser,
  apply2checkoutCouponCodeToUserFailure,
  apply2checkoutCouponCodeToUserSuccess,
} from './actions';

const initialState: UserState = {
  data: getUser(),
  studioUser: getStudioUser(),
  logout: {
    status: AsyncStatus.INIT,
    error: '',
  },
  checkUser: {
    status: AsyncStatus.INIT,
    error: '',
  },
  getCurrentUser: {
    status: AsyncStatus.INIT,
    error: '',
  },
  changeUserInfo: {
    status: AsyncStatus.INIT,
    error: '',
  },
  verifyChangePassword: {
    status: AsyncStatus.INIT,
    error: '',
  },
  verify2checkoutCouponCode: {
    status: AsyncStatus.INIT,
    error: '',
  },
  apply2checkoutCouponCodeToUser: {
    status: AsyncStatus.INIT,
    error: '',
  },
  changePassword: {
    status: AsyncStatus.INIT,
    error: '',
  },
  exitUser: {
    status: AsyncStatus.INIT,
    error: '',
  },
  sessionExpired: false,
};

const reducer = createReducer<UserState, UserAction>(initialState)
  .handleAction(logout, (state) =>
    produce(state, (draft) => {
      draft.logout.status = AsyncStatus.SUCCESS;
      removeUser();
      removeStudioUser();
    }),
  )
  .handleAction(checkUser, (state) =>
    produce(state, (draft) => {
      draft.checkUser.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(checkUserSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.checkUser.status = AsyncStatus.SUCCESS;
      draft.data = payload;
      setUser(payload);
    }),
  )
  .handleAction(checkUserFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.checkUser.status = AsyncStatus.FAILURE;
      draft.checkUser.error = payload;
      draft.data = null;
      draft.studioUser = null;
      removeUser();
      removeStudioUser();
    }),
  )
  .handleAction(getCurrentUser, (state) =>
    produce(state, (draft) => {
      draft.getCurrentUser.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(getCurrentUserSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.getCurrentUser.status = AsyncStatus.SUCCESS;
      draft.studioUser = payload;
      setStudioUser(payload);
    }),
  )
  .handleAction(getCurrentUserFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.getCurrentUser.status = AsyncStatus.FAILURE;
      draft.getCurrentUser.error = payload;
      draft.data = null;
      draft.studioUser = null;
      removeStudioUser();
      removeUser();
    }),
  )
  .handleAction(changeUserInfo, (state) =>
    produce(state, (draft) => {
      draft.changeUserInfo.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(changeUserInfoSuccess, (state, { payload }) =>
    produce(state, (draft) => {
      draft.data = payload;
      draft.changeUserInfo.status = AsyncStatus.SUCCESS;
    }),
  )
  .handleAction(changeUserInfoFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.changeUserInfo.status = AsyncStatus.FAILURE;
      draft.changeUserInfo.error = payload;
    }),
  )
  .handleAction(changeUserField, (state, { payload }) =>
    produce(state, (draft) => {
      draft[payload.key] = payload.value;
    }),
  )
  .handleAction(verifyChangePassword, (state) =>
    produce(state, (draft) => {
      draft.verifyChangePassword.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(verifyChangePasswordSuccess, (state) =>
    produce(state, (draft) => {
      draft.verifyChangePassword.status = AsyncStatus.SUCCESS;
    }),
  )
  .handleAction(verifyChangePasswordFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.verifyChangePassword.status = AsyncStatus.FAILURE;
      draft.verifyChangePassword.error = payload;
    }),
  )
  .handleAction(changePassword, (state) =>
    produce(state, (draft) => {
      draft.changePassword.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(changePasswordSuccess, (state) =>
    produce(state, (draft) => {
      draft.changePassword.status = AsyncStatus.SUCCESS;
    }),
  )
  .handleAction(changePasswordFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.changePassword.status = AsyncStatus.FAILURE;
      draft.changePassword.error = payload;
    }),
  )
  .handleAction(exitUser, (state) =>
    produce(state, (draft) => {
      draft.exitUser.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(exitUserSuccess, (state) =>
    produce(state, (draft) => {
      draft.exitUser.status = AsyncStatus.SUCCESS;
    }),
  )
  .handleAction(exitUserFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.exitUser.status = AsyncStatus.FAILURE;
      draft.exitUser.error = payload;
    }),
  )
  .handleAction(updateStudioUser, (state, { payload }) =>
    produce(state, (draft) => {
      draft.studioUser = payload;
    }),
  )
  .handleAction(setSessionExpired, (state, { payload }) =>
    produce(state, (draft) => {
      draft.sessionExpired = payload;
    }),
  )
  .handleAction(changeBillingType, (state, { payload }) =>
    produce(state, (draft) => {
      if (draft.studioUser) {
        draft.studioUser.billing_type = payload;
      }
    }),
  )
  .handleAction(verify2checkout, (state) =>
    produce(state, (draft) => {
      draft.verify2checkoutCouponCode.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(verify2checkoutFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.verify2checkoutCouponCode.status = AsyncStatus.FAILURE;
      draft.verify2checkoutCouponCode.error = payload;
    }),
  )
  .handleAction(verify2checkoutSuccess, (state) =>
    produce(state, (draft) => {
      draft.verify2checkoutCouponCode.status = AsyncStatus.SUCCESS;
    }),
  )
  .handleAction(verify2checkoutInitiation, (state) =>
    produce(state, (draft) => {
      draft.verify2checkoutCouponCode.status = AsyncStatus.INIT;
    }),
  )
  .handleAction(apply2checkoutCouponCodeToUser, (state) =>
    produce(state, (draft) => {
      draft.apply2checkoutCouponCodeToUser.status = AsyncStatus.WAITING;
    }),
  )
  .handleAction(apply2checkoutCouponCodeToUserSuccess, (state) =>
    produce(state, (draft) => {
      draft.apply2checkoutCouponCodeToUser.status = AsyncStatus.SUCCESS;
    }),
  )
  .handleAction(apply2checkoutCouponCodeToUserFailure, (state, { payload }) =>
    produce(state, (draft) => {
      draft.apply2checkoutCouponCodeToUser.status = AsyncStatus.FAILURE;
      draft.apply2checkoutCouponCodeToUser.error = payload;
    }),
  )
  // initial state 를 주기에는, Logout 할 때 Thunk 에서 Local Storage 를 지우는 시점과 안 맞을 수 있어서 null 을 준다.
  .handleAction(initializeUserStatus, () => ({
    ...initialState,
    data: null,
    studioUser: null,
  }))
  .handleAction(initializeUserField, (state, { payload }) =>
    produce(state, (draft) => {
      if (payload === 'all') {
        return initialState;
      }

      if (draft.studioUser) {
        draft.studioUser.stripe.id = null;
        draft.studioUser.stripe.status = null;
        draft.studioUser.stripe.client_secret = null;
        draft.studioUser.stripe.subscription = null;
      }
    }),
  );

export default reducer;
