import tokenizer from 'sbd';

const tokenOptions = {
  newline_boundaries: true,
  html_boundaries: true,
};

export const convertFeatureTagRex = /<\/?img[^>]*(data-feature=("pause")|data-feature=("speed"))[^>]*(data-value*?[\s]?=[\s"']+(.*?)["']).*?>/g;

export const checkEmphasisRex = /<\/?img[^>]*(data-feature=("emphasis")).*?>/g;

export const checkSpeedRex = /<\/?img[^>]*(data-feature=("speed")).*?>/g;

export const checkPauseRex = /<\/?img[^>]*(data-feature=("pause")).*?>/g;

export const checkDataContainer = /(<data[^>]*value[\s]?=[\s"']+emphasis["'].*?>)([^>]*)\s+(<img)/gm;

export const checkDivContainer = /(<div[^>]*(data-feature=[\s"']+(pause)["']+|data-feature=[\s"']+(speed)["']+)[^>]*(data-value[\s]?=[\s"']+(.*?)["']).*?>)([^>]*)\s+<img/gm;

export const checkSentenceSplitForDiv = () =>
  /(<div[^>]*>)(.*?)((<img[^>]*>)*\s*(<img[^>]*>))*(\s*<\/div>)/gi;

export const checkSplitForData = /(<data[^>]*>.*?<\/data>)/g;

export const checkSpanTag = () => /(<span[^>]*>.*?<\/span>)/g;

export const checkDivTag = () => /(<div[^>]*>)(.*?)(<\/div>)/g;

export const seperateSentenceForDiv = () => /(<div[^>]*>.*?<\/div>)/g;

export const splitSpanContent = () => /(<span[^>]*>)(.*?)<\/span>/g;

export const splitSpanClassEmphasis = () =>
  /<span[^>]*class[\s]?=[\s"']+emphasis[\s]?(feature-cursor)?["'].*?>(.*?)<\/span>/gm;

export const trimSpaces = (string: string) => {
  return string
    .replace(/<\/?span[^>]*>/g, '')
    .replace(/<\/?data[^>]*>/g, '')
    .replace(/<\/?img[^>]*>/g, '')
    .replace(/<\/?br[^>]*>/g, ' ')
    .replace(/&nbsp;/g, ' ')
    .replace(/&amp;/g, '&')
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<');
};

export const trimConvertData = (string: string) => {
  return string
    .replace(convertFeatureTagRex, '<br>$&<br>')
    .replace(/&nbsp;/g, ' ')
    .replace(/&amp;/g, '&')
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<');
};

export const trimFeatureData = (string: string) => {
  return string.replace(/<\/?span[^>]*>/g, '');
};

export const checkAncestorOf = (ancestor: Node, descendant: Node) => {
  let n = descendant;
  while (n) {
    if (n === ancestor) {
      return true;
    } else if (n.parentNode) {
      n = n.parentNode;
    }
  }
  return false;
};

export const nodeContainSelection = (node: Node) => {
  let sel, range;
  sel = document.getSelection();
  if (sel && sel.rangeCount) {
    range = sel.getRangeAt(0);
    return checkAncestorOf(node, range.commonAncestorContainer);
  }
  return false;
};

export const trimCompareData = (string: string) => {
  return string
    .replace(/<\/?span[^>]*>/g, '')
    .replace(/<\/?data[^>]*>/g, '')
    .replace(/<\/?img[^>]*>/g, '')
    .replace(/<\/?br[^>]*>/g, ' ')
    .replace(/&nbsp;/g, ' ');
};

export const sentenceTokenizer = (sentences: string) => {
  let lists = tokenizer.sentences(sentences, tokenOptions);
  return lists;
};

export const isAllowedKeyCode = (event: React.KeyboardEvent) => {
  // * 8 : Backspace
  // * 37: LeftKey
  // * 38: UpKey
  // * 39: RightKey
  // * 40: DownKey
  return (
    event.keyCode === 8 ||
    event.keyCode === 38 ||
    event.keyCode === 39 ||
    event.keyCode === 37 ||
    event.keyCode === 40 ||
    event.ctrlKey ||
    event.metaKey
  );
};

export const checkEqualTwoArray = (arr1: [], arr2: number[]) => {
  if (arr1.length !== arr2.length) return false;

  for (var i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
};
