import React from 'react';
// import moment from "moment-timezone";

export const promo = {
  isDoingPromo: true,
  sale_amount: 50,
  code: 'LOVO50COVID',
  banner: {
    title: 'We are in this together',
    description: ``,
    code: 'LOVO50COVID',
  },
  modal: {
    sale_amount: 50,
    title: 'We are in this together: 50% OFF',
    description:
      "A lot of you have shared how tough the times are with COVID, and while it's also not easy for us, we want to push through the tall waters with you: take until COVID-19 Pandemic is officially declared over.",
  },
};
// CreateContext 의 인자로 default 값을 넘겨준다
export const PromoContext = React.createContext(promo);
