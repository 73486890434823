export enum AsyncStatus {
  INIT = 'INIT',
  WAITING = 'WAITING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export interface BaseEntity {
  id: string;
  createdAt: Date;
  updatedAt: Date;
}
