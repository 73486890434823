import React from 'react';
import styled from 'styled-components';
import Button from './Button';
import CancelBtnImage from '../../images/cancel_btn.png';

const CancelBtn = styled(Button)`
  background-color: white;
  margin-bottom: 1.5rem;
  :hover {
    background-color: transparent;
  }
`;

const CancelImage = styled.img`
  width: 16.9px !important;
  height: 16.9px !important;
`;

const CancelButton = ({ onCancel }) => {
  return (
    <CancelBtn onClick={onCancel}>
      <CancelImage src={CancelBtnImage} alt="cancel image" />
    </CancelBtn>
  );
};

export default CancelButton;
