export const INITIALIZE_FORM = 'posts/INITIALIZE_FORM';

export const GET_SKIN_LIST = 'posts/GET_SKIN_LIST';
export const GET_SKIN_LIST_SUCCESS = 'posts/GET_SKIN_LIST_SUCCESS';
export const GET_SKIN_LIST_FAILURE = 'posts/GET_SKIN_LIST_FAILURE';
export const SELECT_SKIN = 'posts/SELECT_SKIN';
export const TOGGLE_FAVORITE_SKIN = 'posts/TOGGLE_FAVORITE_SKIN';
export const UPLOAD_FILE = 'posts/UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'posts/UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'posts/UPLOAD_FILE_FAILURE';

export const CONVERT_TO_AUDIO = 'posts/CONVERT_TO_AUDIO';
export const CONVERT_TO_AUDIO_SUCCESS = 'posts/CONVERT_TO_AUDIO_SUCCESS';
export const CONVERT_TO_AUDIO_FAILURE = 'posts/CONVERT_TO_AUDIO_FAILURE';

export const ACCESS_PRIVATE_SKIN = 'posts/ACCESS_PRIVATE_SKIN';
export const ACCESS_PRIVATE_SKIN_SUCCESS = 'posts/ACCESS_PRIVATE_SKIN_SUCCESS';
export const ACCESS_PRIVATE_SKIN_FAILURE = 'posts/ACCESS_PRIVATE_SKIN_FAILURE';

export const ACCESS_CUSTOM_SKIN = 'posts/ACCESS_CUSTOM_SKIN';
export const ACCESS_CUSTOM_SKIN_SUCCESS = 'posts/ACCESS_CUSTOM_SKIN_SUCCESS';
export const ACCESS_CUSTOM_SKIN_FAILURE = 'posts/ACCESS_CUSTOM_SKIN_FAILURE';

export const CHANGE_CODE_FIELD = 'posts/CHANGE_CODE_FIELD';
export const CHANGE_POSTS_FIELD = 'posts/CHANGE_POSTS_FIELD';

export const GET_BGM_LIST = 'posts/GET_BGM_LIST';
export const GET_BGM_LIST_SUCCESS = 'posts/GET_BGM_LIST_SUCCESS';
export const GET_BGM_LIST_FAILURE = 'posts/GET_BGM_LIST_FAILURE';

export const UPLOAD_OVERLAY_FILE = 'posts/UPLOAD_OVERLAY_FILE';
export const UPLOAD_OVERLAY_FILE_SUCCESS = 'posts/UPLOAD_OVERLAY_FILE_SUCCESS';
export const UPLOAD_OVERLAY_FILE_FAILURE = 'posts/UPLOAD_OVERLAY_FILE_FAILURE';

export const UPDATE_EDITOR_LIST_DATA = 'posts/UPDATE_EDITOR_LIST_DATA';

export const WORKSPACE_LIBRARY_LOADING = 'posts/WORKSPACE_LIBRARY_LOADING';
export const WORKSPACE_LIBRARY_FAILURE = 'posts/WORKSPACE_LIBRARY_FAILURE';

export const GET_WORKSPACE_LISTS = 'posts/GET_WORKSPACE_LISTS';
export const GET_WORKSPACE_LISTS_SUCCESS = 'posts/GET_WORKSPACE_LISTS_SUCCESS';
export const GET_WORKSPACE_LISTS_FAILURE = 'posts/GET_WORKSPACE_LISTS_FAILURE';

export const CREATE_WORKSPACE = 'posts/CREATE_WORKSPACE';
export const CREATE_WORKSPACE_SUCCESS = 'posts/CREATE_WORKSPACE_SUCCESS';
export const CREATE_WORKSPACE_FAILURE = 'posts/CREATE_WORKSPACE_FAILURE';

export const UPDATE_WORKSPACE = 'posts/UPDATE_WORKSPACE';
export const UPDATE_WORKSPACE_SUCCESS = 'posts/UPDATE_WORKSPACE_SUCCESS';
export const UPDATE_WORKSPACE_FAILURE = 'posts/UPDATE_WORKSPACE_FAILURE';

export const DELETE_WORKSPACE = 'posts/DELETE_WORKSPACE';
export const DELETE_WORKSPACE_SUCCESS = 'posts/DELETE_WORKSPACE_SUCCESS';
export const DELETE_WORKSPACE_FAILURE = 'posts/DELETE_WORKSPACE_FAILURE';

export const GET_WORKSPACE_ONE = 'posts/GET_WORKSPACE_ONE';
export const GET_WORKSPACE_ONE_SUCCESS = 'posts/GET_WORKSPACE_ONE_SUCCESS';
export const GET_WORKSPACE_ONE_FAILURE = 'posts/GET_WORKSPACE_ONE_FAILURE';

export const ADD_SENTENCEDATA = 'posts/ADD_SENTENCEDATA';
export const REMOVE_SENTENCEDATA = 'posts/REMOVE_SENTENCEDATA';
export const ADD_DOCUMENTDATA = 'posts/ADD_DOCUMENTDATA';
