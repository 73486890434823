import React from 'react';

const BgmSvg = () => {
  return (
    <svg
      width="18px"
      height="21px"
      viewBox="0 0 18 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="playselected_" transform="translate(-898.000000, -674.000000)">
          <g id="Group-24" transform="translate(898.000107, 675.372302)">
            <path
              d="M6.24869289,15.958607 C6.81355449,17.337227 5.90962689,19.0116914 4.23069129,19.699511 C2.55101049,20.3865854 0.732722494,19.8269402 0.167860894,18.4498106 C-0.396255506,17.0711906 0.507672094,15.395981 2.18660769,14.7081614 C3.86554329,14.0203418 5.68457649,14.5807322 6.24869289,15.958607"
              id="Fill-1"
              fill="currentColor"
            ></path>
            <path
              d="M17.5460739,13.0804211 C18.1101903,14.4590411 17.2070079,16.1335055 15.5273271,16.8213251 C13.8483915,17.5083995 12.0293583,16.9487543 11.4652419,15.5708795 C10.9003803,14.1930047 11.8043079,12.5177951 13.4832435,11.8299755 C15.1629243,11.1421559 16.9812123,11.7018011 17.5460739,13.0804211"
              id="Fill-3"
              fill="currentColor"
            ></path>
            <polyline
              id="Stroke-5"
              stroke="currentColor"
              strokeWidth="2.2356"
              strokeLinecap="round"
              strokeLinejoin="round"
              points="5.25683169 16.3698084 5.25683169 3.7230192 16.6248577 3.46389584e-13 16.6248577 13.6803816"
            ></polyline>
            <polygon
              id="Fill-7"
              fill="currentColor"
              points="5.25683169 7.35825384 16.6248577 3.77682264 16.6248577 0.000149040001 5.25683169 3.72316824"
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BgmSvg;
