import React from 'react';
import styled from 'styled-components';
import validator from 'validator';
import Button from './Button';
import { logoColor } from '../../lib/styles/palette';
import { Textbox } from 'react-inputs-validation';
import CancelButton from './CancelButton';

const Fullscreen = styled.div`
  position: absolute;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1100px;
`;
const AskModalBlock = styled.div`
  max-width: 600px;
  min-width: 600px;
  background: white;
  padding: 1.5rem;
  border-radius: 0px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
  h2 {
    margin-top: 0;
    margin-bottom: 1rem;
    color: ${logoColor};
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0.5px;
  }
  p {
    margin: auto;
    margin-bottom: 3rem;
    color: #242424;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.36px;
    width: 80%;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
  }
`;

const ButtonWithMarginTop = styled(Button)`
  width: 40%;
  background-color: ${logoColor};
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  border-radius: 0;
`;

const inputStyle = {
  fontSize: '1rem',
  border: 'none',
  borderBottom: `1px solid ${logoColor}`,
  paddingBottom: '0.5rem',
  outline: 'none',
  width: '65%',
};

const inputContainer = {
  marginTop: '2px',
  fontSize: '12px',
  color: '#ff5a57',
  paddingLeft: '4px',
};

const CodeInputModal = ({
  visible,
  onChange,
  onSubmit,
  onJoin,
  onCancel,
  onChangeJoin,
  invitationCodeValidate,
  setInvitationCodeValidate,
  joinlistValidate,
  setJoinlistValidate,
  setInvitationCodeError,
  setJoinlistError,
}) => {
  if (!visible) return null;
  return (
    <Fullscreen>
      <AskModalBlock>
        <div className="buttons">
          <CancelButton onCancel={onCancel} />
        </div>
        <h2>We are currently doing a closed Beta Test!</h2>
        <p>
          Please enter the authentication code we sent to your email to create
          your account
        </p>
        <Textbox
          attributesInput={{
            placeholder: 'AUTHENTICATION CODE',
            type: 'number',
            name: 'code',
            min: 1,
            max: 999999,
          }}
          onChange={(password, e) => {
            onChange(e);
          }}
          onBlur={(e) => {}}
          customStyleInput={inputStyle}
          customStyleWrapper={inputContainer}
          validate={invitationCodeValidate}
          validationCallback={(res) => {
            setInvitationCodeError(res);
            setInvitationCodeValidate(false);
          }}
          validationOption={{
            customFunc: (code) => {
              if (code.length === 6) {
                return true;
              } else {
                return 'Please check your authentication code';
              }
            },
            msgOnError: 'This field is required*',
          }}
        />
        <ButtonWithMarginTop
          cyan
          fullWidth
          style={{ marginTop: '2rem' }}
          onClick={onSubmit}
        >
          SUBMIT
        </ButtonWithMarginTop>
        <p style={{ marginTop: '3rem' }}>Don’t have an authentication code?</p>
        <Textbox
          attributesInput={{
            placeholder: 'EMAIL',
            name: 'email',
            autoComplete: 'email',
          }}
          onChange={(email, e) => {
            onChangeJoin(e);
          }}
          onBlur={(e) => {}}
          customStyleContainer={{ marginTop: '0rem' }}
          customStyleInput={inputStyle}
          customStyleWrapper={inputContainer}
          validate={joinlistValidate}
          validationCallback={(res) => {
            setJoinlistError(res);
            setJoinlistValidate(false);
          }}
          validationOption={{
            customFunc: (email) => {
              if (validator.isEmail(email)) {
                return true;
              } else {
                return 'Please enter a valid email address';
              }
            },
            msgOnError: 'This field is required*',
          }}
        />
        <ButtonWithMarginTop
          cyan
          fullWidth
          style={{ marginTop: '2rem', marginBottom: '2rem' }}
          onClick={onJoin}
        >
          JOIN THE WAITLIST
        </ButtonWithMarginTop>
      </AskModalBlock>
    </Fullscreen>
  );
};

export default CodeInputModal;
