import React, { useState } from 'react';
import styled from 'styled-components';
import { logoColor } from '../../lib/styles/palette';
import Button from './Button';
import CancelBtnImage from '../../images/cancel_btn.png';
import upArrowImage from '../../images/up_arrow.png';
import downArrowImage from '../../images/down_arrow.png';

const Fullscreen = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AskModalBlock = styled.div`
  width: 654px;
  background: white;
  padding: 1rem;
  padding-bottom: 2rem;
  border-radius: 0px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
`;
const CancelButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const CancelBtn = styled(Button)`
  background-color: white;

  :hover {
    background-color: transparent;
  }
`;
const CancelImage = styled.img`
  width: 16.9px !important;
  height: 16.9px !important;
`;
const ModalTitleContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
`;
const Title = styled.div`
  color: ${logoColor};
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0.5px;
`;
const TitleIcon = styled.img`
  width: 19px;
  height: 19px;
  margin-left: 10px;
`;
const SpeedIcon = styled(TitleIcon)`
  width: 24px;
`;
const ButtonWithMarginTop = styled(Button)`
  width: 162px;
  height: 59px;
  background-color: ${logoColor};

  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.36px;
  border-radius: 0;
`;
const Description = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  text-align: center;
  color: #242424;
  padding-left: 1rem;
  padding-right: 1rem;
`;
const ControllerContainer = styled.div`
  width: 100%;
  margin-top: 45px;
  margin-bottom: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
`;
const ArrowButton = styled.img`
  width: 13px;
  height: auto;
  cursor: pointer;
`;
const DownButton = styled(ArrowButton)``;
const UpButton = styled(ArrowButton)``;
const ScoreContainer = styled.div`
  width: 32px;
  margin: 0 27px;

  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.44px;

  color: #242424;
  text-align: center;
`;

type TextEditPopupProps = {
  name: string;
  description: string[];
  onCancel: Function;
  iconSrc: string;
  handleSetValue: Function;
};

const TextEditPopup = ({
  name,
  description,
  onCancel,
  iconSrc,
  handleSetValue,
}: TextEditPopupProps) => {
  // 기본 값은 1(임시)
  const [value, setValue] = useState(1);

  /**
   *
   * @param diff 기본 값에 더해질 offset 값
   */
  const onSetValue = (diff: number) => {
    // Pause 는 최소 0, 최대 5.0 이 되어야 한다. 넘어가면 return 해준다
    if (
      name === 'Pause' &&
      ((value <= 0 && diff < 0) || (value >= 5 && diff > 0))
    ) {
      return;
    }
    // Speed 는 최소 0.8, 최대 1.2가 되어야 한다.
    if (
      name === 'Speed' &&
      ((value <= 0.5 && diff < 0) || (value >= 1.5 && diff > 0))
    ) {
      return;
    }
    setValue(value + diff);
  };

  return (
    <Fullscreen>
      <AskModalBlock>
        <CancelButtonContainer>
          <CancelBtn
            onClick={() => {
              onCancel();
            }}
          >
            <CancelImage src={CancelBtnImage} alt="cancel image" />
          </CancelBtn>
        </CancelButtonContainer>

        <ModalTitleContainer>
          <Title>Text Edit [{name}]</Title>
          {name === 'Pause' ? (
            <TitleIcon src={iconSrc} />
          ) : (
            <SpeedIcon src={iconSrc} />
          )}
        </ModalTitleContainer>
        {description.map((desc, id) => (
          <Description key={id}>{desc}</Description>
        ))}
        <ControllerContainer>
          <DownButton
            src={downArrowImage}
            onClick={() =>
              name === 'Pause' ? onSetValue(-0.5) : onSetValue(-0.05)
            }
           />
          <ScoreContainer>{value.toFixed(2)}</ScoreContainer>
          <UpButton
            src={upArrowImage}
            onClick={() =>
              name === 'Pause' ? onSetValue(+0.5) : onSetValue(+0.05)
            }
           />
        </ControllerContainer>

        <ButtonWithMarginTop
          cyan
          fullWidth
          style={{ marginTop: '2rem', marginBottom: '1rem' }}
          onClick={() => {
            handleSetValue(value.toFixed(2));
            // onCancel();
          }}
        >
          CONFIRM
        </ButtonWithMarginTop>
      </AskModalBlock>
    </Fullscreen>
  );
};

export default TextEditPopup;
