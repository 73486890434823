import client from './client';
import gatewayClient, { ErrorResponse, Response } from './gatewayClient';

export const getCurrentUserApi = (): Promise<Response | ErrorResponse> =>
  client.get('/user/current');

export const checkUserApi = (): Promise<Response | ErrorResponse> =>
  gatewayClient.get('/user/check');

export const logoutApi = (): Promise<Response | ErrorResponse> =>
  gatewayClient.post('/user/logout');

export const changeUserInfoApi = (
  name: string,
  phone: string,
  job: string,
): Promise<Response | ErrorResponse> =>
  gatewayClient.put('/user/info', { name, phone, job });

export const verifyChangePasswordApi = (
  password: string,
): Promise<Response | ErrorResponse> =>
  gatewayClient.post('/user/password/verify', { password });

export const changePasswordApi = (
  password: string,
): Promise<Response | ErrorResponse> =>
  gatewayClient.put('/user/password', { password });

export const exitUserApi = (
  exitReason: string,
): Promise<Response | ErrorResponse> =>
  gatewayClient.put('/user/exit', { exitReason });

export const updateAudioDownloadCount = (
  downloaded_characters_amount: number,
): Promise<Response | ErrorResponse> =>
  client.post('/user/download', { downloaded_characters_amount });

export const sendConvertLogApi = (
  converted_characters: number,
  skin_id: string,
): Promise<Response | ErrorResponse> =>
  client.post('/user/conversion', { converted_characters, skin_id });

export const sendConvertClickLogApi = (): Promise<Response | ErrorResponse> =>
  client.post('/user/conversion-try');

export const sendConvertErrorApi = (
  error_type: string,
): Promise<Response | ErrorResponse> =>
  client.post('/user/conversion-error', { error_type });

export const sendLinkSharingLogApi = (): Promise<Response | ErrorResponse> =>
  client.post('/user/sharing');
