import { isChrome, isEdge, isFirefox, isSafari } from "react-device-detect";

export class PronunciationEditorHelper {
  private _wordLists: object[];
  private _text: string;
  private english: RegExp;

  constructor(wordLists: [], text: string) {
    this._wordLists = wordLists;
    this._text = text;
    this.english = /[a-zA-Z]+/;
    this.removeBoldTag();
    this.replaceWords();
  }

  get text() {
    return this._text;
  }

  set text(value: string) {
    this._text = value;
  }

  get wordLists() {
    return this._wordLists;
  }

  private removeBoldTag(): void {
    this.text = this.text.replace(/(<b>|<\/b>)/g, '');
  }

  private isEnglish(word: string) {
    if (this.english.test(word)) {
      return true;
    }
    return false;
  }

  private setRegex(source: string) {
    if (isChrome || isEdge || isFirefox) {
      return new RegExp(
        `(?<=[^\\p{Alpha}\\p{M}\\p{Nd}\\p{Pc}\\p{Join_C}]|)${source}(?=[^\\p{Alpha}\\p{M}\\p{Nd}\\p{Pc}\\p{Join_C}])`,
        'gu',
      );
    } else if (this.isEnglish(source)) {
      return new RegExp(`\\b${source}\\b`, 'g')
    } else {
      return new RegExp(`${source}`, 'g');
    }

  }

  private replaceWords() {
    this.wordLists.forEach((word: any) => {
      const newText = ` ${this.text} `;
      const pattern = this.setRegex(word.source);
      this.text = newText.replace(pattern, word.target).trim();
    });
  }
}
