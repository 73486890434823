import React from 'react';
import styled from 'styled-components';

interface Props {
  title?: string;
  content?: string;
  className?: string;
}
const ParagraphWithTitle: React.FC<Props> = ({
  className,
  title,
  content,
  children,
}) => {
  return (
    <ParagraphWithTitleContainer className={className}>
      <h3>{title}</h3>
      <p>{content || children}</p>
    </ParagraphWithTitleContainer>
  );
};

export default ParagraphWithTitle;

const ParagraphWithTitleContainer = styled.div`
  h3 {
    font-weight: bold;
    margin-bottom: 17px;
    font-size: 20px;
  }
  p {
    line-height: 25px;
    a {
      text-decoration: underline;
      &.tos {
        font-weight: 600;
      }
    }
  }
`;
