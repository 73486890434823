export const GOOGLE_CLIENT_ID =
  '637534587876-riv63unulttp5t09pg135tq53iiek1ho.apps.googleusercontent.com';
export const FACEBOOK_APP_ID = '2536913609920355';

export const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
export const buildEnv = process.env.REACT_APP_BUILD_ENV;
export const convertedAudioShareLink = process.env.REACT_APP_SHARE_URL;
export const stripeApiKey = process.env.REACT_APP_STRIPE_API_KEY || '';
export const userEmailSalt =
  process.env.REACT_APP_USER_EMAIL_SALT || 'testrandeomslalt';

export const jobLists: string[] = [
  'Marketing Manager',
  'YouTuber',
  'Podcaster',
  'Ad Producer',
  'Audiobook Publisher',
  'Author',
  'E-Learning Material Publisher',
  'App / Web Developer',
  'Game Developer',
  'Screenwriter',
  'Freelance Artist',
  'Voice Actor',
  'Other',
];

export const questionAboutExpenses: string[] = [
  'I make money from doing voice overs.',
  'None',
  '$0 ~ $99',
  '$100 ~ $999',
  '$1,000 ~ $9,999',
  '$10,000 +',
];

interface CardImageState {
  'American Express': string;
  'Diners Club': string;
  Discover: string;
  JCB: string;
  MasterCard: string;
  UnionPay: string;
  Visa: string;
  [key: string]: string;
}

export const cardMapping: CardImageState = {
  'American Express': 'amex.png',
  'Diners Club': 'diners.png',
  Discover: 'discover.png',
  JCB: 'jcb.png',
  MasterCard: 'master.png',
  UnionPay: 'union.png',
  Visa: 'visa.png',
};

export const PLAN_EXCEPTION_DATE = '2020-01-26';

export const FREE_USER_TEXT_LIMIT = 5000;

export const PAID_USER_TEXT_LIMIT = 15000;

type PlanType = {
  [plan_name: string]: number;
};
export const PLAN: PlanType = {
  free: 0,
  starter: 1,
  personal: 2,
  freelancer: 3,
};
