import React from 'react';
import styled from 'styled-components';

import { logoColor } from '../../lib/styles/palette';

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 40px 20px;
  img {
    width: 23px;
    height: 21px;
  }
  h2 {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.48px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    text-align: center;
    color: ${logoColor};
  }
  p {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.36px;
    text-align: center;
    color: ${logoColor};
  }
`;

const HiCreatorMobile = ({ visible }) => {
  if (!visible) return null;
  return (
    <ContentWrapper>
      <h2>Hi Creator!</h2>
      <p>
        Please use a desktop or a laptop when using LOVO. <br /> We'll get you
        to work on-the-go soon, so please stay tuned!
      </p>
    </ContentWrapper>
  );
};

export default HiCreatorMobile;
